import React from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Stack, Typography } from '@esgian/esgianui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ProfileLink } from '@components/Links';
import {
  getNumberWithSign,
  checkUserHasAccess,
  getDateList,
  formatNumberReadable,
  formatToDecimalPlaces
} from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getOperatorType, getUser } from '@store/features';
import { getGlobalPortCongestion } from '@api';
import { useSegment } from '@hooks';
import moment from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';

export const useGlobalPortWaitingTimeChart = (globalPortCongestion, disableDownload = false) => {
  const [monthlyComparison, setMonthlyComparison] = useState({});
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchMonthlyComparison = async () => {
      try {
        await getGlobalPortCongestion(
          {
            OperatorTypeId: operatorType,
            SegmentTypeId: segment.id
          },
          signal
        ).then((result) => {
          setMonthlyComparison(result?.globaCongestionComparison || {});
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    fetchMonthlyComparison();
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, operatorType]);

  const { series = [], maxValue = 1 } = useMemo(() => {
    if (!globalPortCongestion) return {};
    const {
      averageMonthlyPWTDaysPolyfit = [],
      years,
      globaCongestionComparison = []
    } = globalPortCongestion;
    let max = 0;
    let averagePortCalls = [],
      vesselsWaiting = [];

    years?.forEach(({ months }) => {
      months.forEach(({ averageWaitingTimeDays, numWaitingEvents }) => {
        max = max < averageWaitingTimeDays ? averageWaitingTimeDays : max;
        averagePortCalls.push(averageWaitingTimeDays?.toFixed(2) ?? 0);
        vesselsWaiting.push(numWaitingEvents ?? 0);
      });
    });

    const polyFitFormatted = averageMonthlyPWTDaysPolyfit?.map((item) => item.toFixed(2)) || [];

    const tempSeries = [
      {
        name: 'Average Port Waiting Time [Days]',
        type: 'column',
        data: averagePortCalls
      },
      {
        name: 'Vessels waiting',
        type: 'line',
        data: vesselsWaiting
      },
      {
        name: 'Average Port Waiting Time Trendline [Days]',
        data: polyFitFormatted || [],
        type: 'line'
      }
    ];

    return { series: tempSeries, maxValue: max, details: { ...globaCongestionComparison } };
  }, [globalPortCongestion]);

  const options = useMemo(() => {
    const endDate = moment();
    const last12MonthsStartDate = moment(endDate).subtract(11, 'months').startOf('month');
    const dateList = getDateList(last12MonthsStartDate, endDate);

    const {
      palette: {
        mode,
        charts: { background, threeColors, twoColorsContrast }
      }
    } = theme;

    return {
      chart: {
        height: 480,
        type: 'mixed',
        background: background,
        fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        zoom: { enabled: false },
        toolbar: {
          show: !disableDownload,
          tools: { download: checkUserHasAccess(user, true) },
          export: {
            csv: { filename: 'global-average-port-waiting-time' },
            svg: { filename: 'global-average-port-waiting-time' },
            png: { filename: 'global-average-port-waiting-time' }
          }
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: '50%'
        }
      },
      dataLabels: { enabled: false },
      stroke: {
        width: [0, 3, 2],
        curve: 'straight',
        dashArray: [0, 0, 6]
      },
      colors: [twoColorsContrast[1], threeColors[1], twoColorsContrast[0]],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value, { seriesIndex }) {
            if (seriesIndex === 1) return value;
            return value?.toFixed(2);
          }
        },
        x: { show: true }
      },
      markers: {
        size: [0, 3],
        strokeColors: '#ffffff',
        strokeWidth: 1,
        hover: { size: 5 }
      },
      fill: { type: 'solid' },
      theme: { mode },
      yaxis: [
        {
          min: 0,
          max: () => maxValue * 1.15,
          tickAmount: 5,
          decimalsInFloat: 2,
          title: { text: 'DAYS' }
        },
        {
          opposite: true,
          min: (min) => min * 0.85,
          max: (max) => max * 1.15,
          decimalsInFloat: 0,
          tickAmount: 5,
          labels: {
            style: { colors: [threeColors[1]] },
            formatter: (value) => formatNumberReadable(+formatToDecimalPlaces(value, 0))
          },
          title: {
            text: 'NO OF VESSELS',
            style: { color: threeColors[1] }
          }
        },
        { min: 0, max: maxValue * 1.15, tickAmount: 5, decimalsInFloat: 2, show: false }
      ],
      xaxis: {
        categories: dateList,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: -30,
        markers: { width: 8, height: 8, radius: [0, 12, 12] }
      }
    };
  }, [theme, maxValue, user, disableDownload]);

  return { series, options, monthlyComparison };
};

function GlobalAveragePortWaitingTimeChart({ globalPortCongestion, loading }) {
  const { series, options, monthlyComparison } =
    useGlobalPortWaitingTimeChart(globalPortCongestion);

  return (
    <Stack spacing={2}>
      <ApexChart
        loading={loading || !options}
        data={series}
        height={480}
        options={options || {}}
        type={'bar'}
      />
      <Typography variant={'body1'} align="left">
        Monthly Comparison: Latest completed month vs previous month
      </Typography>
      <ul>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>Average waiting time per vessel:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {`${getNumberWithSign(
                monthlyComparison?.avgWaitingTimeDays?.toFixed(2)
              )} days/vessel (${getNumberWithSign(
                monthlyComparison.avgWaitingTimePercent?.toFixed(2)
              )}%)`}
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>Total waiting time:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {`${getNumberWithSign(
                monthlyComparison?.totalWaitingTimeDays?.toFixed(2)
              )} days (${getNumberWithSign(
                monthlyComparison.totalWaitingTimePercent?.toFixed(2)
              )}%)`}
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>Number of vessels:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {`${getNumberWithSign(
                monthlyComparison?.numOfVessels?.toFixed(2)
              )} vessels (${getNumberWithSign(
                monthlyComparison.numOfVesselsPercent?.toFixed(2)
              )}%)`}
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>Supply/Demand impact:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {`${getNumberWithSign(
                monthlyComparison?.supplyDemandImpact?.toFixed(2)
              )} ship equivalent`}
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>
              Highest rate of increase (Avg Port Waiting Time)
            </Typography>
            <ProfileLink
              linkVariant={'body1'}
              uriParams={'section=3'}
              profile={'port'}
              id={monthlyComparison.highestIncreasePortId}
              name={
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Typography>{monthlyComparison.highestIncreasePortName}</Typography>
                  <ChevronRightIcon
                    fontSize={'small'}
                    color={'primary'}
                    style={{ verticalAlign: 'middle', marginLeft: '-4px' }}
                  />
                </Stack>
              }
            />
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>
              Highest rate of decrease (Avg Port Waiting Time)
            </Typography>
            <ProfileLink
              linkVariant={'body1'}
              profile={'port'}
              uriParams={'section=3'}
              id={monthlyComparison.highestDecreasePortId}
              name={
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Typography>{monthlyComparison.highestDecreasePortName}</Typography>
                  <ChevronRightIcon
                    fontSize={'small'}
                    color={'primary'}
                    style={{ verticalAlign: 'middle', marginLeft: '-4px' }}
                  />
                </Stack>
              }
            />
          </Stack>
        </li>
      </ul>
    </Stack>
  );
}

GlobalAveragePortWaitingTimeChart.propTypes = {
  loading: PropTypes.bool,
  globalPortCongestion: PropTypes.object
};

GlobalAveragePortWaitingTimeChart.defaultProps = {
  loading: false,
  globalPortCongestion: null
};

export default GlobalAveragePortWaitingTimeChart;
