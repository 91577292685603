import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Table, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, getCovertTimeToDays } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { PORT_CALLS_TEXT, PORT_TIME_TEXT, PORT_WAITING_TIME_TEXT } from '@constants/tooltips';
import { PREV_MONTH } from '@constants';

function PortsInCountryTable({ countryName, countryPortsData, loading, hideColumns }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const columns = useMemo(() => {
    return [
      {
        name: 'portName',
        label: 'Port Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          setCellProps: () => {
            return {
              style: {
                width: '10vw',
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={rowData[0]} profile={'port'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'id',
        options: { display: false, viewColumns: false, filter: false, download: false }
      },
      {
        name: 'unlocode',
        label: 'UNLOCODE',

        options: {
          display: !hideColumns.includes('portCode'),
          download: !hideColumns.includes('portCode'),
          filter: !hideColumns.includes('portCode'),
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect'
        }
      },
      {
        name: 'totalPortCallsLastMonth',
        label: 'Port Calls',
        options: {
          hint: PORT_CALLS_TEXT + PREV_MONTH,
          sort: true,
          sortThirdClickReset: true
        }
      },
      {
        name: 'avgPortTimeLastMonth',
        label: 'Avg Port Time (days)',
        options: {
          hint: PORT_TIME_TEXT + PREV_MONTH,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value, tableMeta) => {
            const rowData = tableMeta.rowData;
            const timeValue = rowData && rowData[4] ? rowData[4].toString() : null;
            return timeValue ? getCovertTimeToDays(timeValue) : '-';
          }
        }
      },
      {
        name: 'avgPortWaitingTimeLastMonth',
        label: 'Avg Port Waiting time (days)',
        options: {
          hint: PORT_WAITING_TIME_TEXT + PREV_MONTH,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value, tableMeta) => {
            const rowData = tableMeta.rowData;
            const timeValue = rowData && rowData[5] ? rowData[5].toString() : null;
            return timeValue ? getCovertTimeToDays(timeValue) : '-';
          }
        }
      }
    ];
  }, [hideColumns]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: 'ports-in-country-overview.csv'
      },
      setTableProps: () => {
        return {
          id: 'ports-in-regions-table'
        };
      },
      print: false,
      viewColumns: false,
      pagination: false,
      tableBodyMaxHeight: '30vh',
      toolbar: checkUserHasAccess(user, true),
      rowsPerPageOptions: [10, 25, 50, 100],
      elevation: 0,
      sortOrder: {
        name: 'totalPortCallsLastMonth',
        direction: 'desc'
      }
    };
  }, []);

  return (
    <Paper sx={{ overflow: 'auto', minHeight: '100%' }}>
      <Table
        customStyle={{
          MUIDataTableFilterList: { display: 'none' }
        }}
        title={
          <Stack>
            <Typography id={'regions-title'} variant={'h6'}>
              Ports
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'}>
              {countryName}
            </Typography>
          </Stack>
        }
        skeletonRows={3}
        loading={loading}
        mode={theme.mode}
        data={countryPortsData || []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

PortsInCountryTable.propTypes = {
  countryPortsData: PropTypes.arrayOf(PropTypes.object),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  countryName: PropTypes.string
};

PortsInCountryTable.defaultProps = {
  countryPortsData: [],
  hideColumns: [],
  loading: false,
  countryName: ''
};

export default PortsInCountryTable;
