import React from 'react';
import mapboxgl from 'mapbox-gl';
import { renderToString } from 'react-dom/server';
import { VesselIcon, PortPinIcon } from '@components/Icons';
import { formatToDecimalPlaces } from '@helpers';

export const INBOUND_VESSELS_COLOR_PALETTE = ['#1480FF', '#B965CE', '#90D8CB', '#EF9FB2'];

export const VESSEL_DEFAULT_ICON_COLOR = '#0E9EFF';
export const LOW_SPEED_VESSEL_ICON_COLOR = '#FF0000';
export const MEDIUM_SPEED_VESSEL_ICON_COLOR = '#FFA500';
export const HIGH_SPEED_VESSEL_ICON_COLOR = '#32CD32';

export const getVesselInfoIcons = ({ showVesselsStatus, showPorts, showVessels }) => {
  const vesselIcons = !showVesselsStatus
    ? [
        {
          label: 'Vessel',
          icon: <VesselIcon color={VESSEL_DEFAULT_ICON_COLOR} size={12} />
        }
      ]
    : [
        {
          label: 'Speed < 1',
          icon: <VesselIcon color={LOW_SPEED_VESSEL_ICON_COLOR} />
        },
        {
          label: 'Speed 1-3',
          icon: <VesselIcon color={MEDIUM_SPEED_VESSEL_ICON_COLOR} />
        },
        {
          label: 'Speed > 3',
          icon: <VesselIcon color={HIGH_SPEED_VESSEL_ICON_COLOR} />
        }
      ];

  return [
    ...(showVessels ? vesselIcons : []),
    ...(showPorts ? [{ label: 'Port', icon: <PortPinIcon /> }] : [])
  ];
};

export const getInboundVesselsInfoIcons = ({
  selectedLocations,
  showPorts,
  showVessels,
  showInboundVesselsColoredPorts,
  showColoredInboundVessels
}) => {
  const icons = [];

  // Add default port icon
  if (showPorts && !showInboundVesselsColoredPorts) {
    icons.push({ label: 'Port', icon: <PortPinIcon /> });
  }

  // Add default vessel icon
  if (showVessels && !showColoredInboundVessels) {
    icons.push({ label: 'Vessels', icon: <VesselIcon /> });
  }

  // Add colored icons for each selected location
  selectedLocations.forEach((location, index) => {
    const color = INBOUND_VESSELS_COLOR_PALETTE[index];
    const { name, type } = location;

    if (showPorts && showInboundVesselsColoredPorts) {
      icons.push({
        label: type === 'Port' ? `Port: ${name}` : `Ports in ${name}`,
        icon: <PortPinIcon color={color} />
      });
    }

    if (showVessels && showColoredInboundVessels) {
      icons.push({
        label: `Vessels to ${name}`,
        icon: <VesselIcon color={color} />
      });
    }
  });

  return icons;
};

export const getInboundVesselsPortIcon = ({
  singlePort,
  showInboundVesselsColoredPorts,
  inboundVesselsLocations
}) => {
  if (!showInboundVesselsColoredPorts || !inboundVesselsLocations?.some(Boolean)) {
    return <PortPinIcon />;
  }

  const portIndex = inboundVesselsLocations.findIndex(
    (location) =>
      (location.type === 'Port' && location.id === singlePort.id) ||
      (location.type === 'Country' && location.id === singlePort.country.id)
  );

  const color = portIndex !== -1 ? INBOUND_VESSELS_COLOR_PALETTE[portIndex] : undefined;
  return <PortPinIcon color={color} />;
};

export const getTransparentBackground = (themeMode) => {
  return themeMode ? 'rgba(85, 85, 85, 0.8)' : 'rgba(0, 0, 0, 0.2)';
};

export const getVesselColor = ({
  speed,
  showVesselsStatus,
  inboundVesselsLocations,
  destinationPortId,
  lookupPorts,
  showColoredInboundVessels
}) => {
  const hasInboundVesselsLocations =
    inboundVesselsLocations && inboundVesselsLocations?.some(Boolean);
  if (hasInboundVesselsLocations && showColoredInboundVessels) {
    const matchedPortIndex = inboundVesselsLocations.findIndex(
      (location) => location.type === 'Port' && location.id === destinationPortId
    );
    if (matchedPortIndex !== -1) {
      return INBOUND_VESSELS_COLOR_PALETTE[matchedPortIndex];
    }
    const matchedCountryIndex = inboundVesselsLocations.findIndex((location) =>
      lookupPorts?.some((port) => port.id === destinationPortId && location.id === port.country.id)
    );
    if (matchedCountryIndex !== -1) {
      return INBOUND_VESSELS_COLOR_PALETTE[matchedCountryIndex];
    }
    return INBOUND_VESSELS_COLOR_PALETTE[0];
  }
  if (!showVesselsStatus) return VESSEL_DEFAULT_ICON_COLOR;
  if (speed >= 0 && speed < 1) return LOW_SPEED_VESSEL_ICON_COLOR;
  if (speed > 1 && speed <= 3) return MEDIUM_SPEED_VESSEL_ICON_COLOR;
  return HIGH_SPEED_VESSEL_ICON_COLOR;
};

const getPopupBackground = (themeMode) => {
  return themeMode ? 'background: rgba(255, 255, 255, 0.8)' : 'background: rgba(11,26,46,0.7)';
};

export const flyToTheLocation = (mapInstance, lat, lon, zoom = 5) => {
  mapInstance.flyTo({ center: [lon, lat], zoom: zoom, duration: 2000 });
};

const createMarkerElement = ({
  speed,
  heading,
  course,
  showVesselsStatus,
  inboundVesselsLocations,
  destinationPortId,
  lookupPorts,
  showColoredInboundVessels
}) => {
  const el = document.createElement('div');
  const markerSpan = document.createElement('span');
  const rotate = heading || course;

  markerSpan.style.cssText = `
    height: 15px;
    width: 15px;
    cursor: pointer;
    font-size: 15px;   
    position: absolute;
    transform: rotateZ(${rotate}deg);
  `;

  const vesselIconContainer = document.createElement('div');
  vesselIconContainer.innerHTML = renderToString(
    <VesselIcon
      color={getVesselColor({
        speed,
        showVesselsStatus,
        inboundVesselsLocations,
        destinationPortId,
        lookupPorts,
        showColoredInboundVessels
      })}
      size={12}
    />
  );
  markerSpan.appendChild(vesselIconContainer);

  el.className = 'ship-marker';
  el.appendChild(markerSpan);

  return el;
};

const createPortMarkerElement = ({
  singlePort,
  showInboundVesselsColoredPorts,
  inboundVesselsLocations
}) => {
  const el = document.createElement('div');
  const markerSpan = document.createElement('span');

  markerSpan.style.cssText = `
    height: 15px;
    width: 15px;
    cursor: pointer;
    font-size: 15px;   
    position: absolute;
  `;

  const portIconContainer = document.createElement('div');
  portIconContainer.innerHTML = renderToString(
    getInboundVesselsPortIcon({
      singlePort,
      showInboundVesselsColoredPorts,
      inboundVesselsLocations
    })
  );
  markerSpan.appendChild(portIconContainer);

  markerSpan.className = 'icon-port';
  el.className = 'port-marker';
  el.appendChild(markerSpan);

  return el;
};

const createPopupContent = ({ background, getTimeZoneDisplay, vesselLatestPosition }) => {
  const {
    vesselName,
    timestamp,
    operatorShortName,
    destinationPortName,
    destinationPortUnlocode,
    percentDraught,
    draught,
    vesselDraught,
    eta
  } = vesselLatestPosition;
  const textColor = background.includes('rgba(255, 255, 255') ? '#000' : '#fff';
  const baseStyles = `
    color: ${textColor};
    font-size: 14px;
  `;

  const createRow = (label, value) => `
    <div style="display: grid; grid-template-columns: minmax(120px, auto) 1fr; gap: 8px;">
      <span style="${baseStyles}; text-align: left; white-space: nowrap;">${label}</span>
      <span style="${baseStyles}; text-align: right;">${value}</span>
    </div>
  `;

  const rows = [
    createRow('ETA', eta ? getTimeZoneDisplay(eta) : '-'),
    createRow(
      'Destination',
      destinationPortName && destinationPortUnlocode
        ? `${destinationPortName} (${destinationPortUnlocode})`
        : '-'
    ),
    createRow('Position Received', timestamp ? getTimeZoneDisplay(timestamp) : '-'),
    createRow('Operator', operatorShortName ?? '-'),
    createRow(
      'Draught',
      draught && vesselDraught && percentDraught
        ? `${formatToDecimalPlaces(draught, 1)} / ${formatToDecimalPlaces(
            vesselDraught,
            1
          )} (${formatToDecimalPlaces(percentDraught, 1)}%)`
        : '-'
    )
  ];

  return `
    <div style="${background}; border-radius: 4px; overflow: hidden;">  
      <div style="padding: 12px 16px; display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid rgba(255,255,255,0.1)">
        <span style="${baseStyles}; font-size: 14px; font-weight:600;">${vesselName}</span>
      </div>
      <div style="padding: 12px 16px;">
        <div style="display: grid; gap: 12px;">
          ${rows.join('')}
        </div>
      </div>
    </div>`;
};

const createNamePopup = (name, lon, lat) => {
  return new mapboxgl.Popup({
    className: 'mapbox-custom-popup-text',
    closeButton: false,
    closeOnMove: false,
    closeOnClick: false
  })
    .setMaxWidth('none')
    .setHTML(
      renderToString(
        <span style={{ padding: '0 6px' }} className="background-color: rgba(255, 255, 255, 0.5);">
          {name}
        </span>
      )
    )
    .setLngLat([lon, lat]);
};

export const createVesselMarkers = ({
  mapInstance,
  vessels,
  themeMode,
  getTimeZoneDisplay,
  onVesselSelect,
  showVesselsNames,
  showVesselsStatus,
  inboundVesselsLocations,
  lookupPorts,
  showColoredInboundVessels
}) => {
  if (!mapInstance) return;

  const popupOptions = {
    closeButton: false,
    closeOnClick: false
  };

  const background = getPopupBackground(themeMode);

  return (
    vessels?.map((singleVessel) => {
      const {
        lat,
        long: lon,
        vesselName,
        speed,
        heading,
        course,
        destinationPortId
      } = singleVessel;
      const el = createMarkerElement({
        speed,
        heading,
        course,
        showVesselsStatus,
        inboundVesselsLocations,
        destinationPortId,
        lookupPorts,
        showColoredInboundVessels
      });
      const popup = new mapboxgl.Popup(popupOptions);

      el.addEventListener('click', () => {
        onVesselSelect?.(singleVessel);
      });

      el.addEventListener('mouseenter', () => {
        mapInstance.getCanvas().style.cursor = 'pointer';
        const coordinates = [lon, lat];

        while (Math.abs(lon - coordinates[0]) > 180) {
          coordinates[0] += lon > coordinates[0] ? 360 : -360;
        }

        popup
          .setMaxWidth('330px')
          .setLngLat(coordinates)
          .setHTML(
            createPopupContent({
              background,
              getTimeZoneDisplay,
              port: lookupPorts.find((port) => port.id === destinationPortId),
              vesselLatestPosition: singleVessel
            })
          )
          .addTo(mapInstance);
      });

      el.addEventListener('mouseleave', () => {
        popup.remove();
      });

      const marker = new mapboxgl.Marker(el)
        .setLngLat([lon, lat])
        .setPopup(popup)
        .addTo(mapInstance);
      if (showVesselsNames) {
        createNamePopup(vesselName, lon, lat)
          .setOffset(new mapboxgl.Point(0, -4))
          .addTo(mapInstance);
      }
      return marker;
    }) || []
  );
};

export const createPortMarkers = ({
  map,
  ports,
  showPortNames,
  onPortSelect,
  inboundVesselsLocations,
  showInboundVesselsColoredPorts
}) => {
  if (!map) return;

  return (
    ports?.map((port) => {
      const { cpointlat: lat, cpointlon: lon, portName } = port;
      const el = createPortMarkerElement({
        singlePort: port,
        showInboundVesselsColoredPorts,
        inboundVesselsLocations
      });
      const marker = new mapboxgl.Marker(el).setLngLat([lon, lat]).addTo(map);

      if (!showPortNames) {
        const popup = createNamePopup(portName.toUpperCase(), lon, lat);
        el.addEventListener('mouseenter', () => {
          popup.setOffset(new mapboxgl.Point(0, -4)).addTo(map);
        });

        el.addEventListener('mouseleave', () => {
          popup.remove();
        });
      }

      if (showPortNames) {
        createNamePopup(portName, lon, lat).setOffset(new mapboxgl.Point(0, -4)).addTo(map);
      }

      el.addEventListener('click', () => {
        onPortSelect?.(port);
      });

      return marker;
    }) || []
  );
};

export const getVesselNamePopup = (vesselName, lon, lat) => {
  return createNamePopup(vesselName, lon, lat);
};
