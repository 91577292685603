import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box, Paper, Typography } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { useGlobalAveragePortTimeData } from '../../../../Charts/GlobalAveragePortTimeChart/GlobalAveragePortTimeChart';
import moment from 'moment';
import { getDateFormat } from '@store/features';
import { formatToDecimalPlaces } from '@helpers';
import { ProfileLink } from '@components/Links';

function GlobalPortTimeInsight({ globalPortStats, lookupPorts }) {
  const dateFormat = useSelector(getDateFormat);

  const startDate = moment().subtract(12, 'month').startOf('month').format(dateFormat);
  const endDate = moment().subtract(1, 'month').endOf('month').format(dateFormat);

  const { series, options, details } = useGlobalAveragePortTimeData(
    globalPortStats,
    true,
    false,
    startDate,
    endDate
  );

  const mainInsight = [
    {
      label: 'Global avg port time (Days)',
      value: formatToDecimalPlaces(details.totalTerminalDays, 2),
      increase: formatToDecimalPlaces(details.totalTerminalPercent, 2)
    },
    {
      label: 'Avg turn around time (Days)',
      value: formatToDecimalPlaces(details.avgTurnAroundDays, 2),
      increase: formatToDecimalPlaces(details.avgTurnAroundPercent, 2)
    },
    {
      label: 'Port calls',
      value: details.portCalls,
      increase: formatToDecimalPlaces(details.portCallsPercent, 2)
    },
    {
      label: 'Supply/Demand impact (Ship equivalent)',
      value: formatToDecimalPlaces(details.supplyDemandImpact, 2),
      increase: 0
    }
  ];

  const highestRateIncrease = useMemo(() => {
    if (!details) return [];

    const port = lookupPorts.find((port) => port.id === details.highestIncreasePortId);

    return [
      {
        label: 'Port - Avg port time (Days)',
        value: port ? `${port.portName}, ${port.country.countryName}` : '-',
        increase: 0,
        id: details.highestIncreasePortId
      }
    ];
  }, [details, lookupPorts]);

  const highestRateDecrease = useMemo(() => {
    if (!details) return [];

    const port = lookupPorts.find((port) => port.id === details.highestDecreasePortId);

    return [
      {
        label: 'Port - Avg port time (Days)',
        value: port ? `${port.portName}, ${port.country.countryName}` : '-',
        increase: 0,
        id: details.highestDecreasePortId
      }
    ];
  }, [details, lookupPorts]);

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
      <Typography sx={{ fontWeight: 'bold' }}>Global Average Port Time</Typography>
      <ApexChart data={series} height={300} options={options} type={'bar'} />
      <Box display="flex" flexDirection="column" gap={1}>
        <Box>
          <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary' }}>
            Main Insights
          </Typography>
          <Typography variant="caption">Latest completed month vs previous month</Typography>
        </Box>
        <ul
          style={{
            padding: 0,
            margin: 0,
            paddingLeft: 32,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {mainInsight.map((item) => (
            <li key={item.label}>
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                <Box display="flex" gap={0.5} alignItems="center">
                  <Typography sx={{ fontSize: '14px' }}>{item.value}</Typography>
                  <Typography
                    sx={{
                      color: item.increase > 0 ? 'success.main' : 'error.main',
                      fontSize: '0.7rem'
                    }}>
                    {item.increase > 0 ? `+${item.increase}%` : `${item.increase}%`}
                  </Typography>
                </Box>
              </Box>
            </li>
          ))}
        </ul>

        <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary', mt: 2 }}>
          Highest Rate of Increase
        </Typography>
        <ul
          style={{
            padding: 0,
            margin: 0,
            paddingLeft: 32,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {highestRateIncrease.map((item) => (
            <li key={item.label}>
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                <ProfileLink
                  uriParams={'section=2'}
                  profile={'port'}
                  id={item?.id}
                  name={item.value}
                  linkVariant="body1"
                  textAlign="right"
                  fontSize="14px"
                />
              </Box>
            </li>
          ))}
        </ul>

        <Typography sx={{ textTransform: 'uppercase', color: 'text.secondary', mt: 2 }}>
          Highest Rate of Decrease
        </Typography>
        <ul
          style={{
            padding: 0,
            margin: 0,
            paddingLeft: 32,
            display: 'flex',
            flexDirection: 'column'
          }}>
          {highestRateDecrease.map((item) => (
            <li key={item.label}>
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontSize: '14px' }}>{item.label}</Typography>
                <ProfileLink
                  uriParams={'section=2'}
                  profile={'port'}
                  id={item?.id}
                  name={item.value}
                  linkVariant="body1"
                  textAlign="right"
                  fontSize="14px"
                />
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    </Paper>
  );
}

GlobalPortTimeInsight.propTypes = {
  globalPortStats: PropTypes.object,
  lookupPorts: PropTypes.array
};

export default GlobalPortTimeInsight;
