import React from 'react';
import PropTypes from 'prop-types';

const VesselIcon = ({ color = '#0E9EFF', size = 14, selected = false }) => {
  const width = selected ? size * 1.2 : size;
  const height = selected ? size * 1.8 : size * 1.5;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="0" dy="0" result="offsetblur" />
          <feFlood floodColor="white" />
          <feComposite in2="offsetblur" operator="in" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        d="M1 12L4.00001 1.73828L7.00001 12L4.00001 9L1 12Z"
        fill={color}
        stroke={selected ? 'white' : 'none'}
        strokeWidth={selected ? 1 : 0}
        strokeLinejoin="round"
        filter={selected ? 'url(#glow)' : 'none'}
      />
    </svg>
  );
};

VesselIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  selected: PropTypes.bool
};

export default VesselIcon;
