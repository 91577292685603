import {
  SAVED_VOYAGE_GROUPS_KEY,
  getSavedVoyageGroups,
  getReducedTransitDetails,
  updateVoyageGroup
} from '@api';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features/auth/AuthSlice.js';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  VoyageType,
  generateVoyageIdFromSavedVoyage,
  getTimeDaysAgo,
  getTransitDays
} from '../../../components/Sections/VoyageAnalytics/VoyageUtils';
import { useMemo, useState } from 'react';
import { getChunkedArray } from '@helpers';
import { VESSEL_MAX_TRANSIT_TIME } from '../../../constants';
import moment from 'moment';

const SHARED_VOYAGE_GROUP_EMAIL = 'shared.voyageGroup@esgian.com';

const getGroupIdFromSharedVoyageGroup = (sharedVoyageGroupId) =>
  sharedVoyageGroupId.split('~').slice(0, -1).join('~');

const fetchSavedVoyageGroups = async (email, signal) => {
  const data = await getSavedVoyageGroups(email, signal);
  const parsed = JSON.parse(data.userInformation);
  return parsed.sort((a, b) =>
    a.isFavorite && !b.isFavorite ? -1 : b.isFavorite && !a.isFavorite ? 1 : 0
  );
};

const fetchTransitDetailsQueryFn = async ({ groupId, email, signal }) => {
  const data = await getSavedVoyageGroups(email, signal);
  const existingVoyageGroups = JSON.parse(data.userInformation);
  const foundSingleSavedVoyageGroup = existingVoyageGroups.find(
    (group) => group.id.toString() === groupId.toString()
  );
  if (!foundSingleSavedVoyageGroup) {
    throw new Error('Voyage group not found');
  }

  const voyageGroupType = foundSingleSavedVoyageGroup?.type ?? VoyageType.COMPLETED;

  const filterParams = foundSingleSavedVoyageGroup.voyages.map((v) => {
    const isCompletedVoyageGroup = voyageGroupType !== VoyageType.ONGOING;
    let startDate = v.departureDate;
    if (
      !isCompletedVoyageGroup &&
      startDate &&
      getTransitDays(startDate, moment()) > VESSEL_MAX_TRANSIT_TIME
    ) {
      startDate = getTimeDaysAgo(VESSEL_MAX_TRANSIT_TIME);
    }
    return {
      imo: v.imo,
      fromPortId: parseInt(v.fromPortId),
      toPortId: v.toPortId && isCompletedVoyageGroup ? parseInt(v.toPortId) : null,
      startDate,
      endDate: v.arrivalDate && isCompletedVoyageGroup ? v.arrivalDate : null,
      reductionFactor: 4
    };
  });

  const chunked = getChunkedArray(filterParams, 50);

  const promises = chunked.map((chunk) => getReducedTransitDetails(chunk, signal));
  const responses = await Promise.all(promises);

  const flattened = responses.flat();

  const withOperators = flattened.map((d, i) => ({
    ...d,
    operator: `Operator~${i % 10}`
  }));

  return {
    ...foundSingleSavedVoyageGroup,
    voyages: foundSingleSavedVoyageGroup.voyages.map((singleSavedVoyage) => ({
      ...singleSavedVoyage,
      details: withOperators.find(
        (d) => +d.voyageReducedTransitDetails.imo === +singleSavedVoyage.imo
      )
    }))
  };
};

const VoyageDetailsQueryKey = 'voyageDetails';

const MAX_COMPLETED_VOYAGE_PER_GROUP = 150;
const MAX_ONGOING_VOYAGE_PER_GROUP = 150;

const filterAllowedProperties = (voyageGroup) => {
  return {
    id: voyageGroup.id,
    name: voyageGroup.name,
    type: voyageGroup?.type ?? VoyageType.COMPLETED,
    isFavorite: voyageGroup.isFavorite,
    voyages: voyageGroup?.voyages?.map((v) => ({
      imo: v.imo,
      fromPortId: v.fromPortId,
      toPortId: v.toPortId ?? null,
      departureDate: v.departureDate,
      arrivalDate: v.arrivalDate ?? null,
      toPortType: v.toPortType
    }))
  };
};

// TODO: Use this once the simulated voyage group is implemented
// const filterSimulatedVoyageGroupAllowedProperties = (voyageGroup) => {
//   return {
//     id: voyageGroup.id,
//     name: voyageGroup.name,
//     type: VoyageType.SIMULATED,
//     voyages: voyageGroup.voyages.map((v) => ({
//       imo: v.imo,
//       fromPortId: v.fromPortId,
//       toPortId: v.toPortId ?? null,
//       departureDate: v.departureDate,
//       arrivalDate: v.arrivalDate ?? null,
//       toPortType: v.toPortType
//     }))
//   };
// };

export const useVoyageGroups = (props = {}) => {
  const { voyageGroupId } = props;
  const user = useSelector(getUser);
  const email = user?.email;
  const queryClient = useQueryClient();
  const controller = new AbortController();
  const { signal } = controller;
  const isSharedVoyageGroup = voyageGroupId?.includes('shared');

  const [isDuplicateChecking, setIsDuplicateChecking] = useState(false);

  const cachedVoyageGroups = queryClient.getQueryData([SAVED_VOYAGE_GROUPS_KEY]) ?? [];

  const {
    data: savedVoyageGroups = [],
    isPending: isLoadingVoyageGroups,
    isFetching: isFetchingVoyageGroups
  } = useQuery({
    queryKey: [SAVED_VOYAGE_GROUPS_KEY],
    queryFn: async () => fetchSavedVoyageGroups(email, signal)
  });

  const {
    data: voyageGroupWithVoyageDetails,
    isFetching: isGettingSavedVoyagesDetails,
    isError: isErrorGettingVoyageGroupWithVoyageDetails
  } = useQuery({
    queryKey: [VoyageDetailsQueryKey, voyageGroupId?.toString()],
    queryFn: async () =>
      fetchTransitDetailsQueryFn({
        groupId: voyageGroupId?.toString(),
        email: isSharedVoyageGroup ? SHARED_VOYAGE_GROUP_EMAIL : email,
        signal
      }),
    enabled: !!voyageGroupId
  });
  const { mutateAsync: deleteVoyageGroup, isPending: isDeletingVoyageGroup } = useMutation({
    mutationFn: (voyageGroupToDelete) => {
      const updated = cachedVoyageGroups.filter((v) => v.id !== voyageGroupToDelete.id);
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, voyageGroupToDelete) => {
      toast.success(`Voyage group "${voyageGroupToDelete.name}" deleted successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    },
    onError: (_err, voyageGroupToDelete) => {
      toast.error(`Could not delete the voyage group: ${voyageGroupToDelete.name}`);
    }
  });

  const { mutateAsync: createVoyageGroup, isPending: isCreatingVoyageGroup } = useMutation({
    mutationFn: async ({ newVoyageGroupName, type = VoyageType.COMPLETED }) => {
      let existingGroups = [];
      try {
        existingGroups = await fetchSavedVoyageGroups(email, signal);
      } catch (err) {
        console.error('Error fetching existing groups:', err);
        existingGroups = cachedVoyageGroups;
      }
      const isGroupAlreadyExist = existingGroups.some(
        (g) => g?.type === type && g?.name === newVoyageGroupName
      );
      if (isGroupAlreadyExist) {
        throw new Error('Group with the same name already exists');
      }
      const newGroup = {
        id: `${Date.now()}~${type}`,
        name: newVoyageGroupName,
        voyages: [],
        type
      };

      const updated = [...cachedVoyageGroups, newGroup];
      await updateVoyageGroup(email, updated, signal);
      return newGroup;
    },
    onSuccess: (_data, { newVoyageGroupName }) => {
      toast.success(`Voyage group "${newVoyageGroupName}" created successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    },
    onError: (err, { newVoyageGroupName }) => {
      toast.error(`Could not create the voyage group: ${newVoyageGroupName}`);
    }
  });

  const { mutateAsync: renameVoyageGroup, isPending: isRenamingVoyageGroup } = useMutation({
    mutationFn: ({ newName, voyageGroupToRename }) => {
      const updated = cachedVoyageGroups.map((v) =>
        v.id === voyageGroupToRename.id ? { ...v, name: newName } : v
      );
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, { newName, voyageGroupToRename }) => {
      toast.success(
        `Voyage group "${voyageGroupToRename.name}" renamed to "${newName}" successfully.`
      );
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, voyageGroupToRename.id?.toString()]
      });
    },
    onError: (_err, { voyageGroupToRename, newName }) => {
      toast.error(
        `Could not rename the voyage group from "${voyageGroupToRename.name}" to "${newName}"`
      );
    }
  });

  const { mutateAsync: updateVoyageGroups, isPending: isUpdatingVoyageGroups } = useMutation({
    mutationFn: ({ updatedGroups }) => {
      const updated = cachedVoyageGroups.map((v) => {
        const matched = updatedGroups.find((g) => g.id === v.id);
        if (!matched) return v;
        return matched;
      });
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: () => {
      toast.success(`Voyage groups updated successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    },
    onError: () => {
      toast.error(`Could not update voyage groups.`);
    }
  });

  const { mutateAsync: deleteVoyageFromGroup, isPending: isDeletingVoyageFromGroup } = useMutation({
    mutationFn: ({ voyageGroupId, savedVoyageIdToDelete }) => {
      const updated = cachedVoyageGroups.map((v) =>
        v.id === voyageGroupId
          ? {
              ...v,
              voyages: v.voyages.filter(
                (v) => generateVoyageIdFromSavedVoyage(v) !== savedVoyageIdToDelete
              )
            }
          : v
      );
      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_, { voyageGroupId, savedVoyageIdToDelete }) => {
      toast.success(`Voyage deleted successfully.`);
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.setQueryData([VoyageDetailsQueryKey, voyageGroupId?.toString()], (old) => ({
        ...old,
        voyages: old.voyages.filter(
          (v) => generateVoyageIdFromSavedVoyage(v) !== savedVoyageIdToDelete
        )
      }));
    },
    onError: () => {
      toast.error(`Could not delete the voyage.`);
    }
  });
  const { mutateAsync: triggerAddVoyagesToGroup, isPending: isAddingVoyagesToGroup } = useMutation({
    mutationFn: async ({ targetGroup, newVoyagesToAdd, existingGroups }) => {
      const updated = existingGroups.map((group) =>
        group.id === targetGroup.id
          ? { ...group, voyages: [...group.voyages, ...newVoyagesToAdd] }
          : group
      );
      const res = await updateVoyageGroup(email, updated, signal);
      const parsedResponse = JSON.parse(res.userInformation);
      return parsedResponse;
    },
    onSuccess: (_data, { targetGroup, newVoyagesToAdd, duplicateVoyages }) => {
      toast.success(
        `Voyage${newVoyagesToAdd.length > 1 ? 's' : ''} saved to group successfully.${
          duplicateVoyages && duplicateVoyages.length > 0
            ? `Voyage${duplicateVoyages.length > 1 ? 's' : ''} ${duplicateVoyages.join(', ')} ${
                duplicateVoyages.length > 1 ? 'have' : 'has'
              } been excluded since ${
                duplicateVoyages.length > 1 ? 'they' : 'it'
              } already exist in the group.`
            : ''
        }`
      );
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, targetGroup.id?.toString()]
      });
    },
    onError: (_, { targetGroup, newVoyagesToAdd }) => {
      toast.error(
        `Unable to save voyage${newVoyagesToAdd.length > 1 ? 's' : ''} to the group "${
          targetGroup.name
        }".`
      );
    }
  });

  const { mutateAsync: updateVoyages, isPending: isUpdatingVoyages } = useMutation({
    mutationFn: async ({ targetGroupId, updatedVoyages }) => {
      const updated = cachedVoyageGroups.map((group) => {
        if (group.id === targetGroupId) {
          return {
            ...group,
            voyages: group.voyages.map(
              (v) =>
                updatedVoyages.find(
                  (uV) => generateVoyageIdFromSavedVoyage(uV) === generateVoyageIdFromSavedVoyage(v)
                ) ?? v
            )
          };
        }
        return group;
      });
      return updateVoyageGroup(email, updated, signal);
    },

    onSuccess: (_data, { targetGroupId, updatedVoyages }) => {
      toast.success('Voyages information updated successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.setQueryData([VoyageDetailsQueryKey, targetGroupId?.toString()], (old) => ({
        ...old,
        voyages: old.voyages.map(
          (v) =>
            updatedVoyages.find(
              (uV) => generateVoyageIdFromSavedVoyage(uV) === generateVoyageIdFromSavedVoyage(v)
            ) ?? v
        )
      }));
    },
    onError: () => {
      toast.error('Failed to update voyages information.');
    }
  });

  const { mutateAsync: addVoyageGroupToShared, isPending: isAddingVoyageGroupToShared } =
    useMutation({
      mutationFn: async ({ voyageGroupToAdd }) => {
        const newGroup = {
          ...filterAllowedProperties(voyageGroupToAdd),
          id: `${voyageGroupToAdd.id}~shared`
        };
        const existingExistingSharedGroup = await getSavedVoyageGroups(
          SHARED_VOYAGE_GROUP_EMAIL,
          signal
        );
        const parsedExistingSharedGroup = JSON.parse(existingExistingSharedGroup.userInformation);
        const filteredExistingSharedGroup = parsedExistingSharedGroup.filter(
          (g) => g.id !== newGroup.id
        );
        const updated = [...filteredExistingSharedGroup, newGroup];
        await updateVoyageGroup(SHARED_VOYAGE_GROUP_EMAIL, updated, signal);
        return newGroup;
      }
    });

  const { mutateAsync: saveSharedGroup, isPending: isSavingSharedGroup } = useMutation({
    mutationFn: async ({ sharedVoyageGroup }) => {
      const sharedGroupToAdd = {
        ...filterAllowedProperties(sharedVoyageGroup),
        id: getGroupIdFromSharedVoyageGroup(sharedVoyageGroup.id),
        isFavorite: false
      };
      const isGroupAlreadyExist = cachedVoyageGroups.some((g) => g.id === sharedGroupToAdd.id);
      const isSameName = cachedVoyageGroups.some(
        (g) => g?.type === sharedGroupToAdd?.type && g?.name === sharedGroupToAdd?.name
      );
      let errorMessage = '';
      if (isGroupAlreadyExist) {
        errorMessage = 'Group with the same id already exists.';
      }
      if (isSameName) {
        errorMessage += 'Group with the same name already exists';
      }
      if (isSameName && isGroupAlreadyExist) {
        errorMessage = 'Group with the same id and name already exists';
      }
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      const updated = [...cachedVoyageGroups, sharedGroupToAdd];
      await updateVoyageGroup(email, updated, signal);
      return sharedGroupToAdd;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
    }
  });

  const { mutateAsync: moveVoyage, isPending: isMovingVoyage } = useMutation({
    mutationFn: async ({ currentGroupId, targetGroupId, voyageToMove }) => {
      const isSharedVoyageGroup =
        currentGroupId.includes('shared') || targetGroupId.includes('shared');
      const currentVoyageGroupId = isSharedVoyageGroup
        ? currentGroupId.split('~').slice(0, -1).join('~')
        : currentGroupId;
      const voyageIdToMove = generateVoyageIdFromSavedVoyage(voyageToMove);

      let currentGroup = cachedVoyageGroups.find((g) => g.id === currentVoyageGroupId);
      if (isSharedVoyageGroup) {
        currentGroup = JSON.parse(
          (await getSavedVoyageGroups(SHARED_VOYAGE_GROUP_EMAIL, signal)).userInformation
        ).find((g) => g.id === currentGroupId);
      }
      const targetGroup = cachedVoyageGroups.find((g) => g.id === targetGroupId);
      if (!currentGroup) {
        throw new Error('Current group not found');
      }
      if (!targetGroup) {
        throw new Error('Target group not found');
      }
      const foundVoyageInCurrentGroup = currentGroup.voyages.find(
        (v) => generateVoyageIdFromSavedVoyage(v) === voyageIdToMove
      );
      if (!foundVoyageInCurrentGroup) {
        throw new Error('Voyage not found in current group');
      }

      const firstPortCall = voyageToMove.details?.voyagePortCalls?.portCalls?.[0];
      const arrivalPortCall = voyageToMove.details?.voyagePortCalls?.portCalls?.find(
        (singlePortCall) => singlePortCall.portId === foundVoyageInCurrentGroup.toPortId
      );

      const moveableVoyage = {
        ...foundVoyageInCurrentGroup,
        fromPortId: firstPortCall?.portId,
        departureDate: firstPortCall?.arrivalDate,
        arrivalDate: arrivalPortCall?.arrivalDate
      };

      const isAlreadyExistInTargetGroup = targetGroup.voyages.some(
        (v) =>
          generateVoyageIdFromSavedVoyage(v) === generateVoyageIdFromSavedVoyage(moveableVoyage)
      );

      if (isAlreadyExistInTargetGroup) {
        const msg = 'Voyage already exist in target group';
        toast.error(msg);
        throw new Error(msg);
      }

      const updated = cachedVoyageGroups.map((g) => {
        if (g.id === targetGroupId) {
          return {
            ...g,
            voyages: [...g.voyages, moveableVoyage]
          };
        }
        if (g.id === currentVoyageGroupId) {
          return {
            ...g,
            voyages: g.voyages.filter((v) => generateVoyageIdFromSavedVoyage(v) !== voyageIdToMove)
          };
        }
        return g;
      });

      return updateVoyageGroup(email, updated, signal);
    },
    onSuccess: (_data, { targetGroupId, currentGroupId, voyageToMove }) => {
      toast.success('Voyage moved to target group successfully.');
      queryClient.invalidateQueries({ queryKey: [SAVED_VOYAGE_GROUPS_KEY] });
      queryClient.invalidateQueries({
        queryKey: [VoyageDetailsQueryKey, targetGroupId?.toString()]
      });
      queryClient.setQueryData([VoyageDetailsQueryKey, currentGroupId?.toString()], (old) => ({
        ...old,
        voyages: old.voyages.filter(
          (v) =>
            generateVoyageIdFromSavedVoyage(v) !== generateVoyageIdFromSavedVoyage(voyageToMove)
        )
      }));
    },
    onError: () => {
      toast.error('Failed to move voyage.');
    }
  });

  const addVoyagesToGroup = async ({ voyageGroupId, voyagesToAdd }) => {
    setIsDuplicateChecking(true);
    const latestVoyageGroups = await fetchSavedVoyageGroups(email, signal);

    const targetGroup = latestVoyageGroups.find((group) => group.id === voyageGroupId);

    if (!targetGroup) {
      const errMessage = `Requested group not found.`;
      toast.error(errMessage);
      setIsDuplicateChecking(false);
      return;
    }

    const voyagesOnTargetGroup = targetGroup.voyages;

    const duplicateVoyages = [];

    const voyagesAfterEliminatingDuplicates = voyagesToAdd.filter((voyage) => {
      return !voyagesOnTargetGroup.some((voyageOnTargetGroup) => {
        const isMatch =
          generateVoyageIdFromSavedVoyage(voyage) ===
          generateVoyageIdFromSavedVoyage(voyageOnTargetGroup);
        if (isMatch) {
          duplicateVoyages.push(voyageOnTargetGroup.imo);
        }
        return isMatch;
      });
    });

    const voyagesToSave = [...voyagesOnTargetGroup, ...voyagesAfterEliminatingDuplicates];

    if (duplicateVoyages.length === voyagesToAdd.length) {
      const errMessage = `Voyage${
        duplicateVoyages.length > 1 ? 's' : ''
      } already exists in the group.`;
      toast.info(errMessage);
      setIsDuplicateChecking(false);
      return;
    }

    const maxVoyageAllowed =
      targetGroup?.type === VoyageType.COMPLETED
        ? MAX_COMPLETED_VOYAGE_PER_GROUP
        : MAX_ONGOING_VOYAGE_PER_GROUP;

    if (voyagesToSave.length > maxVoyageAllowed) {
      const errMessage =
        duplicateVoyages.length > 0
          ? `Voyage${duplicateVoyages.length > 1 ? 's' : ''} ${duplicateVoyages.join(
              ', '
            )} already exist in the group. Including ${
              duplicateVoyages.length > 1 ? 'them' : 'it'
            }, a group can have a maximum of ${maxVoyageAllowed} voyages.`
          : `A Group can have maximum ${maxVoyageAllowed} voyages.`;
      toast.error(errMessage);
      setIsDuplicateChecking(false);
      return;
    }
    setIsDuplicateChecking(false);
    return triggerAddVoyagesToGroup({
      targetGroup,
      newVoyagesToAdd: voyagesAfterEliminatingDuplicates,
      duplicateVoyages: duplicateVoyages,
      existingGroups: latestVoyageGroups
    });
  };

  const addSimulatedVoyagesToGroup = async ({ voyageGroupId, voyagesToAdd }) => {
    setIsDuplicateChecking(true);
    const latestVoyageGroups = await fetchSavedVoyageGroups(email, signal);
    const targetGroup = latestVoyageGroups.find((group) => group.id === voyageGroupId);
    if (!targetGroup) {
      const errMessage = `Requested group not found.`;
      toast.error(errMessage);
      setIsDuplicateChecking(false);
      return;
    }
    setIsDuplicateChecking(false);
    return triggerAddVoyagesToGroup({
      targetGroup,
      newVoyagesToAdd: voyagesToAdd,
      duplicateVoyages: [],
      existingGroups: latestVoyageGroups
    });
  };

  const prefetchTransitDetails = async (voyageGroupIdCollection) => {
    if (!voyageGroupIdCollection || voyageGroupIdCollection.length === 0) return;
    for (const voyageGroupId of voyageGroupIdCollection) {
      queryClient.prefetchQuery({
        queryKey: [VoyageDetailsQueryKey, voyageGroupId.toString()],
        queryFn: () =>
          fetchTransitDetailsQueryFn({ groupId: voyageGroupId.toString(), email, signal })
      });
    }
  };

  const completedVoyagesGroups = useMemo(
    () => savedVoyageGroups.filter((v) => !v?.type || v?.type === VoyageType.COMPLETED),
    [savedVoyageGroups]
  );

  const ongoingVoyagesGroups = useMemo(
    () => savedVoyageGroups.filter((v) => v?.type === VoyageType.ONGOING),
    [savedVoyageGroups]
  );

  const simulatedVoyagesGroups = useMemo(
    () => savedVoyageGroups.filter((v) => v?.type === VoyageType.SIMULATED),
    [savedVoyageGroups]
  );

  // updateVoyageGroup(email, [], signal);

  const loadingSavedVoyagesMessage = isGettingSavedVoyagesDetails
    ? 'Fetching voyage details. This may take a moment. Please wait...'
    : null;

  return {
    savedVoyageGroups,
    completedVoyagesGroups,
    ongoingVoyagesGroups,
    simulatedVoyagesGroups,
    isLoadingVoyageGroups,
    isFetchingVoyageGroups,
    deleteVoyageGroup,
    isDeletingVoyageGroup,
    createVoyageGroup,
    isCreatingVoyageGroup,
    renameVoyageGroup,
    isRenamingVoyageGroup,
    deleteVoyageFromGroup,
    isDeletingVoyageFromGroup,
    voyageGroupWithVoyageDetails,
    isGettingSavedVoyagesDetails,
    addVoyagesToGroup,
    isAddingVoyagesToGroup: isDuplicateChecking || isAddingVoyagesToGroup,
    isErrorGettingVoyageGroupWithVoyageDetails,
    updateVoyages,
    isUpdatingVoyages,
    moveVoyage,
    isMovingVoyage,
    loadingSavedVoyagesMessage,
    updateVoyageGroups,
    isUpdatingVoyageGroups,
    prefetchTransitDetails,
    isSharedVoyageGroup,
    addVoyageGroupToShared,
    isAddingVoyageGroupToShared,
    saveSharedGroup,
    isSavingSharedGroup,
    addSimulatedVoyagesToGroup
  };
};
