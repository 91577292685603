import { BaseApiRequest } from '@api/BaseApi';
import { objectToQuery } from '@api/helpers';

export const getOperatorProfile = (data, signal) => {
  const query = objectToQuery(data);

  return BaseApiRequest(signal, `/Operator?${query}`);
};

export const getOperatorStats = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/OperatorStats?${query}`);
};

export const getTradeRoutePorts = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/TradeRoute?${query}`);
};

export const getOperatorInformation = (signal) => {
  return BaseApiRequest(signal, `/OperatorInformation`);
};
