import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CountryFlag, Grid, Paper, Table, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, getCovertTimeToDays } from '@helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { ProfileLink } from '@components/Links';
import { PORT_CALLS_TEXT, PORT_TIME_TEXT, PORT_WAITING_TIME_TEXT } from '@constants/tooltips';
import { PREV_MONTH } from '@constants';

function PortsTable({ ports, loading }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const columns = useMemo(() => {
    return [
      {
        name: 'portName',
        label: 'Port Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              style: {
                width: '30vw',
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'port'} name={dataIndex} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'id',
        options: { display: false, viewColumns: false, filter: false, download: false }
      },
      {
        name: 'country.isoAlpha3',
        options: { display: false, viewColumns: false, filter: false }
      },
      {
        name: 'country.countryName',
        label: 'Country',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <Grid container spacing={1}>
                <Grid item sx={{ placeSelf: 'flex-end' }}>
                  <CountryFlag size={20} countryCode={rowData[2]} />
                </Grid>
                <Grid item>
                  <Typography variant={'caption'}>{dataIndex}</Typography>
                </Grid>
              </Grid>
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'totalPortCallsLastMonth',
        label: 'Port Calls',
        options: {
          hint: PORT_CALLS_TEXT + PREV_MONTH,
          sort: true,
          sortThirdClickReset: true
        }
      },
      {
        name: 'avgPortTimeLastMonth',
        label: 'Avg Port Time (days)',
        options: {
          hint: PORT_TIME_TEXT + PREV_MONTH,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getCovertTimeToDays(value);
          }
        }
      },
      {
        name: 'avgPortWaitingTimeLastMonth',
        label: 'Avg Port Waiting time (days)',
        options: {
          hint: PORT_WAITING_TIME_TEXT + PREV_MONTH,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return getCovertTimeToDays(value);
          }
        }
      }
    ];
  }, []);

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'ports-overview.csv'
    },
    setTableProps: () => {
      return {
        id: 'ports-table'
      };
    },
    enableNestedDataAccess: '.',
    print: false,
    viewColumns: false,
    pagination: true,
    tableBodyMaxHeight: '80vh',
    toolbar: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    sortOrder: {
      name: 'totalPortCallsLastMonth',
      direction: 'desc'
    }
  };
  return (
    <Paper id={'table-body'}>
      <Table
        skeletonRows={6}
        loading={loading}
        mode={theme.mode}
        title={
          <Typography id={'table-header'} variant={'h6'}>
            Ports
          </Typography>
        }
        data={
          ports?.map((portCall) => {
            return {
              ...portCall,
              portName: `${portCall.portName} (${portCall.unlocode})`
            };
          }) ?? []
        }
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

PortsTable.propTypes = {
  ports: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

PortsTable.defaultProps = {
  ports: [],
  loading: false
};

export default PortsTable;
