import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getSegment } from '@store/features';
import { getOperatorProfile } from '@api';

export const useOperatorData = (operatorId) => {
  const segment = useSelector(getSegment);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  return useQuery({
    queryKey: ['operatorData', operatorId, segment.id, commercialCategoryType],
    queryFn: ({ signal }) =>
      getOperatorProfile(
        {
          OperatorId: operatorId,
          SegmentTypeId: segment.id,
          commercialCategoryId: commercialCategoryType
        },
        signal
      )
  });
};
