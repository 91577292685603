import { useQuery } from '@tanstack/react-query';
import { getPortStats } from '@api';
import { useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import { useSegment } from '@hooks';

export const usePortStats = (portId) => {
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { segment } = useSegment();

  return useQuery({
    queryKey: ['portStats', portId, operatorType, segment.id, commercialCategoryType],
    queryFn: ({ signal }) =>
      getPortStats(
        {
          PortId: portId,
          OperatorTypeId: operatorType,
          SegmentTypeId: segment.id,
          commercialCategoryId: commercialCategoryType
        },
        signal
      ),
    enabled: Boolean(portId)
  });
};
