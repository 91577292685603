import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InfoBox,
  ShipsIcon,
  StorageIcon,
  TrendingUpIcon,
  Paper,
  Stack,
  Box,
  DateRangeMobi,
  Select,
  MenuItem,
  Divider,
  NotFound,
  Tabs,
  Tab
} from '@esgian/esgianui';
import { OperatorsTable } from '@components/Tables';
import { useDynamicLookupQueries, useSegment } from '@hooks';
import moment from 'moment';
import {
  OperatorCEUChart,
  OperatorFleetAgeChart
} from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections/OperatorOverviewSection';
import { fetchVesselStats } from '@api';
import { DATE_TIME_FORMAT } from '@constants';
import { TextWithTooltipIcon } from '@components';
import { OperatorMultiSelect } from '@components/Inputs';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';

const ageInfoDefault = {
  averageAge: 0,
  vesselsAgeRange0To5: 0,
  vesselsAgeRange6To10: 0,
  vesselsAgeRange11To15: 0,
  vesselsAgeRange16To20: 0,
  vesselsAgeRange21To25: 0,
  vesselsAgeRangeGreaterThan25: 0
};
const ceuCapacityDefault = {
  averageCEU: 0,
  totalCEU: 0,
  averageDeadweightTonnage: 0,
  totalDeadweightTonnage: 0
};

function aggregateOperatorData(response, numMonths) {
  let numOperators = response.slice(1).length;
  const operatorTotalVessels = response[0].vesselTimeSeries; //First index has the total values irrespective of operators
  const aggregatedData = {
    totalVessels: operatorTotalVessels.map((item) => item.numVessels || {}),
    operatorAgeInformation: Array.from({ length: numMonths }, () => ({ ...ageInfoDefault })),
    ceuCapacity: response[0].vesselTimeSeries.map(({ capacity }) => ({
      averageCEU: capacity.averageCEU,
      totalCEU: capacity.totalCEU,
      averageDeadweightTonnage: capacity.averageDeadweightTonnage,
      totalDeadweightTonnage: capacity.totalDeadweightTonnage
    })),
    numVesselsArray: Array(numMonths).fill([])
  };

  response.slice(1).forEach((operator) => {
    const operatorFleetData = operator.vesselTimeSeries || [];

    operatorFleetData.forEach((item, index) => {
      aggregatedData.numVesselsArray[index].push(item.numVessels || 0);

      // Aggregate operatorAgeInformation
      const ageInfo = item.ageInformation || {};
      Object.keys(ageInfo).forEach((key) => {
        aggregatedData.operatorAgeInformation[index][key] += ageInfo[key] ?? 0;
      });
    });
  });

  aggregatedData.operatorAgeInformation = aggregatedData.operatorAgeInformation.map((item) => {
    let avg = item.averageAge / numOperators;
    return { ...item, averageAge: avg };
  });
  return aggregatedData;
}
function OperatorsOverviewSection({ operatorDetails, operatorsKpi }) {
  const [init, setInit] = useState(false);
  const [section, setSection] = useState(1);
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('ageProfile');
  const [fleetDates, setFleetDates] = useState({
    start: moment().subtract(12, 'months').startOf('month'),
    end: moment()
  });

  const { isRoRo, segment } = useSegment();
  const { lookupOperatorsQuery } = useDynamicLookupQueries();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { operators, operatorShortName } = operatorDetails || {};
  const { fleetSize, averageAge, averageCapacity, totalCapacity } = operatorsKpi || {};
  const filterOptions = [
    { value: 'ceuCapacity', label: isRoRo ? 'CEU Capacity' : 'Total Deadweight' },
    { value: 'ageProfile', label: 'Age Profile' }
  ];
  useEffect(() => {
    if (lookupOperatorsQuery.data.length > 0) {
      setSelectedOperators(lookupOperatorsQuery.data);
      setInit(true);
    }
  }, [lookupOperatorsQuery.data, init]);

  const handleFilterChange = useCallback(({ selectedOperators }) => {
    setSelectedOperators(selectedOperators);
  }, []);

  const operatorsOverviewQuery = useQuery({
    queryKey: [
      'vesselStats',
      {
        fleetDates: fleetDates,
        selectedOperators: selectedOperators
      }
    ],
    enabled: !!fleetDates && !!selectedOperators.length,
    placeholderData: null,
    queryFn: ({ signal }) => {
      const operatorIds = selectedOperators.map((operator) => operator.id);
      let start = moment(fleetDates.start);
      let end = moment(fleetDates.end);
      return fetchVesselStats(
        signal,
        [segment.id],
        [],
        operatorIds,
        isRoRo ? [operatorType] : [],
        isRoRo ? [] : [commercialCategoryType],
        [],
        start.clone().format(DATE_TIME_FORMAT),
        end.clone().format(DATE_TIME_FORMAT)
      )
        .then((response) => {
          if (!response.length) return null;
          const numMonths = end.diff(start, 'months') + 1;
          const aggregatedData = aggregateOperatorData(response, numMonths);
          const categories = response[0].vesselTimeSeries.map((item) => {
            const date = new Date(item.date);
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
          });
          return {
            aggregatedData: aggregatedData,
            categories: categories
          };
        })
        .catch(() => {
          return null;
        });
    }
  });

  const { aggregatedData = {}, categories } = operatorsOverviewQuery.data || {};
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} id={'operator-overview-fleet-size'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={fleetSize ? fleetSize : ''}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          headerId={'operator-overview-fleet-size-label'}
          mainValueId={'operator-overview-fleet-size-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-age'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={averageAge ? averageAge.toFixed(1) : ''}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          headerId={'operator-overview-avg-age-label'}
          mainValueId={'operator-overview-avg-age-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-capacity'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          headerId={'operator-overview-avg-capacity-label'}
          mainValueId={'operator-overview-avg-capacity-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-total-capacity'}>
        <InfoBox
          loading={operatorsOverviewQuery.isFetching}
          mainValue={totalCapacity ? totalCapacity : ''}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
          headerId={'operator-overview-total-capacity-label'}
          mainValueId={'operator-overview-total-capacity-value'}
        />
      </Grid>
      {
        <Grid item xs={12} id={'operators-overview'}>
          <Paper>
            <Tabs
              variant="scrollable"
              onChange={(_, val) => {
                setSection(val);
              }}
              value={section}>
              <Tab value={1} label="Operators Overview" />
              <Tab value={2} label="Fleet Development" />
            </Tabs>
          </Paper>
        </Grid>
      }
      <Grid item xs={12}>
        {section === 1 && (
          <OperatorsTable
            operators={operators}
            fileName={'operators-overview'}
            loading={operatorsOverviewQuery.isFetching}
          />
        )}
        {section === 2 && (
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Stack>
                <TextWithTooltipIcon
                  labelVariant={'h6'}
                  label={'Fleet Development'}
                  tooltipText={
                    'Please note the fleet development only counts vessels and operators covered by Esgian shipping'
                  }
                />
              </Stack>
              <Stack direction="row" spacing={4} justifyContent={'space-between'}>
                <Box sx={{ width: '15em' }}>
                  <DateRangeMobi
                    sx={{
                      inputProps: {
                        InputProps: {
                          sx: { fontSize: '0.875rem' }
                        },
                        defaultValue: undefined,
                        value:
                          fleetDates.start || fleetDates.end
                            ? `${
                                fleetDates.start ? moment(fleetDates.start).format('MMM YYYY') : ''
                              } - ${
                                fleetDates.end ? moment(fleetDates.end).format('MMM YYYY') : ''
                              }`
                            : null
                      }
                    }}
                    onClose={({ value }) => {
                      if (value[0].isValid()) {
                        setFleetDates((prevState) => ({
                          ...prevState,
                          start: value[0].startOf('month')
                        }));
                      }
                      if (value[1].isValid()) {
                        setFleetDates((prevState) => ({
                          ...prevState,
                          end: value[1].isSameOrAfter(moment(), 'month')
                            ? moment()
                            : value[1].endOf('month')
                        }));
                      }
                    }}
                    dateFormat={'MMM YYYY'}
                    minStartDate={
                      isRoRo ? moment('07-2022', 'MM-YYYY') : moment('07-2023', 'MM-YYYY')
                    }
                    disableFuture
                    startDate={fleetDates.start}
                    endDate={fleetDates.end}
                    placeholder={'Period'}
                    dateWheels={'MMM YYYY'}
                  />
                </Box>
                <Grid item xs={12} className={'section-filter'}>
                  <Box sx={{ width: '300px' }}>
                    <OperatorMultiSelect
                      helperText={init && !selectedOperators.length ? 'No operators selected' : ''}
                      error={init && !selectedOperators.length}
                      placeholder={'Select Operators...'}
                      selectedOperators={selectedOperators}
                      operatorsList={lookupOperatorsQuery.data || []}
                      sx={{ width: '100%' }}
                      handleChange={(values) => {
                        handleFilterChange({ selectedOperators: values });
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ width: '10em' }}>
                    <Select
                      value={selectedFilter}
                      onChange={(e) => setSelectedFilter(e.target.value)}
                      displayEmpty
                      variant={'standard'}
                      labelId="operator-overview-filter"
                      inputProps={{ 'aria-label': 'Filter' }}
                      MenuProps={{ PaperProps: { style: { width: '10em' } } }}
                      sx={{ width: '10em' }}>
                      {filterOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
              </Stack>
              <Divider />
              {!operatorsOverviewQuery.data && !operatorsOverviewQuery.isFetching ? (
                <NotFound
                  show={true}
                  header={'No Matching Result'}
                  text={'Please change your search parameters'}
                />
              ) : (
                <>
                  {selectedFilter === 'ageProfile' && (
                    <OperatorFleetAgeChart
                      operatorName={operatorShortName}
                      categories={categories}
                      loading={operatorsOverviewQuery.isFetching}
                      fleetAgeCeuData={aggregatedData.operatorAgeInformation}
                      totalCountVessel={aggregatedData.totalVessels}
                      fileName={`${operatorShortName}-fleet-age`}
                    />
                  )}
                  {selectedFilter === 'ceuCapacity' && (
                    <OperatorCEUChart
                      loading={operatorsOverviewQuery.isFetching}
                      CEUCapacityData={aggregatedData.ceuCapacity}
                      fileName={`${operatorShortName}-CEU-capacity`}
                      categories={categories}
                      vesselsAdded={aggregatedData.vesselsAdded}
                      vesselsRemoved={aggregatedData.vesselsRemoved}
                    />
                  )}
                </>
              )}
            </Stack>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}

OperatorsOverviewSection.propTypes = {
  loading: PropTypes.bool,
  operatorDetails: PropTypes.object
};

OperatorsOverviewSection.defaultProps = {};

export default OperatorsOverviewSection;
