import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Table, Typography } from '@esgian/esgianui';
import { getMinMaxFilterListOptions, getMinMaxFilterOptions } from '@components/Tables/helpers';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, formatNumberReadable, tableTitlePaddingLeft } from '@helpers';
import { useSegment, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType, getUser } from '@store/features';
import TextWithTooltipIcon from '../../Display/TextWithTooltipIcon/TextWithTooltipIcon';
import { IN_ASSOCIATION_WITH_HESNES_SHIPPING_AS_TOOLTIP } from '@constants/tooltips';

function OperatorsTable({ operators, loading, title, height, fileName }) {
  const { isRoRo } = useSegment();
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const columns = [
    {
      name: 'operatorLongName',
      label: 'Operator Name',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        setCellProps: () => {
          return {
            style: {
              boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
            }
          };
        },
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'operator'} id={rowData[1]} name={dataIndex} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'operatorShortName',
      label: 'Short Name',
      options: {
        filterType: 'multiselect',
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'fleetSize',
      label: 'Fleet Size',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Size'),
        filterOptions: getMinMaxFilterOptions('Fleet Size')
      }
    },
    {
      name: 'avgAge',
      label: 'Avg Age',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          if (dataIndex?.toFixed(2)) {
            return parseFloat(dataIndex?.toFixed(2));
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Age'),
        filterOptions: getMinMaxFilterOptions('Avg Age')
      }
    },
    {
      name: 'avgCapacity',
      label: `Avg Capacity (${isRoRo ? 'CEU' : 'DWT'})`,
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          if (dataIndex?.toFixed(2)) {
            const formattedValue = parseFloat(dataIndex?.toFixed(2));
            return formatNumberReadable(formattedValue.toString());
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getMinMaxFilterListOptions('Avg Capacity'),
        filterOptions: getMinMaxFilterOptions('Avg Capacity')
      }
    },
    {
      name: 'totalCapacity',
      label: `Total Capacity (${isRoRo ? 'CEU' : 'DWT'})`,
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'custom',
        customBodyRender: (dataIndex) => dataIndex,
        customFilterListOptions: getMinMaxFilterListOptions('Capacity'),
        filterOptions: getMinMaxFilterOptions('Total Capacity')
      }
    }
  ];
  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    tableBodyMaxHeight: height,
    downloadOptions: {
      filename: fileName
    },
    setTableProps: () => {
      return {
        id: 'operators-table'
      };
    },
    print: false,
    elevation: 0,
    customStyle: {
      MuiTableBody: {
        styleOverrides: {
          root: {
            'tr:nth-of-type(even) td': {
              backgroundColor: '#212f41'
            },
            'tr:nth-of-type(odd) td': {
              backgroundColor: '#fff'
            }
          }
        }
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            margin: '16px'
          }
        }
      }
    },
    viewColumns: false,
    pagination: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'fleetSize',
      direction: 'desc'
    }
  };
  return (
    <Paper>
      <Table
        customStyle={tableTitlePaddingLeft}
        skeletonRows={6}
        loading={loading}
        mode={theme.mode}
        title={
          <Stack sx={{ pt: 2 }}>
            <Typography id={'operators-title'} variant={'h6'}>
              {title}
            </Typography>
            {isRoRo ? (
              <TextWithTooltipIcon
                label={
                  `In association with Hesnes Shipping AS` +
                  (operatorType !== 0 ? `, last updated December, 2024` : ``)
                }
                tooltipText={
                  <Typography>{IN_ASSOCIATION_WITH_HESNES_SHIPPING_AS_TOOLTIP}</Typography>
                }
                color={'inherit'}
                labelVariant={'body2'}
                labelBold={false}
                tooltipMaxWidth="600px"
              />
            ) : (
              <Typography variant={'body2'}>
                {`In association with Toepfer Transport GmbH` +
                  ([1, 2, 4].includes(commercialCategoryType)
                    ? `, last updated December, 2024`
                    : commercialCategoryType === 3
                    ? `, last updated October, 2024`
                    : ``)}
              </Typography>
            )}
          </Stack>
        }
        data={operators}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

OperatorsTable.propTypes = {
  operators: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  title: PropTypes.string,
  height: PropTypes.string,
  fileName: PropTypes.string.isRequired
};

OperatorsTable.defaultProps = {
  operators: [],
  loading: false,
  title: 'Active Operators',
  height: '80vh'
};

export default OperatorsTable;
