import { useQuery } from '@tanstack/react-query';
import { getLatestPortCalls } from '@api';
import { GEOGRAPHICAL_TYPES, DEFAULT_NUM_PORT_CALLS } from '@constants';
import { useSegment } from '@hooks';

export const useLatestPortCalls = (props) => {
  const { segment } = useSegment();
  return useQuery({
    queryKey: ['vesselsLatestPortCalls', props, segment.id],
    queryFn: ({ signal }) =>
      getLatestPortCalls(
        {
          geographicalType: GEOGRAPHICAL_TYPES.ALL,
          segmentTypeId: segment.id,
          numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
          ...props
        },
        signal
      ),
    enabled: Object.keys(props).length > 0
  });
};
