import { BaseApiRequest } from '@api/BaseApi';
import { objectToQuery } from '@api/helpers';

export const getCountries = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Country/Active?${query}`, 'ships');
};
export const getCountryDetail = (signal, countryId) => {
  return BaseApiRequest(signal, `/Country/CountryDetails/${countryId}`, 'geo');
};

export const getActivePorts = (data, signal) => {
  return BaseApiRequest(signal, `/Location/ActivePorts`, 'ships', 'Post', data);
};
export const getCountryPortStats = (data, signal, version = '1.0') => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Country/PortStats?${query}&api-version=${version}`);
};

export const getCountryTopPorts = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Country/GetTopCountryPorts?${query}`);
};
