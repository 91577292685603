import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Modal, Grid, Paper } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, sanitizeFileName } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { useTimezone } from '@hooks/useTimezone';

function UnderlyingCanalStatsModal({ modalData, modalOpen, setModalOpen, setModalData, isWT }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const modalDataArray = [];
  const { getTimeZoneDisplay } = useTimezone();
  const selectedCell = Object.values(modalData)[0].selectedCell;

  Object.keys(modalData).forEach((imo) => {
    modalDataArray.push(modalData[imo]);
  });
  let modalTitle = '';
  if (!isNaN(modalDataArray[0].period)) {
    modalTitle = 'Week ';
  }
  modalTitle +=
    selectedCell === 14
      ? `${modalDataArray[0].operatorLongName}`
      : `${modalDataArray[0].period} ${modalDataArray[0].year}`;

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      setTableProps: () => {
        return {
          id: 'underlying-canal-stats-table'
        };
      },
      downloadOptions: {
        filename: sanitizeFileName(modalTitle.replaceAll(' ', '-'))
      },
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '25vh',
      tableBodyHeight: '25vh',
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'startDate',
        direction: 'asc'
      }
    };
  }, [modalDataArray]);

  const tableColumns = [
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'operatorLongName',
      label: 'Operator',
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { tableData } = rowIndex;
          const opID = tableData.find(
            ({ operatorLongName }) => operatorLongName === dataIndex
          ).operatorId;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'operator'} id={opID} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'canalArrival',
      label: 'Arrival',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'canalDeparture',
      label: 'Departure',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'transit',
      label: 'Time in transit (hours)',
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'headingDirection',
      label: 'Direction',
      options: {
        filter: false,
        sort: true
      }
    }
  ];

  const tableColumnsWT = [
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'operatorLongName',
      label: 'Operator',
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { tableData } = rowIndex;
          const opID = tableData.find(
            ({ operatorLongName }) => operatorLongName === dataIndex
          ).operatorId;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'operator'} id={opID} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'startDate',
      label: 'Arrival',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'endDate',
      label: 'Departure',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return getTimeZoneDisplay(value);
        }
      }
    },
    {
      name: 'waitTime',
      label: 'Time at anchor (hours)',
      options: {
        sort: true,
        sortThirdClickReset: true
      }
    }
  ];
  return (
    <Modal
      handleClose={() => {
        setModalOpen(false);
        setModalData(null);
      }}
      title={modalTitle}
      open={modalOpen}
      size={'lg'}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2" sx={{ p: 1 }}>
            {!isWT ? <b>Canal Transits</b> : <b>Anchor events</b>}: {Object.keys(modalData)?.length}
          </Typography>
        </Grid>
      </Grid>

      {modalDataArray && (
        <Paper variant={'outlined'}>
          <Table
            customStyle={{
              MUIDataTableFilterList: { display: 'none' },
              MUIDataTableToolbar: {
                styleOverrides: {
                  root: {
                    minHeight: 0
                  }
                }
              }
            }}
            mode={theme.mode}
            title={''}
            data={modalDataArray}
            columns={!isWT ? tableColumns : tableColumnsWT}
            options={options}
          />
        </Paper>
      )}
    </Modal>
  );
}

UnderlyingCanalStatsModal.propTypes = {
  modalData: PropTypes.object,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  setModalData: PropTypes.func.isRequired,
  isWT: PropTypes.bool
};

UnderlyingCanalStatsModal.defaultProps = {
  modalData: null,
  selectedLocation: null,
  isWT: false
};

export default UnderlyingCanalStatsModal;
