import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { Alert, CircularProgress, Grid, Paper, Stack } from '@esgian/esgianui';
import mapboxgl, { Map } from 'mapbox-gl';
import { useNavigate } from 'react-router-dom';
import CountryListDisplay from '@components/Maps/CountryOverviewMap/CountryListDisplay';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { useSegment } from '@hooks';

const createCountryLayer = (map, handleCountryClick, countriesData) => {
  if (!map.current.getSource('cbs')) {
    map.current.addSource('cbs', {
      type: 'geojson',
      data: 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries.geojson'
    });
  }
  if (!map.current.getLayer('cf')) {
    map.current.addLayer({
      id: 'cf', // country-fills
      type: 'fill',
      source: 'cbs',
      layout: {},
      paint: {
        'fill-color': '#fff',
        'fill-opacity': 0
      }
    });
  }
  if (!map.current.getLayer('cb')) {
    map.current.addLayer({
      id: 'cb', // country borders
      type: 'line',
      source: 'cbs',
      layout: {},
      paint: {
        'line-color': '#3fbad4',
        'line-width': 0
      }
    });
  }
  if (!map.current.getLayer('cfh')) {
    map.current.addLayer({
      id: 'cfh', // country-fills-hover",
      type: 'fill',
      source: 'cbs',
      layout: {},
      paint: {
        'fill-color': '#3fbad4',
        'fill-opacity': 0.5
      },
      filter: ['==', 'name', '']
    });
  }

  map.current.on('mousemove', function (e) {
    const features = map.current.queryRenderedFeatures(e.point, { layers: ['cf'] });

    if (features.length) {
      if (countriesData?.find(({ isoAlpha3 }) => isoAlpha3 === features[0].properties.iso_a3)) {
        map.current.getCanvas().style.cursor = 'pointer';
        map.current.setFilter('cfh', ['==', 'name', features[0].properties.name]);
      } else {
        map.current.setFilter('cfh', ['==', 'name', '']);
        map.current.getCanvas().style.cursor = '';
      }
    } else {
      map.current.setFilter('cfh', ['==', 'name', '']);
      map.current.getCanvas().style.cursor = '';
    }
  });

  // Reset the state-fills-hover layer's filter when the mouse leaves the map
  map.current.on('mouseout', function () {
    map.current.getCanvas().style.cursor = 'auto';
    map.current.setFilter('cfh', ['==', 'name', '']);
  });

  map.current.on('click', function (e) {
    const features = map.current.queryRenderedFeatures(e.point, { layers: ['cf'] });
    if (features.length) {
      handleCountryClick(features[0].properties.iso_a3);
    }
  });
};

function CountryOverviewMap({ countriesData, loading }) {
  const mapContainer = useRef(null);
  const [styleReady, setStyleReady] = useState(false);
  const [loadReady, setLoadReady] = useState(false);
  const [mapNotSupported, setMapNotSupported] = useState(false);
  const [layersAdded, setLayersAdded] = useState(false);
  const navigate = useNavigate();
  const map = useRef(null);
  const themeMode = useSelector(getThemeMode);
  const { uriExt } = useSegment();

  const handleCountryClick = (isoA3) => {
    let country = countriesData?.find(({ isoAlpha3 }) => isoAlpha3 === isoA3);
    if (!country) return;
    navigate(`${uriExt}/commercial-analytics/country/${country.id}`);
  };

  useEffect(() => {
    setStyleReady(false);
    setLoadReady(false);

    if (!map.current) {
      if (mapboxgl.supported() === false) {
        setMapNotSupported(true);
        return;
      }

      map.current = new Map({
        renderWorldCopies: false,
        container: mapContainer.current,
        style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
        projection: { name: 'mercator' },
        center: [50, 50],
        zoom: 0
      });
    }
    map.current.flyTo({ center: [0, 20] });
    map.current?.on('style.load', () => {
      setStyleReady(true);
    });

    map.current?.on('load', () => {
      setLoadReady(true);
    });
    return () => {
      map.current = null;
    };
  }, [themeMode]);

  useEffect(() => {
    if (!countriesData?.length || !styleReady || !loadReady) return;
    createCountryLayer(map, handleCountryClick, countriesData);
    setLayersAdded(true);
  }, [styleReady, loadReady, countriesData]);

  if (mapNotSupported) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Alert severity={'info'} variant={'contained'}>
            Unable to load map with current browser. Please check that WEB GL is enabled, or contact
            the Esgian shipping team
          </Alert>{' '}
        </Grid>
      </Grid>
    );
  }
  return (
    <Paper>
      <Stack direction={'row'}>
        <div
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.25) 2px 4px 4px 0px',
            clipPath: 'inset(0px -15px 0px 0px)',
            width: '15vw',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            height: `80vh`,
            display: 'flex',
            maxHeight: `80vh`
          }}>
          <CountryListDisplay loading={loading} map={map} lookupCountries={countriesData} />
        </div>

        <div
          ref={mapContainer}
          style={{
            height: '80vh',
            width: '82%'
          }}
          className="dashboard-map">
          {(!styleReady || !loadReady || !layersAdded) && (
            <div
              style={{
                height: '100%',
                width: '100%',
                background: 'rgb(255 255 255 / 50%)',
                zIndex: 2,
                position: 'absolute'
              }}>
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 50px)',
                  left: 'calc(50% - 50px)'
                }}
                size={100}
              />
            </div>
          )}
        </div>
      </Stack>
    </Paper>
  );
}

CountryOverviewMap.propTypes = {
  countriesData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

CountryOverviewMap.defaultProps = {
  countriesData: [],
  loading: false
};

export default CountryOverviewMap;
