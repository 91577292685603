import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InfoBox, ShipsIcon, StorageIcon, TrendingUpIcon } from '@esgian/esgianui';
import { VesselsTable } from '@components/Tables';
import { useSegment } from '@hooks';

function VesselsOverviewSection({ loading, vesselsData }) {
  const { isRoRo } = useSegment();
  const { ageStats, capacityStats, vessels } = vesselsData || {};
  const { averageCEU, averageDeadweightTonnage, totalCEU, totalDeadweightTonnage } =
    capacityStats || {};
  const { averageAge } = ageStats || {};
  let hideColumns;
  if (!isRoRo) {
    hideColumns = ['callSign', 'ownerLongName', 'loadCapacity', 'grossTonnage', 'class'];
  } else {
    hideColumns = ['numCranes', 'maxLiftingCapacity', 'grossTonnage', 'deadweight'];
  }

  function transformData(vessels) {
    if (!Array.isArray(vessels)) {
      return [];
    }
    return vessels.map((vessel) => {
      const { capacity, vesselNameHistory, ...rest } = vessel;
      return {
        ...rest,
        loadCapacity: capacity,
        formerVesselName: vesselNameHistory?.map((nameObj) => nameObj.name).join(', ') || null
      };
    });
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} id={'vessel-overview-fleet-size'}>
        <InfoBox
          loading={loading}
          mainValue={vessels?.length ? vessels?.length : ''}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          headerId={'vessel-overview-fleet-size-label'}
          mainValueId={'vessel-overview-fleet-size-value'}
        />
      </Grid>
      <Grid item xs={3} id={'vessel-overview-avg-age'}>
        <InfoBox
          loading={loading}
          mainValue={ageStats ? averageAge.toFixed(1) : ''}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          headerId={'vessel-overview-avg-age-label'}
          mainValueId={'vessel-overview-avg-age-value'}
        />
      </Grid>
      <Grid item xs={3} id={'vessel-overview-avg-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={
            isRoRo
              ? averageCEU
                ? parseFloat(averageCEU.toFixed(0))
                : '-'
              : averageDeadweightTonnage
              ? parseFloat(averageDeadweightTonnage.toFixed(0))
              : '-'
          }
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          headerId={'vessel-overview-avg-capacity-label'}
          mainValueId={'vessel-overview-avg-capacity-value'}
        />
      </Grid>
      <Grid item xs={3} id={'vessel-overview-total-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={
            isRoRo
              ? totalCEU
                ? parseFloat(totalCEU.toFixed(0))
                : '-'
              : totalDeadweightTonnage
              ? parseFloat(totalDeadweightTonnage.toFixed(0))
              : '-'
          }
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
          headerId={'vessel-overview-total-capacity-label'}
          mainValueId={'vessel-overview-total-capacity-value'}
        />
      </Grid>
      <Grid item xs={12}>
        <VesselsTable
          title={'Vessels'}
          hideColumns={hideColumns}
          vessels={transformData(vessels)}
          fileName={'vessels-overview'}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

VesselsOverviewSection.propTypes = {
  loading: PropTypes.bool,
  vesselsData: PropTypes.object
};

VesselsOverviewSection.defaultProps = {
  loading: false,
  vesselsData: null
};

export default VesselsOverviewSection;
