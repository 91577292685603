import { useQuery } from '@tanstack/react-query';
import { getCountryTopPorts } from '@api/Countries';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks';
import moment from 'moment';

export const useCountryTopPorts = ({ countryId, selectedYear = moment().year() }) => {
  const operatorType = useSelector(getOperatorType);
  const { segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  return useQuery({
    queryKey: [
      'countryTopPorts',
      countryId,
      selectedYear,
      operatorType,
      segment.id,
      commercialCategoryType
    ],
    enabled: !!countryId && !!selectedYear,
    queryFn: ({ signal }) =>
      getCountryTopPorts(
        {
          CountryId: countryId,
          NumberOfPorts: 20,
          NumberOfOperators: 20,
          Year: selectedYear,
          OperatorTypeId: operatorType,
          SegmentTypeId: segment.id,
          commercialCategoryId: commercialCategoryType
        },
        signal
      )
  });
};
