import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Paper } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { useSearchParams } from 'react-router-dom';
import { createVesselMarkers, createPortMarkers } from '@helpers';
import {
  ACTIVITY_MAP_ACTIONS,
  DashboardActivityMap,
  DashboardActivityMapInfoWindow
} from '../../../Maps';
import {
  useDynamicLookupQueries,
  useVesselsLatestPositionByTypes,
  useTopPorts,
  useGlobalPortStats,
  useGlobalMonthlyPercentActivityStatus,
  useGlobalEmissionIntensity,
  useGlobalPortCongestion,
  useSegment,
  useStaticLookupQueries
} from '@hooks';
import { useTimezone } from '@hooks/useTimezone';
import DashboardActivityMapVesselSearchBox from './DashboardActivityMapVesselComponents/DashboardActivityMapVesselSearchBox';
import DashboardActivityMapVesselDetailsWindow from './DashboardActivityMapVesselComponents/DashboardActivityMapVesselDetailsWindow';
import DashboardActivityMapGlobalInsights from './DashboardActivityMapGlobalComponents/DashboardActivityMapGlobalInsights';
import { checkDashboardActivityMapFilterApplied } from '../../../Maps/DashboardActivityMap/DashboardActivityMapActionWindow/DashboardActivityMapFilter';
import DashboardActivityMapInsight from '../../../Maps/DashboardActivityMap/DashboardActivityMapActionWindow/DashboardActivityMapInsight';
import { DashboardActivityMapActionWindow } from '@components/Maps/DashboardActivityMap';
import {
  applyAdvancedFilter,
  applyQuickSearchFilter,
  searchItemTypeToSidePanelType
} from './DashboardActivityMapUtils';
import { flyToTheLocation, getVesselInfoIcons, getInboundVesselsInfoIcons } from '@helpers';
import DashboardActivityMapInboundVesselInsight from './DashboardActivityMapInboundVesselComponents/DashboardActivityMapInboundVesselInsight';
import { MAX_VESSELS_TO_SHOW_NAME } from '../../../Maps/DashboardActivityMap/DashboardActivityMapActionWindow/DashboardActivityMapSettings';
import OperatorDetailsPanel from './DashboardActivityMapOperatorComponents/OperatorDetailsPanel';
import OwnerDetailsPanel from './DashboardActivityMapOwnerComponents/OwnerDetailsPanel';
import PortDetailsPanel from './DashboardActivityMapPortComponents/PortDetailsPanel';
import CountryDetailsPanel from './DashboardActivityMapCountryComponents/CountryDetailsPanel';

export const SIDE_PANEL_STATES = {
  OPEN: 'open',
  CLOSED: 'closed'
};

export const SIDE_PANEL_TYPES = {
  VESSEL_DETAILS: 'vesselDetails',
  INSIGHT: 'insight',
  OPERATOR_DETAILS: 'operatorDetails',
  OWNER_DETAILS: 'ownerDetails',
  PORT_DETAILS: 'portDetails',
  COUNTRY_DETAILS: 'countryDetails'
};

export const ACTIVITY_MAP_SEARCH_PARAM_KEYS = {
  SIDE_PANEL_STATE: 'sidePanelState',
  SIDE_PANEL_TYPE: 'sidePanelType',
  SIDE_PANEL_ITEM_ID: 'sidePanelItemId',
  MAP_SETTINGS: {
    SHOW_PORTS: 'showPorts',
    SHOW_VESSELS_NAMES: 'showVesselsNames',
    SHOW_VESSELS_STATUS: 'showVesselsStatus',
    SHOW_VESSELS: 'showVessels',
    SHOW_PORT_NAMES: 'showPortNames',
    SHOW_INBOUND_VESSELS_COLORED_PORTS: 'showInboundVesselsColoredPorts',
    SHOW_COLORED_INBOUND_VESSELS: 'showColoredInboundVessels'
  },
  SELECTED_MAP_ACTION: 'selectedMapAction'
};

function DashboardActivityMapSection({ isCompactView, onClickFullscreen }) {
  const themeMode = useSelector(getThemeMode);
  const [, setIsMapLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { getTimeZoneDisplay, timeZone } = useTimezone();
  const mapRef = useRef(null);
  const [activeAdvancedFilters, setActiveAdvancedFilters] = useState(null);
  const [activeInboundVesselsFilters, setActiveInboundVesselsFilters] = useState(null);
  const [quickSearchItemId, setQuickSearchItemId] = useState(null);
  const [quickSearchItemType, setQuickSearchItemType] = useState(null);

  const [filteredVessels, setFilteredVessels] = useState([]);
  const [filteredPorts, setFilteredPorts] = useState([]);

  const { segment, isRoRo } = useSegment();

  const { sidePanelState, sidePanelType, sidePanelItemId, mapSettings, selectedMapAction } =
    useMemo(
      () => ({
        sidePanelState: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE),
        sidePanelType: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE),
        sidePanelItemId: +searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID),

        mapSettings: {
          showPorts:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS) === 'true',
          showVesselsNames:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_NAMES) ===
              'true' && filteredVessels?.length <= MAX_VESSELS_TO_SHOW_NAME,

          showVessels:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS) !== 'false',
          showVesselsStatus:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_STATUS) !==
            'false',
          showPortNames:
            searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORT_NAMES) ===
            'true',
          showInboundVesselsColoredPorts:
            searchParams.get(
              ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_INBOUND_VESSELS_COLORED_PORTS
            ) === 'true',
          showColoredInboundVessels:
            searchParams.get(
              ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_COLORED_INBOUND_VESSELS
            ) === 'true'
        },
        selectedMapAction: searchParams.get(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION)
      }),
      [searchParams]
    );

  const {
    showPorts,
    showVesselsNames,
    showVessels,
    showVesselsStatus,
    showPortNames,
    showInboundVesselsColoredPorts,
    showColoredInboundVessels
  } = mapSettings;

  const showPortsRef = useRef(showPorts);

  const { data: top10Ports = [], isFetching: isLoadingTop10Ports } = useTopPorts();

  const {
    lookupLocationsQuery: { data: lookupLocations, isPending: isLoadingLookupLocations }
  } = useStaticLookupQueries();

  const {
    lookupPortsQuery: { data: lookupPorts, isFetching: isLoadingLookupPorts },
    lookupOperatorVesselHierarchyQuery: {
      data: operatorVesselHierarchy,
      isFetching: isLoadingOperatorVesselHierarchy
    },
    lookupOwnerOperatorVesselHierarchyQuery: {
      data: ownerOperatorVesselHierarchy,
      isFetching: isLoadingOwnerOperatorVesselHierarchy
    }
  } = useDynamicLookupQueries();

  const { data: vesselsLatestPosition, isPending: isLoadingVesselsLatestPosition } =
    useVesselsLatestPositionByTypes();

  const {
    data: globalMonthlyPercentActivityStatus,
    isPending: isLoadingGlobalMonthlyPercentActivityStatus
  } = useGlobalMonthlyPercentActivityStatus();

  const { data: globalPortCongestion, isPending: isLoadingGlobalPortCongestion } =
    useGlobalPortCongestion();

  const { data: globalEmissionIntensity, isPending: isLoadingGlobalEmissionIntensity } =
    useGlobalEmissionIntensity();

  const { data: globalPortStats, isPending: isLoadingGlobalPortStats } = useGlobalPortStats();

  const searchParamsRef = useRef(searchParams);

  searchParamsRef.current = searchParams;

  useEffect(() => {
    setActiveAdvancedFilters(null);
    setQuickSearchItemId(null);
    setQuickSearchItemType(null);
  }, [segment.id]);

  const hasInboundVesselsLocations = activeInboundVesselsFilters?.selectedLocations?.some(Boolean);

  const handleVesselSelect = (vesselImo) => {
    setSearchParams(() => {
      const latestSearchParams = new URLSearchParams(searchParamsRef.current);
      latestSearchParams.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, vesselImo);
      latestSearchParams.set(
        ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE,
        SIDE_PANEL_TYPES.VESSEL_DETAILS
      );
      latestSearchParams.set(
        ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE,
        SIDE_PANEL_STATES.OPEN
      );
      return latestSearchParams;
    });
  };

  const handlePortSelect = (port) => {
    setSearchParams((prev) => {
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, port.id);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE, SIDE_PANEL_TYPES.PORT_DETAILS);
      prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
      return prev;
    });
  };

  const handleMapLoad = (mapInstance) => {
    if (isLoadingVesselsLatestPosition || isLoadingLookupPorts) {
      return;
    }

    let filteredVessels = vesselsLatestPosition;
    let filteredPorts = lookupPorts ?? [];
    let zoomLocationInfo = null;

    if (quickSearchItemId && quickSearchItemType) {
      const { quickSearchFilteredVessels, quickSearchFilteredPorts, zoomLocation } =
        applyQuickSearchFilter(
          vesselsLatestPosition,
          lookupPorts,
          quickSearchItemType,
          quickSearchItemId
        );
      filteredVessels = quickSearchFilteredVessels;
      filteredPorts = quickSearchFilteredPorts;
      zoomLocationInfo = zoomLocation;
    } else if (activeAdvancedFilters || activeInboundVesselsFilters) {
      const { advancedSearchFilteredVessels, advancedSearchFilteredPorts, zoomLocation } =
        applyAdvancedFilter({
          vesselsLatestPosition,
          lookupPorts,
          activeFilters: activeAdvancedFilters || activeInboundVesselsFilters,
          isInboundVesselsFiltering: !!hasInboundVesselsLocations,
          segment
        });
      filteredVessels = advancedSearchFilteredVessels;
      filteredPorts = advancedSearchFilteredPorts;
      zoomLocationInfo = zoomLocation;
    }

    setFilteredVessels(filteredVessels);
    setFilteredPorts(filteredPorts);

    setIsMapLoaded(true);
    mapRef.current = mapInstance;
    showVessels &&
      createVesselMarkers({
        mapInstance,
        vessels: filteredVessels,
        themeMode,
        getTimeZoneDisplay,
        onVesselSelect: ({ imo }) => handleVesselSelect(imo),
        showVesselsNames: showVesselsNames && filteredVessels?.length <= MAX_VESSELS_TO_SHOW_NAME,
        showVesselsStatus,
        inboundVesselsLocations: activeInboundVesselsFilters?.selectedLocations,
        lookupPorts,
        showColoredInboundVessels: true
      });

    if (showPorts) {
      createPortMarkers({
        map: mapInstance,
        ports: filteredPorts,
        showPortNames,
        onPortSelect: handlePortSelect,
        inboundVesselsLocations: activeInboundVesselsFilters?.selectedLocations,
        showInboundVesselsColoredPorts: true
      });
    }

    if (zoomLocationInfo) {
      flyToTheLocation(
        mapInstance,
        zoomLocationInfo.lat,
        zoomLocationInfo.lng,
        zoomLocationInfo.zoom
      );
    }
  };

  const handleSidePanelClose = () => {
    setSearchParams((prev) => {
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID);
      prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
      return prev;
    });
  };

  const renderSidePanelContent = () => {
    if (sidePanelState !== SIDE_PANEL_STATES.OPEN) return null;

    if (sidePanelType === SIDE_PANEL_TYPES.INSIGHT) {
      return (
        <DashboardActivityMapInsight
          renderInsightContent={renderInsightContent}
          isLoading={
            isLoadingTop10Ports ||
            isLoadingGlobalPortStats ||
            isLoadingGlobalPortCongestion ||
            isLoadingGlobalMonthlyPercentActivityStatus ||
            isLoadingGlobalPortCongestion ||
            isLoadingGlobalEmissionIntensity
          }
          onClose={() => {
            setSearchParams((prev) => {
              prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.CLOSED);
              prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
              return prev;
            });
          }}
          title={activeInboundVesselsFilters ? 'Insight' : 'Global Insight'}
        />
      );
    }

    if (sidePanelType === SIDE_PANEL_TYPES.VESSEL_DETAILS && sidePanelItemId) {
      return (
        <DashboardActivityMapVesselDetailsWindow
          vesselImo={sidePanelItemId}
          vesselsLatestPosition={vesselsLatestPosition}
          onClose={handleSidePanelClose}
        />
      );
    }

    if (sidePanelType === SIDE_PANEL_TYPES.OPERATOR_DETAILS && sidePanelItemId) {
      return (
        <OperatorDetailsPanel
          operatorId={Number(sidePanelItemId)}
          operatorVesselHierarchy={operatorVesselHierarchy}
          handleClose={handleSidePanelClose}
        />
      );
    }

    if (sidePanelType === SIDE_PANEL_TYPES.OWNER_DETAILS && sidePanelItemId) {
      return (
        <OwnerDetailsPanel
          ownerId={Number(sidePanelItemId)}
          ownerOperatorVesselHierarchy={ownerOperatorVesselHierarchy}
          handleClose={handleSidePanelClose}
        />
      );
    }

    if (sidePanelType === SIDE_PANEL_TYPES.PORT_DETAILS && sidePanelItemId) {
      return (
        <PortDetailsPanel
          portData={lookupPorts.find((port) => port.id === +sidePanelItemId)}
          onClose={handleSidePanelClose}
        />
      );
    }

    if (sidePanelType === SIDE_PANEL_TYPES.COUNTRY_DETAILS && sidePanelItemId) {
      return (
        <CountryDetailsPanel
          country={lookupLocations.find(
            (location) => location.type === 'Country' && location.id === +sidePanelItemId
          )}
          onClose={handleSidePanelClose}
        />
      );
    }

    return null;
  };

  const renderSearchPanel = () => {
    return (
      <Box width="25vw">
        <DashboardActivityMapVesselSearchBox
          vesselsLatestPosition={vesselsLatestPosition}
          selectedSearchOptionId={quickSearchItemId}
          selectedSearchOptionType={quickSearchItemType}
          onSelectSearchOption={(newSelectedSearchOption) => {
            const { id, type } = newSelectedSearchOption;
            setQuickSearchItemId(id);
            setQuickSearchItemType(type);

            setActiveInboundVesselsFilters(null);
            setActiveAdvancedFilters(null);

            setSearchParams((prev) => {
              prev.set(
                ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE,
                searchItemTypeToSidePanelType(type)
              );
              prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, id);
              prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
              return prev;
            });

            if (
              selectedMapAction === ACTIVITY_MAP_ACTIONS.ADVANCED_FILTER ||
              selectedMapAction === ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER
            ) {
              setSearchParams((prev) => {
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION);
                return prev;
              });
            }
          }}
          onClearSearchValue={() => {
            setQuickSearchItemId(null);
            setQuickSearchItemType(null);
          }}
          operatorVesselHierarchy={operatorVesselHierarchy}
          ownerOperatorVesselHierarchy={ownerOperatorVesselHierarchy}
          lookupLocations={lookupLocations}
          lookupPorts={lookupPorts}
          isLoading={
            isLoadingOperatorVesselHierarchy ||
            isLoadingOwnerOperatorVesselHierarchy ||
            isLoadingVesselsLatestPosition ||
            isLoadingLookupPorts
          }
          disableCountrySearch={false}
          disablePortSearch={false}
        />
      </Box>
    );
  };

  const renderInsightContent = () => {
    if (activeInboundVesselsFilters && hasInboundVesselsLocations) {
      return (
        <DashboardActivityMapInboundVesselInsight
          lookupPorts={filteredPorts}
          locations={activeInboundVesselsFilters.selectedLocations}
          vesselsLatestPosition={filteredVessels}
        />
      );
    }

    return (
      <DashboardActivityMapGlobalInsights
        top10Ports={top10Ports}
        lookupPorts={lookupPorts}
        globalPortStats={globalPortStats}
        globalMonthlyPercentActivityStatus={globalMonthlyPercentActivityStatus}
        globalPortCongestion={globalPortCongestion}
        globalEmissionIntensity={globalEmissionIntensity}
      />
    );
  };

  const isOpenSidePanel = sidePanelState === SIDE_PANEL_STATES.OPEN;

  const isMapDataReady = vesselsLatestPosition?.length && lookupPorts?.length;

  return (
    <Box height="100%" width="100%">
      <Box
        sx={{
          height: '100%',
          width: '100%'
        }}>
        <DashboardActivityMap
          mapRefreshKey={`${themeMode}-${JSON.stringify(activeAdvancedFilters)}-${JSON.stringify(
            mapSettings
          )}-${quickSearchItemId}-${quickSearchItemType}-${isRoRo}-${JSON.stringify(
            activeInboundVesselsFilters
          )}-${timeZone}-${isMapDataReady}`}
          vesselsLatestPosition={vesselsLatestPosition}
          sidePanelContent={renderSidePanelContent()}
          isOpenSidePanel={isOpenSidePanel}
          onSidePanelClose={handleSidePanelClose}
          onMapLoad={handleMapLoad}
          isCompactView={isCompactView}
          onClickFullscreen={onClickFullscreen}
          renderFloatingComponents={(mapContainerZIndex, mapFullscreenButtonZIndex) => {
            return (
              <>
                <Box
                  position="absolute"
                  top="16px"
                  left="16px"
                  zIndex={mapContainerZIndex + 1}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  height="100%"
                  sx={{
                    pointerEvents: 'none',
                    '& > *': {
                      pointerEvents: 'auto'
                    }
                  }}>
                  {renderSearchPanel()}
                  <Box width="max-content">
                    <DashboardActivityMapInfoWindow
                      infoItems={
                        hasInboundVesselsLocations
                          ? getInboundVesselsInfoIcons({
                              selectedLocations: activeInboundVesselsFilters.selectedLocations,
                              showPorts,
                              showVessels,
                              showInboundVesselsColoredPorts: true,
                              showColoredInboundVessels: true
                            })
                          : getVesselInfoIcons({
                              showVesselsStatus,
                              showPorts,
                              showVessels
                            })
                      }
                    />
                  </Box>
                </Box>

                {selectedMapAction && (
                  <Box
                    position="absolute"
                    top="16px"
                    right="72px"
                    zIndex={mapFullscreenButtonZIndex + 1}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    height="100%"
                    sx={{
                      pointerEvents: 'none',
                      '& > *': {
                        pointerEvents: 'auto'
                      }
                    }}>
                    <Paper sx={{ width: '25rem', maxHeight: '98%' }}>
                      <DashboardActivityMapActionWindow
                        selectedAction={selectedMapAction}
                        onClose={() => {
                          setSearchParams((prev) => {
                            prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION);
                            return prev;
                          });
                        }}
                        filterProps={{
                          onApply: (params) => {
                            setActiveInboundVesselsFilters(null);
                            setActiveAdvancedFilters(params);
                            setQuickSearchItemId(null);
                            setQuickSearchItemType(null);
                          },
                          defaultFilters: activeAdvancedFilters,
                          isDisableApply: isLoadingVesselsLatestPosition,
                          isLoading: isLoadingVesselsLatestPosition || isLoadingLookupPorts
                        }}
                        mapSettingsProps={{
                          showPorts,
                          showPortNames,
                          showInboundVesselsColoredPorts,
                          showColoredInboundVessels,
                          disableShowColoredInboundVessels: !hasInboundVesselsLocations,
                          disableShowInboundVesselsColoredPorts: !hasInboundVesselsLocations,
                          disableShowVesselsStatus: hasInboundVesselsLocations,
                          onShowColoredInboundVesselsChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS
                                  .SHOW_COLORED_INBOUND_VESSELS,
                                value
                              );
                              return prev;
                            });
                          },
                          onShowInboundVesselsColoredPortsChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS
                                  .SHOW_INBOUND_VESSELS_COLORED_PORTS,
                                value
                              );
                              return prev;
                            });
                          },
                          onShowPortNamesChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORT_NAMES,
                                value
                              );
                              return prev;
                            });
                          },
                          isLoadingLookupPorts,
                          onShowPortsChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS,
                                value
                              );
                              return prev;
                            });
                            showPortsRef.current = value;
                          },
                          showVesselsNames,
                          onShowVesselsNamesChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_NAMES,
                                value
                              );
                              return prev;
                            });
                          },
                          disableShowVesselsNames:
                            filteredVessels?.length > MAX_VESSELS_TO_SHOW_NAME,
                          showVessels,
                          onShowVesselsChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS,
                                value
                              );
                              return prev;
                            });
                          },
                          showVesselsStatus,
                          onShowVesselsStatusChange: (value) => {
                            setSearchParams((prev) => {
                              prev.set(
                                ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_VESSELS_STATUS,
                                value
                              );
                              return prev;
                            });
                          }
                        }}
                        inboundVesselsFilterProps={{
                          onApply: (filters, isResetClick) => {
                            setActiveInboundVesselsFilters(filters);

                            setActiveAdvancedFilters(null);
                            setQuickSearchItemId(null);
                            setQuickSearchItemType(null);

                            setSearchParams((prev) => {
                              if (!isResetClick) {
                                prev.set(
                                  ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE,
                                  SIDE_PANEL_TYPES.INSIGHT
                                );
                                prev.set(
                                  ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE,
                                  SIDE_PANEL_STATES.OPEN
                                );
                                prev.set(
                                  ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS,
                                  true
                                );
                              } else {
                                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE);
                                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE);
                                prev.set(
                                  ACTIVITY_MAP_SEARCH_PARAM_KEYS.MAP_SETTINGS.SHOW_PORTS,
                                  showPortsRef.current
                                );
                              }
                              return prev;
                            });
                          },
                          defaultFilters: activeInboundVesselsFilters,
                          isInboundVesselsFiltering: true,
                          isLoading:
                            isLoadingVesselsLatestPosition ||
                            isLoadingLookupPorts ||
                            isLoadingLookupLocations
                        }}
                      />
                    </Paper>
                  </Box>
                )}
              </>
            );
          }}
          onInsightClick={() => {
            setSearchParams((prev) => {
              prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_STATE, SIDE_PANEL_STATES.OPEN);
              prev.set(
                ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_TYPE,
                searchItemTypeToSidePanelType(quickSearchItemType)
              );
              if (quickSearchItemId) {
                prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SIDE_PANEL_ITEM_ID, quickSearchItemId);
              }
              return prev;
            });
          }}
          selectedMapAction={selectedMapAction}
          onChangeSelectedAction={(value) => {
            setSearchParams((prev) => {
              if (!value) {
                prev.delete(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION);
                return prev;
              }
              prev.set(ACTIVITY_MAP_SEARCH_PARAM_KEYS.SELECTED_MAP_ACTION, value);
              return prev;
            });
          }}
          isAdvancedFilterApplied={
            activeAdvancedFilters &&
            checkDashboardActivityMapFilterApplied(activeAdvancedFilters, false, isRoRo)
          }
          isInboundVesselFilterApplied={
            activeInboundVesselsFilters &&
            checkDashboardActivityMapFilterApplied(activeInboundVesselsFilters, true, isRoRo)
          }
          isDisableMapActions={isLoadingVesselsLatestPosition}
        />
      </Box>
    </Box>
  );
}

DashboardActivityMapSection.propTypes = {
  isCompactView: PropTypes.bool,
  onClickFullscreen: PropTypes.func
};

export default DashboardActivityMapSection;
