import React from 'react';
import PropTypes from 'prop-types';
import { ContentPanel, ContentBox } from '../../../../Maps';
import { Box, Button } from '@esgian/esgianui';
import { useOperatorData } from '@hooks';
import OperatorOverview from './OperatorOverview';
import OperatorAverageSpeed from './OperatorAverageSpeed';
import OperatorActivityProfile from './OperatorActivityProfile';
import { PageLinkIcon } from '@components';
import { ProfileLink } from '@components/Links';

const OperatorDetailsPanel = ({ operatorId, operatorVesselHierarchy = [], handleClose }) => {
  const { data: operatorData, isPending: isOperatorDataPending } = useOperatorData(operatorId);
  const selectedOperator = operatorVesselHierarchy.find((operator) => operator.id === operatorId);
  return (
    <ContentPanel
      title={selectedOperator?.name}
      footerActions={
        <Box textAlign="center">
          <Button startIcon={<PageLinkIcon />} sx={{ textTransform: 'none' }}>
            <ProfileLink
              name={selectedOperator?.name}
              profile={'operator'}
              id={operatorId}
              textColor="black"
            />
          </Button>
        </Box>
      }
      onClose={handleClose}>
      <Box display="flex" flexDirection="column" gap={2}>
        <ContentBox isLoading={isOperatorDataPending} skeletonHeight="200px">
          <OperatorOverview operatorData={operatorData} />
        </ContentBox>
        <ContentBox isLoading={isOperatorDataPending} skeletonHeight="300px">
          <OperatorAverageSpeed
            operatorId={operatorId}
            operatorShortName={selectedOperator?.operatorShortName}
          />
        </ContentBox>
        <ContentBox isLoading={isOperatorDataPending} skeletonHeight="300px">
          <OperatorActivityProfile
            operatorShortName={selectedOperator?.operatorShortName}
            operatorId={operatorId}
          />
        </ContentBox>
      </Box>
    </ContentPanel>
  );
};

OperatorDetailsPanel.propTypes = {
  operatorId: PropTypes.number.isRequired,
  operatorVesselHierarchy: PropTypes.array,
  handleClose: PropTypes.func
};

export default OperatorDetailsPanel;
