import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, Paper, Switch, Typography } from '@esgian/esgianui';
import DashboardActivityMapDrawerHeader from '../Common/DashboardActivityMapDrawerHeader';
import { getTransparentBackground } from '@helpers';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { TextWithTooltipIcon } from '@components';

export const MAX_VESSELS_TO_SHOW_NAME = 150;

const DashboardMapSettings = ({
  onClose,
  showPorts,
  onShowPortsChange,
  showVesselsNames,
  onShowVesselsNamesChange,
  showVesselsStatus,
  disableShowVesselsStatus,
  onShowVesselsStatusChange,
  showVessels,
  onShowVesselsChange,
  isLoadingLookupPorts,
  // showPortNames,
  // onShowPortNamesChange,
  disableShowVesselsNames
  // showInboundVesselsColoredPorts,
  // onShowInboundVesselsColoredPortsChange,
  // showColoredInboundVessels,
  // onShowColoredInboundVesselsChange,
  // disableShowColoredInboundVessels,
  // disableShowInboundVesselsColoredPorts
}) => {
  const themeMode = useSelector(getThemeMode);

  const settingsSections = [
    {
      sectionTitle: 'Port',
      settings: [
        {
          label: 'Show port',
          name: 'showPort',
          value: showPorts,
          onChange: (e) => onShowPortsChange(e.target.checked),
          disabled: isLoadingLookupPorts
        }
        // {
        //   label: 'Show colored ports',
        //   name: 'showInboundVesselsColoredPorts',
        //   value: showInboundVesselsColoredPorts,
        //   onChange: (e) => onShowInboundVesselsColoredPortsChange(e.target.checked),
        //   disabled: disableShowInboundVesselsColoredPorts
        // }
        // {
        //   label: 'Show port name',
        //   name: 'showPortName',
        //   value: showPortNames,
        //   onChange: (e) => onShowPortNamesChange(e.target.checked)
        // },
      ]
    },
    {
      sectionTitle: 'Vessel',
      settings: [
        {
          label: 'Show vessels',
          name: 'showVessels',
          value: showVessels,
          onChange: (e) => onShowVesselsChange(e.target.checked)
        },
        {
          label: 'Show vessel name',
          name: 'showVesselName',
          value: showVesselsNames,
          onChange: (e) => onShowVesselsNamesChange(e.target.checked),
          disabled: disableShowVesselsNames,
          tooltip: disableShowVesselsNames
            ? `You can only show vessel name if the number of vessels is no more than ${MAX_VESSELS_TO_SHOW_NAME} on the map`
            : ''
        },
        {
          label: 'Show vessel status',
          name: 'showVesselStatus',
          value: showVesselsStatus,
          onChange: (e) => onShowVesselsStatusChange(e.target.checked),
          disabled: disableShowVesselsStatus,
          tooltip: disableShowVesselsStatus ? 'Vessel status is unavailable in inbound search' : ''
        }
        // {
        //   label: 'Show colored inbound vessels',
        //   name: 'showColoredInboundVessels',
        //   value: showColoredInboundVessels,
        //   onChange: (e) => onShowColoredInboundVesselsChange(e.target.checked),
        //   disabled: disableShowColoredInboundVessels
        // }
      ]
    }
  ];

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 2,
        backgroundColor: getTransparentBackground(themeMode)
      }}>
      <DashboardActivityMapDrawerHeader title="Map setting" onClose={onClose} />
      {settingsSections.map((section) => (
        <Box key={section.sectionTitle}>
          <Typography>{section.sectionTitle}: </Typography>
          <ul style={{ margin: 0, paddingLeft: '16px' }}>
            {section.settings.map((setting) => (
              <li key={setting.name}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  {setting.tooltip ? (
                    <TextWithTooltipIcon
                      label={<FormLabel>{setting.label}</FormLabel>}
                      tooltipText={setting.tooltip}
                    />
                  ) : (
                    <FormLabel>{setting.label}</FormLabel>
                  )}
                  <Switch
                    disabled={!setting?.onChange || setting?.disabled}
                    checked={setting.value}
                    onChange={setting?.onChange}
                  />
                </Box>
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Paper>
  );
};

DashboardMapSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  showPorts: PropTypes.bool,
  onShowPortsChange: PropTypes.func,
  showVesselsNames: PropTypes.bool,
  onShowVesselsNamesChange: PropTypes.func,
  showVesselsStatus: PropTypes.bool,
  onShowVesselsStatusChange: PropTypes.func,
  showVessels: PropTypes.bool,
  onShowVesselsChange: PropTypes.func,
  isLoadingLookupPorts: PropTypes.bool,
  showPortNames: PropTypes.bool,
  onShowPortNamesChange: PropTypes.func,
  disableShowVesselsNames: PropTypes.bool,
  showInboundVesselsColoredPorts: PropTypes.bool,
  onShowInboundVesselsColoredPortsChange: PropTypes.func,
  showColoredInboundVessels: PropTypes.bool,
  onShowColoredInboundVesselsChange: PropTypes.func,
  disableShowColoredInboundVessels: PropTypes.bool,
  disableShowInboundVesselsColoredPorts: PropTypes.bool,
  disableShowVesselsStatus: PropTypes.bool
};

export default DashboardMapSettings;
