import React from 'react';
import PropTypes from 'prop-types';
import { CountryFlag, Divider, Link, Typography, Box, Paper } from '@esgian/esgianui';

function OwnerOverview({ ownerData }) {
  const ensureHttpsAndWww = (website) => {
    let site = website;
    if (!website.startsWith('www.') && !website.startsWith('https://')) {
      site = `https://www.${site}`;
    } else if (website.startsWith('www.')) {
      site = `https://${site}`;
    }
    return site;
  };

  const { country, address, website, ownerLongName, ownerShortName, isoAlpha2 } = ownerData || {};

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6">Overview</Typography>
      <Divider />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" fontWeight="bold" sx={{ minWidth: 100 }}>
            Name:
          </Typography>
          <Typography variant="body2">{ownerLongName}</Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" fontWeight="bold" sx={{ minWidth: 100 }}>
            Short Name:
          </Typography>
          <Typography variant="body2">{ownerShortName}</Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" fontWeight="bold" sx={{ minWidth: 100 }}>
            Country:
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CountryFlag size={15} countryCode={isoAlpha2} />
            <Typography variant="body2">{country}</Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" fontWeight="bold" sx={{ minWidth: 100 }}>
            Address:
          </Typography>
          <Typography variant="body2">{address}</Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="body2" fontWeight="bold" sx={{ minWidth: 100 }}>
            Website:
          </Typography>
          {website ? (
            <Link variant="body2" target="__blank" href={ensureHttpsAndWww(website)}>
              {website}
            </Link>
          ) : (
            <Typography variant="body2">{website}</Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

OwnerOverview.propTypes = {
  ownerData: PropTypes.object
};

OwnerOverview.defaultProps = {
  ownerData: null
};

export default OwnerOverview;
