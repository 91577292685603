import { useQuery } from '@tanstack/react-query';
import { getVesselLatestPositionByTypes } from '@api';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';

export const useVesselsLatestPositionByTypes = () => {
  const { segment } = useSelector(getGeneralSlice);
  return useQuery({
    queryKey: ['vesselLatestPositionByTypes', segment.id],
    queryFn: ({ signal }) => getVesselLatestPositionByTypes(segment.id, signal)
  });
};
