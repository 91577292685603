import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AdvancedFilterDrawer,
  FilterSlider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@esgian/esgianui';
import { useSegment } from '@hooks/useSegment';
import {
  OPERATOR_TYPE,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT,
  VESSEL_MAX_LIFTING_CAPACITY,
  VESSEL_MIN_BUILD_YEAR,
  COMMERCIAL_CATEGORY_TYPE,
  VESSEL_MAX_GROSS_TONNAGE
} from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompletedVoyagesFilters,
  updateCompletedVoyagesFilters
} from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import { formatNumberReadable } from '@helpers';
import moment from 'moment';
import {
  COM_CAT_TYPE_ALL_TEXT,
  COM_CAT_TYPE_DEEP_SEA_CORE_TEXT,
  COM_CAT_TYPE_DEEP_SEA_EXT_TEXT,
  COM_CAT_TYPE_OTHER_TEXT,
  COM_CAT_TYPE_SHORT_SEA_EUR_TEXT,
  OPR_TYPE_BOTH_TEXT,
  OPR_TYPE_DEEP_SEA_TEXT,
  OPR_TYPE_SHORT_SEA_TEXT
} from '@constants/tooltips';
import { TextWithTooltipIcon } from '@components';

function AdvancedVoyageAnalyticsFilters({ advancedOpen, setAdvancedOpen, handleApplyFilters }) {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const voyageAnalyticsFilters = useSelector(getCompletedVoyagesFilters);
  const { isRoRo } = useSegment();
  const {
    minCarCapacity = 0,
    maxCarCapacity = VESSEL_MAX_CAPACITY,
    minLiftingCapacity = 0,
    maxLiftingCapacity = VESSEL_MAX_LIFTING_CAPACITY,
    minBuildYear = VESSEL_MIN_BUILD_YEAR,
    maxBuildYear = moment().year(),
    minDeadweight = 0,
    maxDeadweight = VESSEL_MAX_CAPACITY_DWT,
    minGrossTonnage = 0,
    maxGrossTonnage = VESSEL_MAX_GROSS_TONNAGE,
    operatorTypeId = OPERATOR_TYPE.ALL,
    commercialCategoryId = COMMERCIAL_CATEGORY_TYPE.ALL
  } = filters;

  const handleClearClick = () => {
    setAdvancedOpen(false);
  };

  const handleApplyClick = () => {
    dispatch(updateCompletedVoyagesFilters(filters));
    setAdvancedOpen(false);
    handleApplyFilters();
  };

  useEffect(() => {
    isRoRo
      ? dispatch(
          updateCompletedVoyagesFilters({
            minLiftingCapacity: 0,
            maxLiftingCapacity: VESSEL_MAX_CAPACITY
          })
        )
      : dispatch(
          updateCompletedVoyagesFilters({ minCarCapacity: 0, maxCarCapacity: VESSEL_MAX_CAPACITY })
        );
  }, []);

  const updateTempFilters = (payload) => {
    setFilters({ ...filters, ...payload });
  };
  useEffect(() => {
    setFilters({
      ...voyageAnalyticsFilters
    });
  }, [voyageAnalyticsFilters]);

  return (
    <AdvancedFilterDrawer
      anchor={'right'}
      open={advancedOpen}
      handleReset={handleClearClick}
      onClose={() => setAdvancedOpen(false)}
      handleApply={handleApplyClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
                Display {isRoRo ? 'Operators' : 'Commercial category'}
              </Typography>
              {isRoRo ? (
                <RadioGroup
                  value={operatorTypeId}
                  onChange={({ target }) => {
                    updateTempFilters({ operatorTypeId: Number(target.value) });
                  }}>
                  <FormControlLabel
                    value={OPERATOR_TYPE.ALL}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>All</Typography>}
                        tooltipText={OPR_TYPE_BOTH_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={OPERATOR_TYPE.SHORT_SEA}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>Short sea</Typography>}
                        tooltipText={OPR_TYPE_SHORT_SEA_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={OPERATOR_TYPE.DEEP_SEA}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>Deep sea</Typography>}
                        tooltipText={OPR_TYPE_DEEP_SEA_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                </RadioGroup>
              ) : (
                <RadioGroup
                  value={commercialCategoryId}
                  onChange={({ target }) => {
                    updateTempFilters({ commercialCategoryId: Number(target.value) });
                  }}>
                  <FormControlLabel
                    value={COMMERCIAL_CATEGORY_TYPE.ALL}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>All</Typography>}
                        tooltipText={COM_CAT_TYPE_ALL_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_CORE}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>Deep sea core</Typography>}
                        tooltipText={COM_CAT_TYPE_DEEP_SEA_CORE_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_EXT}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>Deep sea extended</Typography>}
                        tooltipText={COM_CAT_TYPE_DEEP_SEA_EXT_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={COMMERCIAL_CATEGORY_TYPE.SHORT_SEA_EUR}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>Short sea Europe</Typography>}
                        tooltipText={COM_CAT_TYPE_SHORT_SEA_EUR_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={COMMERCIAL_CATEGORY_TYPE.OTHER}
                    label={
                      <TextWithTooltipIcon
                        label={<Typography variant={'subtitle2'}>Widedeck / Other</Typography>}
                        tooltipText={COM_CAT_TYPE_OTHER_TEXT}
                      />
                    }
                    control={<Radio />}
                  />
                </RadioGroup>
              )}
            </Stack>
            <Grid item xs={12} className={'section-filter'}>
              <FilterSlider
                disableMarkRotation
                sliderWidth={'97%'}
                step={isRoRo ? 100 : 1000}
                marks={[
                  { value: 0, label: 0 },
                  {
                    value: isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_LIFTING_CAPACITY,
                    label: isRoRo
                      ? formatNumberReadable(VESSEL_MAX_CAPACITY)
                      : formatNumberReadable(VESSEL_MAX_LIFTING_CAPACITY)
                  }
                ]}
                value={
                  isRoRo
                    ? [minCarCapacity, maxCarCapacity]
                    : [minLiftingCapacity, maxLiftingCapacity]
                }
                max={isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_LIFTING_CAPACITY}
                min={0}
                title={isRoRo ? 'Car capacity (CEU)' : 'Lifting capacity (MT)'}
                onChange={(val) =>
                  updateTempFilters(
                    isRoRo
                      ? {
                          minCarCapacity: val[0],
                          maxCarCapacity: val[1]
                        }
                      : {
                          minLiftingCapacity: val[0],
                          maxLiftingCapacity: val[1]
                        }
                  )
                }
              />
            </Grid>
            <Grid item xs={12} className={'section-filter'}>
              <FilterSlider
                sliderWidth={'98%'}
                disableMarkRotation
                marks={[
                  { value: VESSEL_MIN_BUILD_YEAR, label: VESSEL_MIN_BUILD_YEAR },
                  { value: moment().year(), label: moment().year() }
                ]}
                value={[minBuildYear, maxBuildYear]}
                max={moment().year()}
                min={VESSEL_MIN_BUILD_YEAR}
                title={'Build year'}
                onChange={(val) =>
                  updateTempFilters({ minBuildYear: val[0], maxBuildYear: val[1] })
                }
              />
            </Grid>
            <Grid item xs={12} className={'section-filter'}>
              <FilterSlider
                sliderWidth={'96%'}
                disableMarkRotation
                step={1000} // Adjust the step size as needed
                marks={[
                  { value: 0, label: 0 },
                  {
                    value: VESSEL_MAX_CAPACITY_DWT,
                    label: formatNumberReadable(VESSEL_MAX_CAPACITY_DWT)
                  }
                ]}
                value={[minDeadweight, maxDeadweight]}
                max={VESSEL_MAX_CAPACITY_DWT}
                min={0}
                title={'Deadweight (DWT)'}
                onChange={(val) =>
                  updateTempFilters({ minDeadweight: val[0], maxDeadweight: val[1] })
                }
              />
            </Grid>

            <Grid item xs={12} className={'section-filter'}>
              <FilterSlider
                sliderWidth={'96%'}
                disableMarkRotation
                step={10} // Adjust the step size as needed
                marks={[
                  { value: 0, label: 0 },
                  {
                    value: VESSEL_MAX_GROSS_TONNAGE,
                    label: formatNumberReadable(VESSEL_MAX_GROSS_TONNAGE)
                  }
                ]}
                value={[minGrossTonnage, maxGrossTonnage]}
                max={VESSEL_MAX_GROSS_TONNAGE}
                min={0}
                title={'Gross Tonnage (GT)'}
                onChange={(val) =>
                  updateTempFilters({ minGrossTonnage: val[0], maxGrossTonnage: val[1] })
                }
              />
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </AdvancedFilterDrawer>
  );
}

AdvancedVoyageAnalyticsFilters.propTypes = {
  advancedOpen: PropTypes.bool,
  setAdvancedOpen: PropTypes.func.isRequired,
  handleApplyFilters: PropTypes.func.isRequired
};

AdvancedVoyageAnalyticsFilters.defaultProps = {
  advancedOpen: false
};

export default AdvancedVoyageAnalyticsFilters;
