import React from 'react';
import PropTypes from 'prop-types';

const PortPinIcon = ({ color = '#80DFEB', size = 16 }) => {
  const width = size;
  const height = Math.round(size * 1.2);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.78727 4.42833L4.45034 4.0589L4.78727 4.42833C7.66074 1.80757 11.0567 0.5 15 0.5C18.9433 0.5 22.3393 1.80757 25.2127 4.42833C28.0659 7.03055 29.5 10.4296 29.5 14.6731C29.5 17.507 28.3081 20.6726 25.8419 24.1824C23.4303 27.6143 19.8221 31.3374 15 35.3505C10.1779 31.3374 6.56974 27.6143 4.15813 24.1824C1.69191 20.6726 0.5 17.507 0.5 14.6731C0.5 10.4296 1.93409 7.03055 4.78727 4.42833ZM15.0034 18.236C16.1183 18.236 17.0882 17.8526 17.885 17.0908C18.6822 16.3287 19.0923 15.3928 19.0923 14.3094C19.0923 13.2253 18.6806 12.2901 17.8805 11.5301C17.0815 10.7712 16.111 10.3893 14.9966 10.3893C13.8817 10.3893 12.9118 10.7726 12.115 11.5344C11.3178 12.2965 10.9077 13.2324 10.9077 14.3158C10.9077 15.3999 11.3194 16.3351 12.1195 17.0951C12.9185 17.8541 13.889 18.236 15.0034 18.236Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M20.5 15C20.5 18.0376 18.0376 20.5 15 20.5C11.9624 20.5 9.5 18.0376 9.5 15C9.5 11.9624 11.9624 9.5 15 9.5C18.0376 9.5 20.5 11.9624 20.5 15Z"
        fill="white"
        stroke={color}
      />
    </svg>
  );
};

PortPinIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

export default PortPinIcon;
