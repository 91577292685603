import React from 'react';
import PropTypes from 'prop-types';
import { ContentPanel, ContentBox } from '../../../../Maps';
import { Box, Button, Skeleton } from '@esgian/esgianui';
import { useOwnerData, useLatestPortCalls } from '@hooks';
import OwnerOverview from './OwnerOverview';
import { VesselPortCallsTable } from '@components/Tables';
import { ProfileLink } from '@components/Links';
import { PageLinkIcon } from '../../../../Icons';

const OwnerDetailsPanel = ({ ownerId, ownerOperatorVesselHierarchy = [], handleClose }) => {
  const { data: ownerData, isPending: isOwnerDataPending } = useOwnerData(ownerId);

  const { data: vesselsLatestPortCalls, isPending: isLoadingVesselsLatestPortCalls } =
    useLatestPortCalls({
      owners: [ownerId]
    });

  const selectedOwner = ownerOperatorVesselHierarchy?.find((owner) => owner.id === ownerId);

  return (
    <ContentPanel
      title={selectedOwner?.name ?? <Skeleton variant="text" width={200} />}
      footerActions={
        <Box textAlign="center">
          <Button startIcon={<PageLinkIcon />} sx={{ textTransform: 'none' }}>
            <ProfileLink
              name={selectedOwner?.name}
              profile={'owner'}
              id={selectedOwner?.id}
              textColor="black"
            />
          </Button>
        </Box>
      }
      onClose={handleClose}>
      <Box display="flex" flexDirection="column" gap={2}>
        <ContentBox isLoading={isOwnerDataPending} skeletonHeight="200px">
          <OwnerOverview ownerData={ownerData} />
        </ContentBox>

        {/* <ContentBox isLoading={isOwnerDataPending} skeletonHeight="300px">
          <VesselsTable
            hideColumns={[
              'grossTonnage',
              'deadweight',
              'ownerLongName',
              'maxLiftingCapacity',
              'numCranes'
            ]}
            title={'Owner Vessels'}
            vessels={ownerData?.ownerVessels}
            fileName={`fleet-list-${selectedOwner?.ownerShortName}`}
          />
        </ContentBox> */}
        <ContentBox isLoading={isOwnerDataPending} skeletonHeight="300px">
          <VesselPortCallsTable
            assetLevel={'owner'}
            ownerId={ownerId}
            name={selectedOwner?.ownerShortName}
            hideColumns={[
              'imo',
              'operatorName',
              'countryName',
              'portCode',
              'startDate',
              'arrivalDraught',
              'endDate',
              'departureDraught'
            ]}
            portCalls={vesselsLatestPortCalls}
            loading={isLoadingVesselsLatestPortCalls}
            title={'Last Port Calls'}
          />
        </ContentBox>
      </Box>
    </ContentPanel>
  );
};

OwnerDetailsPanel.propTypes = {
  ownerId: PropTypes.number.isRequired,
  ownerOperatorVesselHierarchy: PropTypes.array,
  handleClose: PropTypes.func
};

export default OwnerDetailsPanel;
