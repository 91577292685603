import { useQuery } from '@tanstack/react-query';
import { getUpcomingPortCalls } from '@api';
import { useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import { useSegment } from '@hooks';
import { DEFAULT_NUM_PORT_CALLS } from '@constants';

export const useNextPortCalls = ({ geoType, geoIds }) => {
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { segment } = useSegment();

  return useQuery({
    queryKey: [
      'upcomingPortCalls',
      geoType,
      geoIds,
      operatorType,
      segment.id,
      commercialCategoryType
    ],
    queryFn: ({ signal }) =>
      getUpcomingPortCalls(
        {
          numberOfIncomingEntries: DEFAULT_NUM_PORT_CALLS,
          segmentTypeId: segment.id,
          operatorTypeId: operatorType,
          commercialCategoryId: commercialCategoryType,
          geoIds: geoIds,
          geoType: geoType
        },
        signal
      ).catch(() => []),
    enabled: Boolean(geoType && geoIds)
  });
};
