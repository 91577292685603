import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@esgian/esgianui';

const ContentBox = ({
  isLoading,
  children,
  skeletonHeight = '100%',
  skeletonWidth = '100%',
  skeletonVariant = 'rectangular',
  ...props
}) => {
  return (
    <Box {...props}>
      {isLoading ? (
        <Skeleton variant={skeletonVariant} height={skeletonHeight} width={skeletonWidth} />
      ) : (
        children
      )}
    </Box>
  );
};

ContentBox.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  skeletonHeight: PropTypes.string,
  skeletonWidth: PropTypes.string,
  skeletonVariant: PropTypes.string,
  ...Box.propTypes
};

export default ContentBox;
