// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tree-dropdown > div {
  max-width: none;
  min-width: none;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Maps/DashboardActivityMap/DashboardActivityMapActionWindow/dashboardActivityMapFilter.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb","sourcesContent":["#tree-dropdown > div {\n  max-width: none;\n  min-width: none;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
