import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import {
  BasicTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@esgian/esgianui';
import moment from 'moment';
import UnderlyingCanalStatsModal from '@components/Sections/ShipAnalytics/CanalProfileSection/UnderlyingCanalStatsModal/UnderlyingCanalStatsModal';
import { useTheme } from '@hooks/useTheme';
import { getDirection } from '@helpers';

const getDefaultOperatorSetWeek = (startDate, endDate) => {
  if (!startDate?.isValid() || !endDate?.isValid()) {
    return {};
  }

  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (
    startDateClone?.isValid() &&
    endDateClone?.isValid() &&
    startDateClone.isSameOrBefore(endDateClone.endOf('isoWeek'))
  ) {
    let weekNumber = startDateClone.isoWeek();
    let yearNumber = startDateClone.isoWeekYear();
    if (startDateClone.isoWeekYear() < startDateClone.year()) {
      startDateClone.add(1, 'isoWeek');
      continue;
    }
    let key = `${yearNumber}_${weekNumber}`;
    res[key] = {
      year: yearNumber,
      week: weekNumber,
      value: 0,
      modalData: {}
    };

    startDateClone.add(1, 'isoWeek');
  }
  return res;
};

const getDefaultOperatorSetMonth = (startDate, endDate) => {
  if (!startDate?.isValid() || !endDate?.isValid()) {
    return {};
  }

  let startDateClone = startDate.clone();
  let endDateClone = endDate.clone();
  let res = {};
  while (
    startDateClone?.isValid() &&
    endDateClone?.isValid() &&
    startDateClone.isSameOrBefore(endDateClone)
  ) {
    let monthNumber = startDateClone.month();
    let yearNumber = startDateClone.year();
    let key = `${yearNumber}_${monthNumber}`;
    res[key] = {
      year: yearNumber,
      month: monthNumber,
      value: 0,
      modalData: {}
    };

    startDateClone.add(1, 'month');
  }
  return res;
};

function CanalTransitsTableOperator({
  startDate = null,
  endDate = null,
  loading = false,
  canalTransits = null,
  timeFrame = 'weekly',
  fileName = ''
}) {
  if (!startDate?.isValid() || !endDate?.isValid()) {
    return null;
  }

  const user = useSelector(getUser);
  if (!user) {
    return null;
  }

  const [sortBy, setSortBy] = useState({ key: 'Total', order: 'desc' });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const { customScrollbar, theme } = useTheme();

  if (!theme?.mode || !customScrollbar) {
    return null;
  }

  const weeklyTableData = useMemo(() => {
    if (loading || !Array.isArray(canalTransits) || !canalTransits.length) {
      return [];
    }
    let dataSet = {};

    canalTransits.forEach((transit) => {
      if (!transit) return;

      const {
        canalArrival,
        operatorId,
        operatorShortName,
        headingDirection,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit: transitDuration
      } = transit;

      if (!operatorId || !canalArrival) {
        return;
      }

      if (!dataSet[operatorId]) {
        dataSet[operatorId] = {
          operatorName: operatorShortName || 'Unknown',
          data: getDefaultOperatorSetWeek(startDate, endDate)
        };
      }

      const arrival = moment(canalArrival);
      if (!arrival?.isValid()) {
        return;
      }

      let weekNumber = arrival.clone().isoWeek();
      let yearNumber = arrival.clone().isoWeekYear();

      if (!dataSet[operatorId]?.data) return;

      if (!Object.hasOwn(dataSet[operatorId].data, `${yearNumber}_${weekNumber}`)) {
        if (Object.hasOwn(dataSet[operatorId].data, `${yearNumber + 1}_${1}`)) {
          yearNumber++;
          weekNumber = 1;
        } else {
          yearNumber--;
          weekNumber = 52;
        }
      }

      const key = `${yearNumber}_${weekNumber}`;
      if (!dataSet[operatorId].data[key]) return;

      dataSet[operatorId].data[key].value += 1;

      const transitKey = `${imo}_${canalArrival}`;
      if (!dataSet[operatorId].data[key].modalData[transitKey]) {
        const transitHours = transitDuration
          ? moment.duration(transitDuration).isValid()
            ? moment.duration(transitDuration).asHours().toFixed(2)
            : '0.00'
          : '0.00';

        dataSet[operatorId].data[key].modalData[transitKey] = {
          vesselName: vesselName || 'Unknown',
          imo: imo || 'Unknown',
          operatorLongName: operatorLongName || 'Unknown',
          canalArrival: canalArrival || null,
          canalDeparture: canalDeparture || null,
          transit: transitHours,
          headingDirection: getDirection(headingDirection) || 'Unknown',
          year: yearNumber,
          period: weekNumber,
          operatorId: operatorId
        };
      }
    });

    return dataSet;
  }, [canalTransits, startDate, endDate]);

  const monthlyTableData = useMemo(() => {
    if (loading || !Array.isArray(canalTransits) || !canalTransits.length) {
      return [];
    }
    let dataSet = {};

    canalTransits.forEach((transit) => {
      if (!transit) return;

      const {
        canalArrival,
        operatorId,
        operatorShortName,
        headingDirection,
        vesselName,
        imo,
        operatorLongName,
        canalDeparture,
        transit: transitDuration
      } = transit;

      if (!operatorId || !canalArrival) {
        return;
      }

      if (!dataSet[operatorId]) {
        dataSet[operatorId] = {
          operatorName: operatorShortName || 'Unknown',
          data: getDefaultOperatorSetMonth(startDate, endDate)
        };
      }

      const arrival = moment(canalArrival);
      if (!arrival?.isValid()) {
        return;
      }

      if (!dataSet[operatorId]?.data) return;

      const monthNumber = arrival.clone().month();
      const yearNumber = arrival.clone().year();
      const key = `${yearNumber}_${monthNumber}`;

      if (!dataSet[operatorId].data[key]) return;

      dataSet[operatorId].data[key].value += 1;

      const transitKey = `${imo}_${canalArrival}`;
      if (!dataSet[operatorId].data[key].modalData[transitKey]) {
        const transitHours = transitDuration
          ? moment.duration(transitDuration).isValid()
            ? moment.duration(transitDuration).asHours().toFixed(2)
            : '0.00'
          : '0.00';

        const monthLabel = moment().month(monthNumber);
        dataSet[operatorId].data[key].modalData[transitKey] = {
          vesselName: vesselName || 'Unknown',
          imo: imo || 'Unknown',
          operatorLongName: operatorLongName || 'Unknown',
          canalArrival: canalArrival || null,
          canalDeparture: canalDeparture || null,
          transit: transitHours,
          headingDirection: getDirection(headingDirection) || 'Unknown',
          year: yearNumber,
          period: monthLabel.isValid() ? monthLabel.format('MMM') : 'Unknown',
          operatorId: operatorId
        };
      }
    });

    return dataSet;
  }, [canalTransits, startDate, endDate]);

  let yearGroups = [];
  let timeColumns = {};
  let opNames = {};

  if (timeFrame === 'weekly' && weeklyTableData) {
    Object.entries(weeklyTableData).forEach(([operatorId, operatorData]) => {
      if (!operatorData?.data) return;

      opNames[operatorId] = {
        operatorName: operatorData.operatorName || 'Unknown',
        data: {},
        total: {
          value: 0,
          modalData: {}
        }
      };
      opNames[operatorId].data = operatorData.data;

      Object.entries(operatorData.data).forEach(([weeklyKey, weekData]) => {
        if (!weekData) return;

        opNames[operatorId].total.value += weekData.value || 0;
        opNames[operatorId].total.modalData = {
          ...opNames[operatorId].total.modalData,
          ...(weekData.modalData || {})
        };

        if (!yearGroups.find(({ mentionedYear }) => mentionedYear === weekData.year)) {
          yearGroups.push({
            mentionedYear: weekData.year,
            yearCount: 0
          });

          Object.values(operatorData.data).forEach((opRow) => {
            if (!opRow) return;

            const yearGroup = yearGroups.find(
              ({ mentionedYear }) => mentionedYear === opRow.year && mentionedYear === weekData.year
            );
            if (yearGroup) {
              yearGroup.yearCount++;
            }
          });
        }

        if (weekData.year && weekData.week) {
          timeColumns[`${weekData.year}_${weekData.week}`] = weekData.week;
        }
      });
    });
  } else if (monthlyTableData) {
    Object.entries(monthlyTableData).forEach(([operatorId, operatorData]) => {
      if (!operatorData?.data) return;

      opNames[operatorId] = {
        operatorName: operatorData.operatorName || 'Unknown',
        data: {},
        total: {
          value: 0,
          modalData: {}
        }
      };
      opNames[operatorId].data = operatorData.data;

      Object.entries(operatorData.data).forEach(([monthlyKey, monthData]) => {
        if (!monthData) return;

        opNames[operatorId].total.value += monthData.value || 0;
        opNames[operatorId].total.modalData = {
          ...opNames[operatorId].total.modalData,
          ...(monthData.modalData || {})
        };

        if (!yearGroups.find(({ mentionedYear }) => mentionedYear === monthData.year)) {
          yearGroups.push({
            mentionedYear: monthData.year,
            yearCount: 0
          });

          Object.values(operatorData.data).forEach((opRow) => {
            if (!opRow) return;

            const yearGroup = yearGroups.find(
              ({ mentionedYear }) =>
                mentionedYear === opRow.year && mentionedYear === monthData.year
            );
            if (yearGroup) {
              yearGroup.yearCount++;
            }
          });
        }

        if (monthData.year !== undefined && monthData.month !== undefined) {
          const monthLabel = moment().month(monthData.month);
          timeColumns[`${monthData.year}_${monthData.month}`] = monthLabel.isValid()
            ? `${monthLabel.format('MMM')} ${monthData.year}`
            : 'Unknown';
        }
      });
    });
  }

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      fixedHeader: true,
      responsive: 'standard',
      filter: false,
      search: false,
      download: checkUserHasAccess(user, true),
      elevation: 0,
      downloadOptions: {
        filename: fileName || 'canal_transits'
      },
      setTableProps: () => ({
        id: timeFrame === 'weekly' ? 'weekly-canal-transits-table' : 'monthly-canal-transits-table'
      }),
      print: false,
      viewColumns: false,
      pagination: false,
      toolbar: true,
      tableBodyMaxHeight: '20vh',
      tableBodyHeight: '20vh',
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, [weeklyTableData, monthlyTableData, user, fileName, timeFrame]);

  let convertedColumns = [];
  Object.entries(timeColumns).forEach(([key, value]) => {
    if (value) {
      convertedColumns.push(value);
    }
  });

  if (!convertedColumns.length || !Object.keys(opNames).length) {
    return null;
  }

  const sortData = (obj) => {
    const operators = Object.keys(obj);
    const sortedObj = {};

    if (sortBy.key === 'Operator/Week' || sortBy.key === 'Operator') {
      const sortedNames = operators.sort((a, b) =>
        sortBy.order === 'asc'
          ? obj[a].operatorName.localeCompare(obj[b].operatorName)
          : obj[b].operatorName.localeCompare(obj[a].operatorName)
      );

      sortedNames.forEach((opId) => {
        sortedObj[obj[opId].operatorName] = obj[opId];
      });
    } else if (sortBy.key === 'Total') {
      const sortedOperators = operators.sort((a, b) =>
        sortBy.order === 'asc'
          ? obj[a].total.value - obj[b].total.value
          : obj[b].total.value - obj[a].total.value
      );

      sortedOperators.forEach((opId) => {
        sortedObj[obj[opId].operatorName] = obj[opId];
      });
    } else {
      return obj;
    }

    return sortedObj;
  };
  const handleSort = (key) => {
    setSortBy((prevSortBy) => {
      if (prevSortBy.key === key) {
        const nextOrder =
          prevSortBy.order === 'asc' ? 'desc' : prevSortBy.order === 'desc' ? null : 'asc';
        return { key: nextOrder ? key : null, order: nextOrder };
      }
      return { key, order: 'asc' };
    });
  };
  const sortedOperatorNames = sortData(opNames);
  return (
    <>
      <TableContainer sx={{ maxHeight: 440, ...customScrollbar }} mode={theme.mode}>
        <BasicTable stickyHeader aria-label="sticky table" options={options}>
          {timeFrame === 'weekly' && (
            <TableHead>
              <TableRow>
                {[{ isEmpty: true }, ...yearGroups].map(
                  ({ isEmpty, mentionedYear, yearCount }, i) => (
                    <TableCell
                      key={isEmpty ? i : mentionedYear}
                      align="center"
                      colSpan={isEmpty ? 1 : yearCount}>
                      <b>{mentionedYear ?? ''}</b>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
          )}
          <TableHead>
            <TableRow>
              {[
                timeFrame === 'weekly' ? 'Operator/Week' : 'Operator',
                ...convertedColumns,
                'Total'
              ].map((val, index) => (
                <TableCell
                  key={`${index}-${val}`}
                  align="center"
                  colSpan={1}
                  onClick={() =>
                    (val === 'Operator/Week' || val === 'Operator' || val === 'Total') &&
                    handleSort(val)
                  }>
                  <b>{val}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(sortedOperatorNames).map((operator) => {
              const { operatorName, data, total } = sortedOperatorNames[operator];
              if (!data || !total) return null;

              return (
                <TableRow key={operator}>
                  <TableCell colSpan={1} align="center">
                    <b>{operatorName || 'Unknown'}</b>
                  </TableCell>
                  {Object.entries(data).map(([key, value]) => {
                    if (!value) return null;

                    return (
                      <TableCell
                        colSpan={1}
                        sx={{
                          cursor: 'pointer'
                        }}
                        align="center"
                        key={`${key}_${operator}_${operatorName}`}
                        onClick={() => {
                          if (value.modalData) {
                            setModalOpen(true);
                            setModalData(value.modalData);
                          }
                        }}>
                        {value.value || 0}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    colSpan={1}
                    sx={{
                      cursor: 'pointer'
                    }}
                    align="center"
                    onClick={() => {
                      if (total.modalData) {
                        setModalOpen(true);
                        setModalData(total.modalData);
                      }
                    }}>
                    {total.value || 0}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </BasicTable>
      </TableContainer>
      {modalOpen && modalData && Object.keys(modalData).length > 0 && (
        <UnderlyingCanalStatsModal
          modalOpen={modalOpen}
          modalData={modalData}
          setModalOpen={setModalOpen}
          setModalData={setModalData}
        />
      )}
    </>
  );
}

CanalTransitsTableOperator.propTypes = {
  canalTransits: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  timeFrame: PropTypes.string,
  fileName: PropTypes.string
};

export default CanalTransitsTableOperator;
