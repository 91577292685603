import React from 'react';
import {
  Box,
  IconButton,
  InsightsOutlinedIcon,
  SettingsOutlinedIcon,
  ToolTip,
  TuneIcon
} from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import PropTypes from 'prop-types';
import { CircuitIcon } from '../../../Icons';

export const ACTIVITY_MAP_ACTIONS = {
  ADVANCED_FILTER: 'advanced-filter',
  MY_SAVE: 'my-save',
  MAP_SETTINGS: 'map-settings',
  INBOUND_VESSELS_FILTER: 'inbound-vessels-filter'
};

const DashboardActivityMapActions = ({
  isDisabledInsight,
  onInsightClick,
  onFilterClick,
  isAdvancedFilterApplied,
  isInboundVesselFilterApplied,
  isInsightApplied,
  onMapSettingsClick,
  isMapSettingsApplied,
  onInboundVesselsFilterClick,
  isDisableMapActions
}) => {
  const themeMode = useSelector(getThemeMode);

  const getActions = (iconStyles = {}) => [
    {
      icon: <InsightsOutlinedIcon sx={iconStyles} />,
      onClick: onInsightClick,
      isActive: isInsightApplied,
      tooltip: 'Insight',
      id: ACTIVITY_MAP_ACTIONS.INSIGHT,
      disabled: isDisableMapActions
    },
    {
      icon: <TuneIcon size={iconStyles} />,
      onClick: onFilterClick,
      isActive: isAdvancedFilterApplied,
      tooltip: 'Advanced Filter',
      id: ACTIVITY_MAP_ACTIONS.ADVANCED_FILTER,
      disabled: isDisableMapActions
    },
    {
      icon: <SettingsOutlinedIcon sx={iconStyles} />,
      onClick: onMapSettingsClick,
      isActive: isMapSettingsApplied,
      tooltip: 'Map Settings',
      id: ACTIVITY_MAP_ACTIONS.MAP_SETTINGS,
      disabled: isDisableMapActions
    },
    {
      icon: <CircuitIcon />,
      onClick: onInboundVesselsFilterClick,
      isActive: isInboundVesselFilterApplied,
      tooltip: 'Inbound Vessels Filter',
      id: ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER,
      disabled: isDisableMapActions
    }
  ];

  const actions = getActions({
    fontSize: '24px',
    color: themeMode ? 'white' : 'black'
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: 1,
          border: `0.5px solid var(--dark-mode-border-divider-default, #7B7B7B)`,
          p: 1
        }}>
        {actions.map((action) => (
          <Box key={action.id} position="relative">
            <ToolTip
              title={action.tooltip}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.80)',
                    color: 'black'
                  }
                }
              }}>
              <IconButton
                disabled={
                  (action.id === ACTIVITY_MAP_ACTIONS.INSIGHT && isDisabledInsight) ||
                  isDisableMapActions
                }
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '4px',
                  border: `0.5px solid var(--dark-mode-border-divider-default, #7B7B7B)`,
                  height: '32px',
                  color: '#7B7B7B',
                  width: '32px',
                  ...(action.id === ACTIVITY_MAP_ACTIONS.INBOUND_VESSELS_FILTER && {
                    padding: '2px'
                  })
                }}
                onClick={action.onClick}>
                {action.icon}
              </IconButton>
              {action.isActive && (
                <Box
                  sx={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: '#1976d2',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: 0,
                    right: 0
                  }}
                />
              )}
            </ToolTip>
          </Box>
        ))}
      </Box>
    </>
  );
};

DashboardActivityMapActions.propTypes = {
  onInsightClick: PropTypes.func,
  onFilterClick: PropTypes.func,
  onMySaveClick: PropTypes.func,
  onMapSettingsClick: PropTypes.func,
  isDisabledInsight: PropTypes.bool,
  isInsightApplied: PropTypes.bool,
  isAdvancedFilterApplied: PropTypes.bool,
  isMapSettingsApplied: PropTypes.bool,
  isSavedApplied: PropTypes.bool,
  isInboundVesselsFilterApplied: PropTypes.bool,
  onInboundVesselsFilterClick: PropTypes.func,
  isInboundVesselFilterApplied: PropTypes.bool,
  isDisableMapActions: PropTypes.bool
};

export default DashboardActivityMapActions;
