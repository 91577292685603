import { useQuery } from '@tanstack/react-query';
import { getCountryPortStats } from '@api/Countries';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';

export const useCountryPortStats = (countryId) => {
  const { segment } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  return useQuery({
    queryKey: ['countryPortStats', countryId, segment.id, operatorType, commercialCategoryType],
    enabled: !!countryId,
    queryFn: ({ signal }) =>
      getCountryPortStats(
        {
          countryId,
          SegmentTypeId: segment.id,
          operatorTypeId: operatorType,
          commercialCategoryId: commercialCategoryType
        },
        signal
      )
  });
};
