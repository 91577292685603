import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@esgian/esgianui';
import { useSegment } from '@hooks';
import { useTheme } from '@hooks/useTheme';
import moment from 'moment';
import { formatToDecimalPlaces } from '@helpers';
import { ProfileLink } from '@components/Links';

function Top10PortsInsight({ top10Ports, lookupPorts }) {
  const { segmentName } = useSegment();
  const { customScrollbar } = useTheme();

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={4}>
        <Typography sx={{ fontWeight: 'bold' }}>
          Top 10 {segmentName} Ports ({moment().year()})
        </Typography>
        <Typography>YTD</Typography>
      </Box>

      <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
        {lookupPorts.length} ports
      </Typography>
      <Box
        sx={{
          maxHeight: '300px',
          position: 'relative',
          overflow: 'hidden'
        }}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: (theme) => theme.palette.background.paper,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`
          }}>
          <Box
            display="flex"
            justifyContent="space-between"
            gap={1}
            sx={{
              p: 0.75,
              borderRadius: 1
            }}>
            <Typography
              sx={{ width: '70%', fontWeight: 'bold', fontSize: '0.85rem', textAlign: 'left' }}>
              Port Name
            </Typography>
            <Typography
              sx={{ width: '30%', fontWeight: 'bold', fontSize: '0.85rem', textAlign: 'left' }}>
              Port Calls
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            maxHeight: 'calc(300px - 35px)',
            overflowY: 'auto',
            ...customScrollbar,
            '& > *:nth-of-type(even)': {
              backgroundColor: (theme) => theme.palette.action.hover
            }
          }}>
          {top10Ports?.map((port) => {
            const increase = +formatToDecimalPlaces(port.increase, 1);

            return (
              <Box
                key={port.portId}
                display="flex"
                gap={1}
                sx={{
                  p: 0.75,
                  borderRadius: 1
                }}>
                <Typography
                  sx={{
                    width: '70%',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    textAlign: 'left'
                  }}>
                  <ProfileLink
                    name={port.portName}
                    profile={'port'}
                    id={port.portId}
                    linkVariant="body1"
                  />
                </Typography>
                <Box
                  display="flex"
                  gap={1}
                  alignItems="center"
                  width="30%"
                  sx={{ textAlign: 'left' }}>
                  <Typography>{port.ytdPortCalls.toLocaleString()}</Typography>
                  <Typography
                    sx={{
                      color: increase > 0 ? 'success.main' : 'error.main',
                      fontSize: '0.7rem'
                    }}>
                    {increase > 0 ? `+${increase}%` : `${increase}%`}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
}

Top10PortsInsight.propTypes = {
  top10Ports: PropTypes.array,
  lookupPorts: PropTypes.array
};

export default Top10PortsInsight;
