import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { getOperatorCiiRatingData } from '@api/Emissions';
import { useSegment } from '@hooks';

export const useOperatorCiiRatingData = (operatorId) => {
  const { segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);

  return useQuery({
    queryKey: ['operatorCiiRating', operatorId, segment.id, operatorType, commercialCategoryType],
    queryFn: ({ signal }) =>
      getOperatorCiiRatingData(
        {
          OperatorId: operatorId,
          SegmentTypeId: segment.id,
          OperatorTypeId: operatorType,
          CommercialCategoryId: commercialCategoryType
        },
        signal
      )
  });
};
