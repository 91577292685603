import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, DateRangeMobi, Divider, Paper, Typography } from '@esgian/esgianui';
import { TextWithTooltipIcon } from '@components';
import { OperatorActivityProfileChart } from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections/OperatorActivitySection';
import moment from 'moment';
import { useSegment } from '@hooks/useSegment';
import { useOperatorActivityData } from '@hooks';

const tooltipContent = (
  <Box display="flex" flexDirection="column">
    <Typography variant="caption">Transit: Vessel Speed &gt; 3 kn</Typography>
    <Typography variant="caption">Standby: 0 kn &lt; Vessel Speed &le; 3 kn</Typography>
    <Typography variant="caption">At Port: Vessel at port</Typography>
  </Box>
);

const OperatorActivityProfile = ({ operatorShortName, operatorId }) => {
  const { segmentMinDate } = useSegment();
  const [activityDates, setActivityDates] = useState({
    start: moment().subtract(5, 'months').startOf('month'),
    end: moment()
  });

  const { data: activityData, isLoading: loading } = useOperatorActivityData(
    operatorId,
    activityDates.start,
    activityDates.end
  );

  const handleDateSelect = useCallback((val) => {
    if (val[0].isValid()) {
      setActivityDates((prevState) => ({ ...prevState, start: val[0].startOf('month') }));
    }
    if (val[1].isValid()) {
      if (val[1].isSameOrAfter(moment(), 'month', '[]')) {
        setActivityDates((prevState) => ({ ...prevState, end: moment() }));
      } else {
        setActivityDates((prevState) => ({ ...prevState, end: val[1].endOf('month') }));
      }
    }
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <TextWithTooltipIcon
            label={<Typography variant="h6">Activity profile</Typography>}
            tooltipText={tooltipContent}
          />
          <Box width="10em">
            <DateRangeMobi
              sx={{
                inputProps: {
                  InputProps: {
                    sx: { fontSize: '0.875rem' }
                  },
                  variant: 'standard',
                  defaultValue: undefined,
                  value:
                    activityDates.start || activityDates.end
                      ? `${
                          activityDates.start ? moment(activityDates.start).format('MMM YYYY') : ''
                        } - ${
                          activityDates.end ? moment(activityDates.end).format('MMM YYYY') : ''
                        }`
                      : null
                }
              }}
              onClose={({ value }) => handleDateSelect(value)}
              dateFormat="MMM YYYY"
              minStartDate={segmentMinDate}
              disableFuture
              startDate={activityDates.start}
              endDate={activityDates.end}
              placeholder="Period"
              dateWheels="MMM YYYY"
            />
          </Box>
        </Box>
        <Divider />
        <OperatorActivityProfileChart
          activityStatusData={activityData}
          loading={loading}
          fileName={`${operatorShortName}-activity-profile`}
        />
      </Box>
    </Paper>
  );
};

OperatorActivityProfile.propTypes = {
  operatorId: PropTypes.number,
  operatorShortName: PropTypes.string
};

export default OperatorActivityProfile;
