import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@esgian/esgianui';
import DashboardActivityMapDrawerHeader from './DashboardActivityMapDrawerHeader';
import { useTheme } from '@hooks';

const ContentPanel = ({ title, onClose, children, footerActions, padding = 2 }) => {
  const { customScrollbar } = useTheme();
  return (
    <Box height="100%" display="flex" flexDirection="column" gap={2} p={padding}>
      <DashboardActivityMapDrawerHeader title={title} onClose={onClose} />

      <Box flex={1} overflow="auto" sx={{ ...customScrollbar }}>
        {children}
      </Box>

      {footerActions && <Box mt="auto">{footerActions}</Box>}
    </Box>
  );
};

ContentPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  footerActions: PropTypes.node,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default ContentPanel;
