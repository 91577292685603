import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { getAverageMonthlySpeed } from '@api';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment } from '@hooks/useSegment';
import { DATE_FORMAT } from '@constants';

export const useOperatorAverageMonthlySpeed = (operatorId, startDate, endDate) => {
  const { segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);

  return useQuery({
    queryKey: [
      'operatorAverageSpeed',
      operatorId,
      startDate,
      endDate,
      segment.id,
      operatorType,
      commercialCategoryType
    ],
    queryFn: ({ signal }) =>
      getAverageMonthlySpeed(
        {
          OperatorId: operatorId,
          SegmentTypeId: segment.id,
          StartDate: moment(startDate).format(DATE_FORMAT),
          EndDate: moment(endDate).format(DATE_FORMAT),
          OperatorTypeId: operatorType,
          CommercialCategoryId: commercialCategoryType
        },
        signal
      ),
    enabled: !!operatorId && !!startDate && !!endDate
  });
};
