import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, TextField, Typography, Skeleton } from '@esgian/esgianui';
import { PortIcon } from '@components';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@constants';
import { formatToDecimalPlaces } from '@helpers';
import { ProfileLink } from '@components/Links';

const portCallsTimePeriodOptions = [
  { label: '1 month', value: 1 },
  { label: '3 months', value: 3 },
  { label: '6 months', value: 6 }
];

const createRow = (label, value) => (
  <Box display="flex" justifyContent="space-between" gap={2}>
    <Typography variant="body2" sx={{ textAlign: 'left' }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ textAlign: 'right' }}>
      {value}
    </Typography>
  </Box>
);

const VoyageTab = ({
  selectedVesselNextPortCall,
  latestPortCalls,
  isLoadingNextPortCall,
  isLoadingLatestPortCalls
}) => {
  const [selectedPortCallsTimePeriod, setSelectedPortCallsTimePeriod] = useState(
    portCallsTimePeriodOptions[0]
  );

  const filteredLatestPortCalls = latestPortCalls?.filter((portCall) => {
    const portCallDate = moment(portCall.startDate);
    const currentDate = moment();
    return portCallDate.isAfter(currentDate.subtract(selectedPortCallsTimePeriod.value, 'months'));
  });

  if (isLoadingLatestPortCalls || isLoadingNextPortCall) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontWeight: 'bold' }}>Port call history</Typography>
        <Box width="150px">
          <Autocomplete
            options={portCallsTimePeriodOptions}
            size="small"
            renderInput={(params) => <TextField {...params} label="Latest" />}
            value={selectedPortCallsTimePeriod}
            onChange={(_, value) => setSelectedPortCallsTimePeriod(value)}
            disableClearable
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={1} alignItems="center">
            <PortIcon size={10} color="yellow" />
            <ProfileLink
              name={selectedVesselNextPortCall.portName}
              profile={'port'}
              id={selectedVesselNextPortCall.portId}
              linkVariant={'body1'}
            />
          </Box>
          <Box minHeight="30px" px={2} borderLeft="3px solid" borderColor="primary.light" mx="2px">
            {createRow('ETA:', moment(selectedVesselNextPortCall).format(DATE_TIME_FORMAT))}
          </Box>
        </Box>
        {filteredLatestPortCalls.map(
          ({
            portName,
            timeInPort,
            startDate,
            arrivalDraught,
            percentArrivalDraught,
            departureDraught,
            percentDepartureDraught,
            imoDraught,
            endDate,
            portId
          }) => {
            const arrival = moment(startDate).format(DATE_TIME_FORMAT);
            const departure = moment(endDate).format(DATE_TIME_FORMAT);
            return (
              <Box key={portName} display="flex" flexDirection="column" gap={1}>
                <Box display="flex" gap={1} alignItems="center">
                  <PortIcon size={10} color="#CD91DC" />
                  <ProfileLink name={portName} profile={'port'} id={portId} linkVariant={'body1'} />
                </Box>
                <Box px={2} borderLeft="3px solid" borderColor="primary.light" mx="2px">
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Box display="flex" flexDirection="column" gap={0.5} mb={2}>
                      {arrival && createRow('Arrival time:', arrival)}
                      {departure && createRow('Departure time:', departure)}
                    </Box>

                    <Box display="flex" flexDirection="column" gap={0.5} mb={2}>
                      {createRow('Port time(days):', formatToDecimalPlaces(timeInPort, 1))}
                    </Box>

                    <Box display="flex" flexDirection="column" gap={0.5} mb={2}>
                      {createRow(
                        'Draught on arrival:',
                        `${formatToDecimalPlaces(arrivalDraught, 1)} / ${formatToDecimalPlaces(
                          imoDraught,
                          1
                        )} (${formatToDecimalPlaces(percentArrivalDraught, 1)}%)`
                      )}
                      {createRow(
                        'Draught on departure:',
                        `${formatToDecimalPlaces(departureDraught, 1)} / ${formatToDecimalPlaces(
                          imoDraught,
                          1
                        )} (${formatToDecimalPlaces(percentDepartureDraught, 1)}%)`
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
};

VoyageTab.propTypes = {
  selectedVesselNextPortCall: PropTypes.object,
  latestPortCalls: PropTypes.array,
  isLoadingLatestPortCalls: PropTypes.bool,
  isLoadingNextPortCall: PropTypes.bool
};

export default VoyageTab;
