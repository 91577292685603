import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentPanel } from '../../../../Maps';
import { Box, Button, Skeleton } from '@esgian/esgianui';
import {
  VesselPortCallsTable,
  YtdPortCallStatsTable,
  UpcomingVesselPortCallsTable
} from '@components/Tables';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { useLatestPortCalls, useCountryTopPorts, useNextPortCalls } from '@hooks';
import { ProfileLink } from '@components/Links';
import { PageLinkIcon } from '../../../../Icons';
import moment from 'moment';

const CountryDetailsPanel = ({ country, onClose }) => {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const { data: countryTopPortsData, isPending: isLoadingCountryTopPorts } = useCountryTopPorts({
    countryId: country?.id,
    selectedYear
  });
  const { data: countryPortCallsData, isPending: loading } = useLatestPortCalls({
    geographicalType: GEOGRAPHICAL_TYPES.COUNTRY,
    geoIds: [country?.id]
  });
  const { data: upcomingPortCalls, isPending: isLoadingUpcomingPortCalls } = useNextPortCalls({
    geoType: GEOGRAPHICAL_TYPES.COUNTRY,
    geoIds: [country?.id]
  });

  return (
    <ContentPanel
      title={country?.name ?? <Skeleton variant="text" width={200} />}
      footerActions={
        <Box textAlign="center">
          <Button startIcon={<PageLinkIcon />} sx={{ textTransform: 'none' }}>
            <ProfileLink
              name={country?.name}
              profile={'country'}
              id={country?.id}
              textColor="black"
            />
          </Button>
        </Box>
      }
      onClose={onClose}>
      <Box display="flex" flexDirection="column" gap={2}>
        <UpcomingVesselPortCallsTable
          assetLevel={'port'}
          title={'Upcoming Vessels Port Calls'}
          upcomingPortCalls={upcomingPortCalls}
          loading={isLoadingUpcomingPortCalls}
          name={country?.name}
          hideColumns={[
            'imo',
            'operatorId',
            'portId',
            'countryId',
            'countryName',
            'regionId',
            'regionName',
            'portCode'
          ]}
        />
        <YtdPortCallStatsTable
          topPortsData={countryTopPortsData?.topPortsYTD}
          handleSelectYear={({ target }) => {
            setSelectedYear(target.value);
          }}
          selectedYear={selectedYear}
          subTableHeader={`Operators YTD ${selectedYear}`}
          loading={isLoadingCountryTopPorts}
        />
        <VesselPortCallsTable
          loading={loading}
          name={country?.name}
          geoId={country?.id}
          title={'Most Recent Vessels Port Calls'}
          portCalls={countryPortCallsData}
          assetLevel={'country'}
          hideColumns={[
            'imo',
            'operatorId',
            'portId',
            'portName',
            'countryId',
            'countryName',
            'portCode',
            'imoDraught',
            'arrivalDraught',
            'percentArrivalDraught',
            'endDate',
            'departureDraught',
            'percentDepartureDraught'
          ]}
        />
      </Box>
    </ContentPanel>
  );
};

CountryDetailsPanel.propTypes = {
  country: PropTypes.object,
  onClose: PropTypes.func
};

export default CountryDetailsPanel;
