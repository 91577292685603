import React from 'react';
import PropTypes from 'prop-types';
import { Table, Typography, Paper, Stack } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import {
  getDateBetweenFilterListOptions,
  getDateBetweenFilterOptions
} from '@components/Tables/helpers';
import { checkUserHasAccess, tableTitlePaddingLeft } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import { useTimezone } from '@hooks/useTimezone';

function UpcomingVesselPortCallsTable({ title, loading, upcomingPortCalls, name, hideColumns }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const { getTimeZoneDisplay } = useTimezone();

  const columns = [
    {
      name: 'vesselName',
      label: 'Vessel',
      options: {
        filter: !hideColumns.includes('vesselName'),
        filterType: 'multiselect',
        sort: true,
        display: !hideColumns.includes('vesselName'),
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'vessel'} name={dataIndex} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'imo',
      label: 'IMO',
      options: {
        sort: true,
        filter: !hideColumns.includes('imo'),
        display: !hideColumns.includes('imo'),
        download: !hideColumns.includes('imo'),
        filterType: 'multiselect'
      }
    },
    {
      name: 'operatorShortName',
      label: 'Operator',
      options: {
        sort: true,
        filter: !hideColumns.includes('operatorShortName'),
        display: !hideColumns.includes('operatorShortName'),
        download: !hideColumns.includes('operatorShortName'),
        filterType: 'multiselect',
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'operator'} name={dataIndex} id={parseInt(rowData[3])} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'operatorId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'portId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'portName',
      label: 'Port',
      options: {
        sort: true,
        filter: !hideColumns.includes('portName'),
        display: !hideColumns.includes('portName'),
        download: !hideColumns.includes('portName'),
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'port'} name={dataIndex} id={rowData[4]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'countryId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'countryName',
      label: 'Country',
      options: {
        sort: true,
        filter: !hideColumns.includes('countryName'),
        display: !hideColumns.includes('countryName'),
        download: !hideColumns.includes('countryName'),
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'country'} name={dataIndex} id={rowData[6]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'regionId',
      options: {
        display: false,
        sort: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'regionName',
      label: 'Region',
      options: {
        sort: true,
        filter: !hideColumns.includes('regionName'),
        display: !hideColumns.includes('regionName'),
        download: !hideColumns.includes('regionName'),
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink profile={'region'} name={dataIndex} id={rowData[8]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'portCode',
      label: 'UNLOCODE',
      options: {
        sort: true,
        filter: !hideColumns.includes('portCode'),
        display: !hideColumns.includes('portCode'),
        download: !hideColumns.includes('portCode'),
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'eta',
      label: 'Estimated Time of Arrival',
      options: {
        sort: true,
        filter: !hideColumns.includes('eta'),
        display: !hideColumns.includes('eta'),
        download: !hideColumns.includes('eta'),
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1.data);
            let val2 = moment(obj2.data);
            if (order === 'asc') {
              return val1.isBefore(val2) ? 1 : -1;
            }
            return val1.isAfter(val2) ? 1 : -1;
          };
        },
        customBodyRender: (dataIndex) => {
          if (moment(dataIndex).isValid()) {
            return getTimeZoneDisplay(dataIndex);
          }
          return '-';
        },
        filterType: 'custom',
        customFilterListOptions: getDateBetweenFilterListOptions('Arrival'),
        filterOptions: getDateBetweenFilterOptions('Arrival Date', 'YYYY-MM-DDTHH:mm:ss')
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: true,
    download: checkUserHasAccess(user, true),
    setTableProps: () => {
      return {
        id: 'vessel-port-calls-table'
      };
    },
    downloadOptions: {
      filename: `Upcoming-Port-Calls-${name}.csv`
    },
    print: false,
    viewColumns: false,
    pagination: true,
    toolbar: true,
    elevation: 0,
    tableBodyMaxHeight: '80vh',
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'endDate',
      direction: 'desc'
    }
  };
  return (
    <Paper id={'upcoming-vessel-portcalls-table'}>
      <Table
        customStyle={tableTitlePaddingLeft}
        loading={loading}
        mode={theme.mode}
        title={
          <Stack sx={{ pb: 2, pt: 2 }}>
            <Typography id={'vessel-port-calls-title'} variant={'h6'}>
              {title}
            </Typography>
          </Stack>
        }
        data={upcomingPortCalls?.length ? upcomingPortCalls : []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

UpcomingVesselPortCallsTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  assetLevel: PropTypes.oneOf(['country', 'vessel', 'operator', 'owner', 'port', 'region'])
    .isRequired,
  upcomingPortCalls: PropTypes.arrayOf(PropTypes.object),
  hideColumns: PropTypes.arrayOf(PropTypes.string)
};

UpcomingVesselPortCallsTable.defaultProps = {
  title: 'Vessels Port Calls',
  name: '',
  loading: false,
  upcomingPortCalls: [],
  hideColumns: []
};

export default UpcomingVesselPortCallsTable;
