import moment from 'moment/moment';

export const getMonthDateRangePickerSx = (startDate, endDate, label = null) => {
  return {
    inputProps: {
      autoComplete: 'off',
      label: label,
      defaultValue: undefined,
      value:
        startDate || endDate
          ? `${startDate ? moment(startDate).format('MMM YYYY') : ''} - ${
              endDate ? moment(endDate).format('MMM YYYY') : ''
            }`
          : ''
    }
  };
};

export const getStartEndMonth = (value, dateFormat) => {
  let end = null;
  if (value[1]?.isValid()) {
    if (value[1]?.isSame(moment(), 'month')) {
      end = moment().format(dateFormat);
    } else {
      end = value[1].endOf('month').format(dateFormat);
    }
  }
  let start = value[0]?.isValid() ? value[0].startOf('month').format(dateFormat) : null;

  return { start: start, end: end };
};

export const getStartEndDate = (value, dateFormat) => {
  let end = value[1]?.isValid() ? value[1].format(dateFormat) : null;
  let start = value[0]?.isValid() ? value[0].format(dateFormat) : null;

  return { start: start, end: end };
};
