import { useQuery } from '@tanstack/react-query';
import { getMaxPortDraught } from '@api';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { useSelector } from 'react-redux';
import { getOperatorType, getCommercialCategoryType } from '@store/features';
import { useSegment } from '@hooks';

export const useGetMaxPortDraught = (portId) => {
  const operatorType = useSelector(getOperatorType);
  const { segment } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  return useQuery({
    queryKey: ['portMaxDraught', portId, operatorType, segment.id, commercialCategoryType],
    queryFn: ({ signal }) =>
      getMaxPortDraught(
        {
          geographicalType: GEOGRAPHICAL_TYPES.PORT,
          operatorTypeId: operatorType,
          segmentTypeId: segment.id,
          geoIds: [portId],
          commercialCategoryId: commercialCategoryType
        },
        signal
      ),
    enabled: !!portId
  });
};
