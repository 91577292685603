import { useQuery } from '@tanstack/react-query';
import { getNumVesselsInAgeRangePerQuarter } from '@api/Emissions';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType, getSegment } from '@store/features';

const useFleetAge = (operatorId) => {
  const segment = useSelector(getSegment);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);

  return useQuery({
    queryKey: ['fleetAge', operatorId, segment.id, operatorType, commercialCategoryType],
    queryFn: ({ signal }) =>
      getNumVesselsInAgeRangePerQuarter(
        {
          OperatorId: operatorId,
          SegmentTypeId: segment.id,
          OperatorTypeId: operatorType,
          CommercialCategoryId: commercialCategoryType
        },
        signal
      )
  });
};

export default useFleetAge;
