import React from 'react';
import PropTypes from 'prop-types';
import { ContentPanel } from '../../../../Maps';
import { Box, Button, Skeleton } from '@esgian/esgianui';
import Overview from './Overview';
import { VesselPortCallsTable, UpcomingVesselPortCallsTable } from '@components/Tables';
import { useLatestPortCalls, useNextPortCalls } from '@hooks';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { ProfileLink } from '@components/Links';
import { PageLinkIcon } from '../../../../Icons';

const PortDetailsPanel = ({ portData, onClose }) => {
  const { data: lastPortCalls, isPending: loading } = useLatestPortCalls({
    geographicalType: GEOGRAPHICAL_TYPES.PORT,
    geoIds: [portData.id]
  });

  const { data: upcomingPortCalls, isPending: isLoadingUpcomingPortCalls } = useNextPortCalls({
    geoType: GEOGRAPHICAL_TYPES.PORT,
    geoIds: [portData.id]
  });

  return (
    <ContentPanel
      title={portData?.portName ?? <Skeleton variant="text" width={200} />}
      footerActions={
        <Box textAlign="center">
          <Button startIcon={<PageLinkIcon />} sx={{ textTransform: 'none' }}>
            <ProfileLink
              name={portData?.portName}
              profile={'port'}
              id={portData?.id}
              textColor="black"
            />
          </Button>
        </Box>
      }
      onClose={onClose}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Overview portData={portData} />
        <UpcomingVesselPortCallsTable
          assetLevel={'port'}
          title={'Upcoming Vessels Port Calls'}
          upcomingPortCalls={upcomingPortCalls}
          loading={isLoadingUpcomingPortCalls}
          name={portData?.portName}
          hideColumns={[
            'imo',
            'operatorId',
            'portId',
            'portName',
            'countryId',
            'countryName',
            'regionId',
            'regionName',
            'portCode'
          ]}
        />
        <VesselPortCallsTable
          assetLevel={'port'}
          title={'Most Recent Vessels Port Calls'}
          portCalls={lastPortCalls}
          loading={loading}
          name={portData.portName}
          geoId={portData.id}
          hideColumns={[
            'imo',
            'operatorId',
            'portId',
            'portName',
            'countryId',
            'countryName',
            'portCode',
            'imoDraught',
            'arrivalDraught',
            'percentArrivalDraught',
            'endDate',
            'departureDraught',
            'percentDepartureDraught'
          ]}
        />
      </Box>
    </ContentPanel>
  );
};

PortDetailsPanel.propTypes = {
  portData: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default PortDetailsPanel;
