import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker } from 'mapbox-gl';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, SEGMENT } from '@constants';
import { CircularProgress } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { useSegment } from '@hooks';

const createMarker = (map, portData, themeMode, navigate, segment, previousMarkers) => {
  if (map.getLayer('circle')) {
    map.removeLayer('circle');
  }
  if (map.getSource('waypoints')) {
    map.removeSource('waypoints');
  }

  // Array to store marker instances
  let markers = [];

  let ret = [];
  let latAvg = 0;
  let lonAvg = 0;

  // Remove existing markers
  previousMarkers.forEach((marker) => marker.remove());
  markers = [];

  portData.forEach(({ cpointlat, cpointlon, radius, portName, id }) => {
    latAvg += cpointlat;
    lonAvg += cpointlon;

    const popup = new AnimatedPopup({
      closeButton: false,
      closeOnClick: false,
      openingAnimation: {
        duration: 500,
        easing: 'easeOutElastic',
        transform: 'scale'
      },
      closingAnimation: {
        duration: 200,
        easing: 'easeInBack',
        transform: 'scale'
      }
    });
    const description = `
    <div style="
        display: flex; 
        flex-direction: column; 
        ${themeMode ? 'background: rgba(11,26,46,0.8)' : 'background: rgba(255, 255, 255, 0.8)'}"> 
        <div style="padding: 8px">
            <div style="padding-bottom: 5px;">
                <span style="font-family: 'Nato Sans', sans-serif;font-size: 12px">${portName}</span>
            </div>
        </div>
    </div>`;
    const el = document.createElement('div');
    el.className = 'custom-marker';
    const img = document.createElement('img');
    img.src = '/assets/images/blue-pin.svg';
    img.style.width = '30px';
    img.style.height = '30px';
    el.appendChild(img);

    const marker = new Marker({ element: el })
      .setPopup(popup.setHTML(description).setLngLat([cpointlon, cpointlat]))
      .setLngLat([cpointlon, cpointlat])
      .addTo(map);

    const markerDiv = marker.getElement();
    let path = segment.id === SEGMENT.RoRo.id ? 'roro' : 'mpp';

    markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
    markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
    markerDiv.addEventListener('click', () => navigate(`/${path}/commercial-analytics/port/${id}`));

    // Store marker instance in the markers array
    markers.push(marker);

    ret.push({
      type: 'Feature',
      properties: {
        id: 1,
        name: `terminal`,
        radius: radius || 0
      },
      geometry: {
        type: 'Point',
        coordinates: [cpointlon, cpointlat]
      }
    });
  });

  latAvg = latAvg / portData.length;
  lonAvg = lonAvg / portData.length;
  map.flyTo({ center: [lonAvg, latAvg], zoom: 2.5, duration: 0 });

  map.addSource('waypoints', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: ret
    }
  });
  map.addLayer({
    id: 'circle',
    type: 'circle',
    source: 'waypoints',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        0,
        0,
        22,
        ['/', ['/', ['get', 'radius'], 0.01866137981], ['cos', ['*', 44, ['/', ['pi'], 180]]]]
      ],
      'circle-color': 'rgba(91,179,239,0.43)',
      'circle-opacity': 0.6,
      'circle-stroke-width': 1,
      'circle-stroke-color': 'rgb(91,179,239)'
    }
  });
  return markers;
};

function CountryPortsMap({ portData, loading, height }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const navigate = useNavigate();
  const themeMode = useSelector(getThemeMode);
  const { segment } = useSegment();
  const [markers, setMarkers] = useState([]);
  const [mapReady, setMapReady] = useState(false);

  useEffect(() => {
    // initialize map only once
    if (!portData) return;

    const { cpointlat, cpointlon } = portData[0];
    map.current = new Map({
      container: mapContainer.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      projection: { name: 'mercator' },
      center: [cpointlon, cpointlat],
      height: '100vh',
      zoom: 0
    });

    map.current.on('load', () => {
      setMapReady(true);
      setMarkers(createMarker(map.current, portData, themeMode, navigate, segment, markers));
    });

    return () => {};
  }, [portData, themeMode]);

  useEffect(() => {
    if (!mapReady || !portData) return;

    map.current.on('style.load', () => {
      setMarkers(createMarker(map.current, portData, themeMode, navigate, segment, markers));
    });
  }, [portData, themeMode, mapReady, markers]);

  return (
    <div
      ref={mapContainer}
      style={{
        height: height
      }}
      className="country-profile-map">
      {(loading || !portData?.length) && (
        <div
          style={{
            height: '100%',
            width: '100%',
            background: 'rgb(255 255 255 / 50%)',
            zIndex: 2,
            position: 'relative'
          }}>
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </div>
      )}
    </div>
  );
}

CountryPortsMap.propTypes = {
  portData: PropTypes.array,
  loading: PropTypes.bool,
  height: PropTypes.string
};

CountryPortsMap.defaultProps = {
  portData: [],
  loading: false,
  height: '40vh'
};

export default CountryPortsMap;
