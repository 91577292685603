import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  DatePicker,
  Grid,
  Paper,
  Stack,
  TextField
} from '@esgian/esgianui';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { OperatorMultiSelect } from '@components/Inputs';
import { VesselsMap } from '@components/Maps';
import { checkMinMaxInputValid } from '@helpers';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { useDynamicLookupQueries, useSegment, useStaticLookupQueries, useTheme } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat, getOperatorType } from '@store/features';
import {
  getRegionActivityFilters,
  REGION_ACTIVITY_SLICE_KEYS,
  updateRegionActivityFilters
} from '@store/features/filters/RegionProfilePage/RegionActivitySlice';
import {
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSliceToUri
} from '@helpers/uriHelpers';
import { getShipsInRegionByOperators } from '@api';
import { useVesselLookup } from '@hooks/useVesselLookup';

const initUrlParams = (regionActivityFilters, lookupOperators, searchParams) => {
  let newFilters = { ...regionActivityFilters };
  newFilters = getStandardValuesFromUri(newFilters, REGION_ACTIVITY_SLICE_KEYS, 2, searchParams);
  const oprs = getSelectedOperatorsFromUri(
    'raSelectedOperators',
    null,
    lookupOperators,
    searchParams
  );
  if (oprs) {
    newFilters.selectedOperators = oprs;
  } else {
    if (newFilters.selectedOperators === 'all') {
      newFilters.selectedOperators = lookupOperators;
    }
  }
  return newFilters;
};

const setUrlParams = (regionActivityFilters, lookupOperators, searchParams, setSearchParams) => {
  const { selectedOperators } = regionActivityFilters;
  searchParams = setSliceToUri(
    regionActivityFilters,
    REGION_ACTIVITY_SLICE_KEYS,
    'ra',
    searchParams
  );

  if (selectedOperators === 'all') {
    searchParams.set(`raSelectedOperators`, 'all');
  } else if (Array.isArray(selectedOperators)) {
    if (selectedOperators.length === lookupOperators.length) {
      searchParams.set(`raSelectedOperators`, 'all');
    } else {
      searchParams.set(`raSelectedOperators`, selectedOperators?.map(({ id }) => id).join(','));
    }
  }
  setSearchParams(searchParams);
};

function RegionActivitySection({ regionId }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [regionVessels, setRegionVessels] = useState([]);
  const [inputErrors, setInputErrors] = useState({
    sizeMinMax: null,
    minMaxLifting: null
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const regionActivityFilters = useSelector(getRegionActivityFilters);
  const {
    lookupRegionsQuery: { data: lookupRegions }
  } = useStaticLookupQueries();
  const {
    lookupOperatorsQuery: { data: lookupOperators }
  } = useDynamicLookupQueries();
  const operatorType = useSelector(getOperatorType);
  const dateFormat = useSelector(getDateFormat);
  const { isRoRo, isMPP, segment } = useSegment();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { getVesselListFromOperators } = useVesselLookup();

  const { center, regionPolygon } = useMemo(() => {
    if (lookupRegions?.length) {
      const region = lookupRegions.find(({ regionId: id }) => id === regionId);
      const { polygon, lat, long } = region;
      return { center: [long, lat], polygon: polygon };
    }
    return { center: [0, 0], polygon: [] };
  }, [lookupRegions]);

  useEffect(() => {
    if (init && lookupOperators.length) {
      setUrlParams(regionActivityFilters, lookupOperators, searchParams, setSearchParams);
    }
  }, [regionActivityFilters, init]);

  useEffect(() => {
    setFilters({ ...regionActivityFilters });
  }, [regionActivityFilters]);

  useEffect(() => {
    if (lookupOperators?.length) {
      let newFilters = initUrlParams(regionActivityFilters, lookupOperators, searchParams);
      dispatch(updateRegionActivityFilters(newFilters));
      setInit(true);
    }
  }, [lookupOperators]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const fetchRegionData = async () => {
      try {
        if (!moment(date, dateFormat).isValid()) {
          return;
        }
        setLoading(true);
        if (!selectedOperators.length) {
          setRegionVessels([]);
          setLoading(false);
          return;
        }
        let imos = selectedOperators ? getVesselListFromOperators(selectedOperators) : [];
        await getShipsInRegionByOperators(
          regionActivityFilters,
          regionId,
          segment.id,
          operatorType,
          imos,
          signal
        )
          .then(({ vessels }) => {
            setRegionVessels(vessels);
          })
          .catch(() => {
            setRegionVessels([]);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Abort');
        }
      }
    };
    if (init) {
      fetchRegionData();
    }
    return () => {
      controller.abort();
    };
  }, [init, regionActivityFilters]);

  const handleFilterChange = useCallback(
    (val) => {
      setFilters({ ...filters, ...val });
    },
    [filters]
  );

  const handleSave = useCallback(() => {
    const {
      vesselSizeMin,
      vesselSizeMax,
      date,
      selectedOperators,
      minLiftingCapacity,
      maxLiftingCapacity
    } = filters;
    let tempErrors = { ...inputErrors };
    tempErrors.sizeMinMax = checkMinMaxInputValid(vesselSizeMin, vesselSizeMax);
    tempErrors.minMaxLifting = checkMinMaxInputValid(minLiftingCapacity, maxLiftingCapacity);
    setInputErrors(tempErrors);
    const noErrors = !Object.values(tempErrors).some((x) => x !== null && x !== '');

    if (moment(date, dateFormat).isValid() && selectedOperators?.length && noErrors) {
      dispatch(updateRegionActivityFilters(filters));
    }
  }, [filters, inputErrors]);

  const {
    vesselSizeMin,
    vesselSizeMax,
    date,
    selectedOperators,
    minLiftingCapacity,
    maxLiftingCapacity
  } = filters;
  return (
    <Grid item xs={12}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2}>
            <Stack className={'section-filter'}>
              <OperatorMultiSelect
                helperText={init && !selectedOperators.length ? 'No operators selected' : ''}
                error={init && !selectedOperators.length}
                selectedOperators={init ? selectedOperators : []}
                operatorsList={lookupOperators || []}
                handleChange={(values) => {
                  handleFilterChange({ selectedOperators: values });
                }}
              />
            </Stack>
            <Stack className={'section-filter'}>
              <DatePicker
                disableFuture
                inputFormat={dateFormat}
                onChange={(val) => {
                  handleFilterChange({
                    date: moment(val, dateFormat).format(dateFormat)
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    label={init ? '' : <CircularProgress size={25} />}
                    fullWidth
                    {...params}
                  />
                )}
                value={init ? date : null}
                label={'Date'}
              />
            </Stack>
            <Stack className={'section-filter'}>
              <Stack direction={'row'} spacing={2}>
                <TextField
                  error={!!inputErrors.sizeMinMax}
                  helperText={inputErrors.sizeMinMax}
                  value={vesselSizeMin ?? ''}
                  label={
                    init ? (
                      `Vessel Size ${isRoRo ? 'Capacity' : 'DWT'} (min)`
                    ) : (
                      <CircularProgress size={25} />
                    )
                  }
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={({ target }) => {
                    handleFilterChange({
                      vesselSizeMin: target.value === '' ? '' : parseInt(target.value)
                    });
                  }}
                  type={'number'}
                  fullWidth
                  placeholder={'Min size...'}
                />
                <TextField
                  error={!!inputErrors.sizeMinMax}
                  fullWidth
                  type={'number'}
                  value={vesselSizeMax ?? ''}
                  label={
                    init ? (
                      `Vessel Size ${isRoRo ? 'Capacity' : 'DWT'} (max)`
                    ) : (
                      <CircularProgress size={25} />
                    )
                  }
                  placeholder={'Max size...'}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={({ target }) => {
                    handleFilterChange({
                      vesselSizeMax: target.value === '' ? '' : parseInt(target.value)
                    });
                  }}
                />
              </Stack>
            </Stack>

            {isMPP && (
              <Stack className={'section-filter'}>
                <Stack direction={'row'} spacing={2}>
                  <TextField
                    helperText={inputErrors.minMaxLifting}
                    error={!!inputErrors.minMaxLifting}
                    value={minLiftingCapacity ?? ''}
                    onChange={({ target }) => {
                      handleFilterChange({
                        minLiftingCapacity: target.value === '' ? '' : parseInt(target.value)
                      });
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    type={'number'}
                    fullWidth
                    placeholder={'Min size...'}
                    label={'Lifting Capacity (min)'}
                  />
                  <TextField
                    value={maxLiftingCapacity || ''}
                    error={!!inputErrors.minMaxLifting}
                    placeholder={'Max size...'}
                    onChange={({ target }) => {
                      handleFilterChange({
                        maxLiftingCapacity: target.value === '' ? '' : parseInt(target.value)
                      });
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    type={'number'}
                    label={'Lifting Capacity (max)'}
                  />
                </Stack>
              </Stack>
            )}
            <Box alignSelf={'self-end'} sx={{ pb: 1 }}>
              <Button onClick={handleSave}>Apply</Button>
            </Box>
          </Stack>

          <VesselsMap
            showSpeedLegend
            loading={!regionVessels?.length || loading}
            showVesselList
            mapStyle={theme.mode === 'dark' ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE}
            center={center}
            zoom={2.1}
            vessels={regionVessels}
            regionPolygon={regionPolygon}
            projection={'mercator'}
            height={'60vh'}
          />
        </Stack>
      </Paper>
    </Grid>
  );
}

RegionActivitySection.propTypes = {
  regionId: PropTypes.number.isRequired
};

RegionActivitySection.defaultProps = {};

export default RegionActivitySection;
