import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { useTheme } from '@hooks/useTheme';
import InsightHeaderWithTimeFilter from './InsightHeaderWithTimeFilter';
import { formatToDecimalPlaces } from '@helpers';

function VesselsStatusInsight({ globalMonthlyPercentActivityStatus }) {
  const { theme } = useTheme();
  const { segmentMonthlyPercentActivityStatusCountDict } = globalMonthlyPercentActivityStatus;

  const monthlyData = Object.values(segmentMonthlyPercentActivityStatusCountDict).flat();

  const latestMonthData = monthlyData[monthlyData.length - 1];
  const previousMonthData = monthlyData[monthlyData.length - 2];

  const options = useMemo(() => {
    const {
      palette: { mode }
    } = theme || {};
    return {
      chart: {
        type: 'pie'
      },
      colors: ['#0E9EFF', '#4CAF50', '#80DFEB'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        labels: {
          colors: mode === 'dark' ? '#fff' : '#000'
        }
      },
      labels: ['In port', 'Standby', 'Transit'],
      dataLabels: {
        formatter: (val) => `${formatToDecimalPlaces(val, 0)}%`
      },
      stroke: {
        width: 0
      }
    };
  }, [theme]);

  return (
    <InsightHeaderWithTimeFilter
      title="Vessel Status"
      filterOptions={[latestMonthData.monthStr, previousMonthData.monthStr]}
      renderChildren={(isSecondOptionSelected) => {
        const { percentMonthlyTransit, percentMonthlyStandBy, percentMonthlyAtPort } =
          isSecondOptionSelected ? previousMonthData : latestMonthData;

        const data = [
          +formatToDecimalPlaces(percentMonthlyAtPort, 0),
          +formatToDecimalPlaces(percentMonthlyStandBy, 0),
          +formatToDecimalPlaces(percentMonthlyTransit, 0)
        ];

        return <ApexChart options={options} data={data} type="pie" height={300} />;
      }}
    />
  );
}

VesselsStatusInsight.propTypes = {
  vesselsLatestPosition: PropTypes.array,
  globalMonthlyPercentActivityStatus: PropTypes.object
};

export default VesselsStatusInsight;
