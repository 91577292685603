import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, DateRangeMobi, Divider, Paper, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { OperatorAvgSpeedChart } from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections/OperatorActivitySection';
import { useOperatorAverageMonthlySpeed } from '@hooks';
import { useSegment } from '@hooks/useSegment';

function OperatorAverageSpeed({ operatorId, operatorShortName }) {
  const { segmentMinDate } = useSegment();
  const [speedDates, setSpeedDates] = useState({
    start: moment().subtract(1, 'year').startOf('month'),
    end: moment()
  });
  const { data: avgSpeedData, isPending: loading } = useOperatorAverageMonthlySpeed(
    operatorId,
    speedDates.start,
    speedDates.end
  );

  const handleDateSelect = (val) => {
    if (val[0].isValid()) {
      setSpeedDates((prevState) => ({ ...prevState, start: val[0].startOf('month') }));
    }
    if (val[1].isValid()) {
      if (val[1].isSameOrAfter(moment(), 'month', '[]')) {
        setSpeedDates((prevState) => ({ ...prevState, end: moment() }));
      } else {
        setSpeedDates((prevState) => ({ ...prevState, end: val[1].endOf('month') }));
      }
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography variant="h6">Average speed</Typography>
          <Box width="10em">
            <DateRangeMobi
              sx={{
                inputProps: {
                  InputProps: {
                    sx: { fontSize: '0.875rem' }
                  },
                  variant: 'standard',
                  defaultValue: undefined,
                  value:
                    speedDates.start || speedDates.end
                      ? `${speedDates.start ? moment(speedDates.start).format('MMM YYYY') : ''} - ${
                          speedDates.end ? moment(speedDates.end).format('MMM YYYY') : ''
                        }`
                      : null
                }
              }}
              onClose={({ value }) => handleDateSelect(value)}
              dateFormat="MMM YYYY"
              minStartDate={segmentMinDate}
              disableFuture
              startDate={speedDates.start}
              endDate={speedDates.end}
              placeholder="Period"
              dateWheels="MMM YYYY"
            />
          </Box>
        </Box>
        <Divider />
        <OperatorAvgSpeedChart
          loading={loading}
          avgSpeedData={avgSpeedData}
          fileName={`${operatorShortName}-average-speed`}
        />
      </Box>
    </Paper>
  );
}

OperatorAverageSpeed.propTypes = {
  operatorId: PropTypes.string.isRequired,
  operatorShortName: PropTypes.string.isRequired
};

export default OperatorAverageSpeed;
