import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { fetchVesselStats, getOperatorStats } from '@api';
import OperatorsOverviewSection from '@components/Sections/ShipAnalytics/OperatorsOverviewSection';
import { DATE_TIME_FORMAT, SEGMENT } from '@constants';
import { useDynamicLookupQueries, useSegment } from '@hooks';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';

function OperatorsOverviewPage() {
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const { segment, isRoRo, uriExt } = useSegment();
  const navigate = useNavigate();
  const [selectedOperators, setSelectedOperators] = useState([]);
  const { lookupOperatorsQuery } = useDynamicLookupQueries();
  const [init, setInit] = useState(false);
  const routeChange = (path) => {
    navigate(path);
  };
  const operatorsDataQuery = useQuery({
    queryKey: ['operatorOverview', segment, operatorType, commercialCategoryType],
    placeholderData: null,
    queryFn: ({ signal }) => {
      window.scrollTo(0, 0);
      let body = { SegmentTypeId: segment.id };
      if (isRoRo) {
        body.OperatorTypeId = operatorType;
      } else {
        body.CommercialCategoryId = commercialCategoryType;
      }
      return getOperatorStats(body, signal)
        .then((resp) => resp)
        .catch(() => []);
    }
  });
  useEffect(() => {
    if (lookupOperatorsQuery.data.length > 0) {
      setSelectedOperators(lookupOperatorsQuery.data);
      setInit(true);
    }
  }, [lookupOperatorsQuery.data, init]);

  const operatorsOverviewQuery = useQuery({
    queryKey: [
      'kpiStats',
      {
        selectedOperators: selectedOperators
      }
    ],
    enabled: !!selectedOperators.length,
    placeholderData: null,
    queryFn: ({ signal }) => {
      const operatorIds = selectedOperators.map((operator) => operator.id);
      let start = moment().subtract(1, 'year');
      let end = moment();
      return fetchVesselStats(
        signal,
        [segment.id],
        [],
        operatorIds,
        isRoRo ? [operatorType] : [],
        isRoRo ? [] : [commercialCategoryType],
        [],
        start.clone().format(DATE_TIME_FORMAT),
        end.clone().format(DATE_TIME_FORMAT)
      )
        .then((response) => {
          if (!response.length) return null;
          const aggregatedData = aggregateKpiData(response);
          return {
            aggregatedData: aggregatedData
          };
        })
        .catch(() => {
          return null;
        });
    }
  });

  function aggregateKpiData(response) {
    const vesselData = response[0]?.vesselTimeSeries?.[12] || {};
    const capacity = vesselData.capacity || {};
    let ageInfo = vesselData.ageInformation || {};

    return {
      fleetSize: vesselData.numVessels || 0,
      averageAge: ageInfo.averageAge || 0,
      averageCapacity: isRoRo ? capacity.averageCEU : capacity.averageDeadweightTonnage || 0,
      totalCapacity: isRoRo ? capacity.totalCEU : capacity.totalDeadweightTonnage || 0
    };
  }
  const aggregatedData = operatorsOverviewQuery.data?.aggregatedData;
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          id={'lbl-operator-overview'}
          title={'Operators Overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange(`${uriExt}/commercial-analytics`)}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange(`${uriExt}/commercial-analytics`)}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="operator-overview">
                Operators Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      <Grid item xs={12}>
        <OperatorsOverviewSection
          operatorDetails={operatorsDataQuery.data}
          operatorsKpi={aggregatedData}
          loading={operatorsDataQuery.isFetching}
        />
      </Grid>
    </Grid>
  );
}

OperatorsOverviewPage.propTypes = {};

OperatorsOverviewPage.defaultProps = {};

export default OperatorsOverviewPage;
