import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link, Stack, CountryFlag, Paper } from '@esgian/esgianui';

const InfoRow = ({ label, children }) => (
  <Box display="flex" gap={2}>
    <Box width="120px">
      <Typography variant="body2" bold>
        {label}:{' '}
      </Typography>
    </Box>
    <Box flex={1}>{children}</Box>
  </Box>
);

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const OperatorOverview = ({ operatorData }) => {
  const { operatorLongName, operatorShortName, country, address, website, isoAlpha2 } =
    operatorData || {};

  const ensureHttpsAndWww = (website) => {
    let site = website;
    if (!website.startsWith('www.') && !website.startsWith('https://')) {
      site = `https://www.${site}`;
    } else if (website.startsWith('www.')) {
      site = `https://${site}`;
    }
    return site;
  };

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 2 }}>
      <InfoRow label="Name">
        <Typography variant="body2">{operatorLongName}</Typography>
      </InfoRow>

      <InfoRow label="Short Name">
        <Typography variant="body2">{operatorShortName}</Typography>
      </InfoRow>

      <InfoRow label="Country">
        <Stack direction="row" spacing={1} alignItems="center">
          <CountryFlag size={15} countryCode={isoAlpha2} />
          <Typography variant="body2">{country}</Typography>
        </Stack>
      </InfoRow>

      <InfoRow label="Address">
        <Typography variant="body2">{address}</Typography>
      </InfoRow>

      <InfoRow label="Website">
        {website ? (
          <Link variant="body2" target="__blank" href={ensureHttpsAndWww(website)}>
            {website}
          </Link>
        ) : (
          <Typography variant="body2">{website}</Typography>
        )}
      </InfoRow>
    </Paper>
  );
};

OperatorOverview.propTypes = {
  operatorData: PropTypes.object
};

export default OperatorOverview;
