import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@esgian/esgianui';
import { useTheme } from '@hooks';
import InboundVesselPortTab from './InboundVesselPortTab';
import InboundVesselInsightVesselTab from './InboundVesselInsightVesselTab';

const DashboardActivityMapInboundVesselInsight = ({
  lookupPorts,
  locations,
  vesselsLatestPosition
}) => {
  const { customScrollbar } = useTheme();
  const [isVesselTabSelected, setIsVesselTabSelected] = useState(true);

  const renderTabContent = () => {
    let ports = [];

    locations.forEach((location) => {
      if (location.type === 'Country') {
        const countryPorts = lookupPorts.filter(({ country }) => country.id === location.id);
        ports = [...ports, ...countryPorts];
      } else {
        const locationPorts = lookupPorts.filter((port) => port.id === location.id);
        ports = [...ports, ...locationPorts];
      }
    });

    if (isVesselTabSelected) {
      return (
        <InboundVesselInsightVesselTab
          vesselsLatestPosition={vesselsLatestPosition}
          ports={ports}
        />
      );
    }

    return <InboundVesselPortTab ports={ports} />;
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} height="100%">
      <Box display="flex" gap={1}>
        {/* <Button
          variant={isVesselTabSelected ? 'outlined' : 'contained'}
          size="small"
          sx={{ textTransform: 'none', fontSize: 12, p: 0.2 }}
          onClick={() => setIsVesselTabSelected(false)}>
          Port
        </Button> */}
        <Button
          size="small"
          variant={isVesselTabSelected ? 'contained' : 'outlined'}
          sx={{ textTransform: 'none', fontSize: 12, p: 0.2 }}
          onClick={() => setIsVesselTabSelected(true)}>
          Vessel
        </Button>
      </Box>
      <Box
        display="flex"
        gap={2}
        flexDirection="column"
        flex={1}
        overflow="auto"
        sx={customScrollbar}>
        {renderTabContent()}
      </Box>
    </Box>
  );
};

DashboardActivityMapInboundVesselInsight.propTypes = {
  locations: PropTypes.array,
  lookupPorts: PropTypes.array,
  vesselsLatestPosition: PropTypes.array
};

export default DashboardActivityMapInboundVesselInsight;
