import { useQuery } from '@tanstack/react-query';
import { getOwnershipInformation } from '@api';

export const useOwnerData = (ownerId) => {
  return useQuery({
    queryKey: ['ownershipInformation', ownerId],
    queryFn: ({ signal }) => getOwnershipInformation({ OwnerId: ownerId }, signal),
    enabled: !!ownerId
  });
};
