import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import ValueDisplay from '@components/ValueDisplay';
import { ProfileLink } from '@components/Links';
import { useTimezone } from '@hooks/useTimezone';
import { useGetMaxPortDraught } from '@hooks';
import { ContentBox } from '../../../../Maps';

const Overview = ({ portData }) => {
  const { data: maxPortDraught, isPending } = useGetMaxPortDraught(portData?.id);
  const { getTimeZoneDisplay } = useTimezone();
  const { portName, country, cpointlat, cpointlon, unlocode } = portData || {};
  const { vesselName, draught, draughtDate, imo } = maxPortDraught?.maxDraughtLastPortCalls ?? {};

  return (
    <ContentBox isLoading={isPending} skeletonHeight="400px">
      <Paper sx={{ p: 2, height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item alignSelf={'center'}>
              <Typography variant={'h6'}>Overview</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={'column'} spacing={2}>
              <ValueDisplay title={'Name'} value={portName} />
              <ValueDisplay title={'Country'}>
                <Stack spacing={1} direction={'row'}>
                  <Typography variant={'body2'}>{country?.countryName}</Typography>
                </Stack>
              </ValueDisplay>
              <ValueDisplay title={'Location(lat/lon)'} value={`${cpointlat}/${cpointlon}`} />
              <ValueDisplay title={'UNLOCODE'} value={`${unlocode}`} />
              <ValueDisplay title={'Max reported Draught'} className={'vessel-details'}>
                <Stack>
                  <ProfileLink profile={'vessel'} name={vesselName} id={imo} />
                  <Typography variant={'body2'}>
                    {`${draught}m on ${getTimeZoneDisplay(draughtDate)}`}
                  </Typography>
                </Stack>
              </ValueDisplay>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ContentBox>
  );
};

Overview.propTypes = {
  portData: PropTypes.object,
  maxDraughtLastPortCalls: PropTypes.object
};

export default Overview;
