import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangeMobi, Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import { PortsTable } from '@components/Tables';
import YtdPortCallStatsTable from '@components/Tables/YtdPortCallStatsTable';
import moment from 'moment';
import { GlobalAveragePortTimeChart } from '@components/Charts';
import { useSegment } from '@hooks';
import { getGlobalPortsStats } from '@api';
import { useSelector } from 'react-redux';
import {
  getCommercialCategoryType,
  getDateFormat,
  getOperatorType,
  getSegment
} from '@store/features';
import { TextWithTooltipIcon } from '@components';
import { GLOBAL_AVERAGE_PORT_TIME_TEXT } from '@constants/tooltips';

function PortsOverviewSection({ topPortsData, handleSelectYear, selectedYear, ports, loading }) {
  const segment = useSelector(getSegment);
  const operatorType = useSelector(getOperatorType);
  const dateFormat = useSelector(getDateFormat);
  const { segmentMinDate } = useSegment();
  const [startDate, setStartDate] = useState(
    moment().subtract(11, 'months').startOf('month').format(dateFormat)
  );
  const [endDate, setEndDate] = useState(moment().format(dateFormat));
  const [loadingPortStats, setLoadingPortStats] = useState(true);
  const [globalPortStats, setGlobalPortStats] = useState(null);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getSpeedData = async () => {
      setLoadingPortStats(true);
      try {
        getGlobalPortsStats(
          {
            StartDate: startDate,
            EndDate: endDate,
            OperatorTypeId: operatorType,
            SegmentTypeId: segment.id,
            CommercialCategoryId: commercialCategoryType
          },
          signal
        )
          .then((result) => {
            setGlobalPortStats(result);
            setLoadingPortStats(false);
          })
          .catch(() => {
            setGlobalPortStats(null);
            setLoadingPortStats(false);
          });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (moment(startDate, dateFormat).isValid() && moment(endDate, dateFormat).isValid()) {
      getSpeedData();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [startDate, endDate, operatorType, commercialCategoryType]);

  useEffect(() => {}, []);

  const handleDateSelect = useCallback(
    (val) => {
      if (val[0].isValid()) {
        setStartDate(val[0].startOf('month').format(dateFormat));
      }
      if (val[1].isValid()) {
        setEndDate(val[1].endOf('month').format(dateFormat));
      }
    },
    [dateFormat]
  );

  function isSelectedMonthCurrent(selectedDate) {
    const currentDate = moment(); // Current date

    // Check if the selected date's month and year match the current month and year
    return (
      moment(selectedDate).month() === currentDate.month() &&
      moment(selectedDate).year() === currentDate.year()
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} sm={12}>
        <Paper sx={{ p: 2, height: '100%' }}>
          <Stack spacing={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Stack direction={'row'} justifyContent={'start'}>
                <Typography variant={'h6'}>Global Average Port Time</Typography>
                <TextWithTooltipIcon tooltipText={GLOBAL_AVERAGE_PORT_TIME_TEXT} />
              </Stack>
              <DateRangeMobi
                sx={{
                  inputProps: {
                    InputProps: {
                      sx: { fontSize: '1rem' }
                    },
                    size: 'small',
                    variant: 'outlined',
                    defaultValue: undefined,
                    label: 'Period',
                    value:
                      startDate || endDate
                        ? `${startDate ? moment(startDate, dateFormat).format('MMM YYYY') : ''} - ${
                            endDate ? moment(endDate, dateFormat).format('MMM YYYY') : ''
                          }`
                        : null
                  }
                }}
                onClose={({ value }) => {
                  handleDateSelect(value);
                }}
                dateFormat={dateFormat}
                minStartDate={segmentMinDate}
                maxDate={moment()}
                startDate={moment(startDate, dateFormat)}
                endDate={moment(endDate, dateFormat)}
                placeholder={'Period'}
                dateWheels={'MMM YYYY'}
              />
            </Stack>
            <Divider />
            <GlobalAveragePortTimeChart
              startDate={startDate}
              endDate={
                isSelectedMonthCurrent(endDate) ? moment(endDate).format(dateFormat) : endDate
              }
              globalPortStats={globalPortStats}
              loading={loadingPortStats}
              headerText={'Global Average Port Time'}
              isEndDateCurrentMonth={isSelectedMonthCurrent(endDate)}
            />
          </Stack>
        </Paper>
      </Grid>
      <Grid item lg={6} sm={12}>
        <YtdPortCallStatsTable
          handleSelectYear={handleSelectYear}
          selectedYear={selectedYear}
          topPortsData={topPortsData}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <PortsTable ports={ports} loading={loading} />
      </Grid>
    </Grid>
  );
}

PortsOverviewSection.propTypes = {
  ports: PropTypes.arrayOf(PropTypes.object),
  topPortsData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  handleSelectYear: PropTypes.func.isRequired,
  selectedYear: PropTypes.number,
  headerText: PropTypes.string
};

PortsOverviewSection.defaultProps = {
  loading: false,
  ports: [],
  topPortsData: [],
  selectedYear: moment().year(),
  headerText: ''
};

export default PortsOverviewSection;
