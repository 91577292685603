import React from 'react';
import PropTypes from 'prop-types';
import DashboardActivityMapDrawerHeader from '../Common/DashboardActivityMapDrawerHeader';
import { Box } from '@esgian/esgianui';
import { LoopPlaceholder } from '../../../Common';

const DashboardActivityMapInsight = ({ onClose, renderInsightContent, isLoading, title }) => {
  if (isLoading) {
    return <LoopPlaceholder />;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={2} p={2}>
      <DashboardActivityMapDrawerHeader title={title ?? 'Insight'} onClose={onClose} />
      {renderInsightContent?.()}
    </Box>
  );
};

DashboardActivityMapInsight.propTypes = {
  onClose: PropTypes.func.isRequired,
  renderInsightContent: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  title: PropTypes.string
};

export default DashboardActivityMapInsight;
