import { useQuery } from '@tanstack/react-query';
import { getMonthlyPercentActivityStatus } from '@api';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { DATE_FORMAT } from '@constants';
import moment from 'moment';
import { useSegment } from '@hooks/useSegment';

export const useOperatorActivityData = (operatorId, startDate, endDate) => {
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const { segment } = useSegment();

  return useQuery({
    queryKey: [
      'operatorActivity',
      operatorId,
      segment.id,
      startDate,
      endDate,
      operatorType,
      commercialCategoryType
    ],
    queryFn: ({ signal }) =>
      getMonthlyPercentActivityStatus(
        {
          OperatorId: operatorId,
          SegmentTypeId: segment.id,
          StartDate: moment(startDate).format(DATE_FORMAT),
          EndDate: moment(endDate).format(DATE_FORMAT),
          OperatorTypeId: operatorType,
          CommercialCategoryId: commercialCategoryType
        },
        signal
      ),
    enabled: Boolean(operatorId && startDate && endDate)
  });
};
