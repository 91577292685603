import { useQuery } from '@tanstack/react-query';
import { getActivePorts } from '@api/Countries';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';

export const useGetActivePorts = ({ geoType, geoIds }) => {
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const { segment } = useSegment();

  return useQuery({
    queryKey: ['activePorts', geoType, geoIds, segment.id, operatorType, commercialCategoryType],
    queryFn: ({ signal }) =>
      getActivePorts(
        {
          geoIds: geoIds,
          geoType: geoType,
          SegmentTypeId: segment.id,
          operatorType: operatorType,
          commercialCategoryId: commercialCategoryType
        },
        signal
      ),
    enabled: Boolean(geoType && geoIds && segment.id && operatorType && commercialCategoryType)
  });
};
